<template>
  <div>
    <!-- 英文 -->
    <div class="main" v-show="lang == 'en'">
      <div class="box">
        <div class="top">
          <div class="text1">Privacy Policy</div>
          <div class="text2">Last revision date: 2022-08-20</div>
        </div>
        <div class="content">
          <p>Preamble and Reminder</p>
          <p>
            This Platform that is exclusively used by users to trade digital
            assets and provides them with relevant Services (hereinafter
            referred to as "the Services"). For the convenience of wording in
            this Agreement, The Platform are collectively referred to
            as&nbsp;“We”&nbsp;or other appropriate forms of the first-person
            pronouns. Any natural person or entity that logs into the Platform
            shall be a user thereof and are hereinafter referred to as "You" or
            any other applicable forms of the second-person pronouns.
            Hereinafter You and us are collectively referred to as "both
            parties", and individually as "a party".
          </p>
          <p>&nbsp;</p>
          <p>
            We fully understand and respect the importance of Your personal
            information to You, and We will adopt corresponding appropriate
            safety protection measures to protect Your personal information in
            accordance with the requirements of the applicable laws and
            regulations. In view of this, this Privacy Policy (hereinafter
            referred to as "this Policy/this Privacy Policy") is formulated and
            the following reminder is provided to You:
          </p>
          <p>&nbsp;</p>
          <p>
            This Policy applies to all products and services We provide.&nbsp;If
            another privacy policy is specifically applicable to any product or
            service We provide to You, such privacy policy shall prevail;
            otherwise, this Privacy Policy shall prevail.
          </p>
          <p>&nbsp;</p>
          <p>
            In particular,&nbsp;this Policy does not apply to services provided
            to You by any third party, and such services shall be subject to a
            privacy policy that is otherwise specified to You.
          </p>
          <p>&nbsp;</p>
          <p>
            Before using our products or services, please read carefully and
            thoroughly understand this Policy, and pay special attention to the
            clauses that are in bold type or underlined and in bold type, make
            sure that You fully understand and agree to them before You start
            using our products or services. If You have any question, comment or
            suggestion in connection with any content of this Policy, You can
            contact us through any of the contact methods officially provided by
            us. If You do not agree with any term or condition in this Policy,
            You should promptly stop accessing this Platform.
          </p>
          <p>&nbsp;</p>
          <p>Part I Definition</p>
          <p>&nbsp;</p>
          <p>
            Personal information:&nbsp;various kinds of information recorded
            electronically or otherwise, which, alone or in combination with any
            other information, can identify the identity of a specific natural
            person or reflect the activities of a specific natural person.
          </p>
          <p>
            Personal sensitive information:&nbsp;personal information, including
            Identity Card number, personal biometric information, bank account
            numbers, property information, tracks of whereabouts, transaction
            information, and information of children who are not older than
            fourteen(14) years old, inter alia. (We will prominently identify
            specific personal sensitive information in bold type in this Privacy
            Policy).
          </p>
          <p>
            Deletion of personal information:&nbsp;the act of removing personal
            information from the systems involved in daily business functions to
            keep it in a state that cannot be retrieved or accessed.
          </p>
          <p>Children:&nbsp;minors under the age of fouteen(14).</p>
          <p>&nbsp;</p>
          <p>Part II Privacy Policy</p>
          <p>&nbsp;</p>
          <p>This Section will help You understand the following:</p>
          <ol>
            <li>How We collect and use Your information</li>
            <li>How We use Cookie and similar technologies</li>
            <li>
              How We share, transfer and publicly disclose Your information
            </li>
            <li>How We protect Your information</li>
            <li>How You manage Your information</li>
            <li>How We deal with the information of minors</li>
            <li>How this Privacy Policy is updated</li>
            <li>How to contact us</li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>How We collect and use Your information</li>
          </ol>
          <p>
            When You use our products and/or services, We need/may need to
            collect and use Your personal information, including the following
            two types of information:
          </p>
          <ol>
            <li>
              In order to enable the basic functions of the services/products We
              provide to You, You need to authorize us to collect and use Your
              information as may be necessary. If You refuse to provide such
              information, You will not be able to use our products and/or
              services normally;
            </li>
            <li>
              In order to enable the auxiliary functions of the
              services/products We provide to You, You may choose to authorize
              us to collect and use Your information as is necessary. If You
              refuse to provide such information, You will not be able to use
              the corresponding auxiliary functions or cannot achieve the
              intended effects of such functions, which, however, will not
              affected Your normal access to the basic functions of our products
              and/or services;
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>You understand and agree:</p>
          <p>&nbsp;</p>
          <ol>
            <li>
              We are committed to creating diversified products and services to
              meet Your needs. As We provide a wide variety of products and
              services to You, and the specific range of products/services that
              different users choose to use may vary from person to person,
              therefore, the corresponding basic/additional functions of such
              services and products, as well as the types and ranges of personal
              information collected and used hereunder may also vary subject to
              the specific product/service functions.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start="2">
            <li>
              In order to provide You with a better product and service
              experience, We are making continuous efforts to improve our
              technology. Subsequently, We may introduce new or optimized
              functions from time to time, and may need to collect and use new
              personal information or change the purpose or method of using such
              personal information. In this regard, We will separately explain
              to You the purpose, scope and usage of the corresponding
              information by updating this Policy, pop-up windows, page prompts,
              inter alia, and provide You with the means to choose and agree at
              Your discretion, and collect and use the corresponding information
              after obtaining Your express consent.&nbsp;During this process, if
              You have any questions, comments or suggestions, You can contact
              us through various contact methods provided by this Platform, and
              We will provide a reply to You as soon as possible.
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>
            We will collect and use Your personal information to realize the
            following functions under this Policy:
          </p>
          <ol>
            <li>
              Help You register and provide You with the services offered by
              this Platform;
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>Registration Service</li>
          </ol>
          <p>&nbsp;</p>
          <p>
            We provide You with basic membership services through Your account
            with the Platform. In order to register as a member and access our
            membership services, You need to fill in and provide the following
            information when applying for registration:
          </p>
          <p>
            (1) Identity Information. Such information can help us verify
            whether You are eligible to be registered as a member of this
            Platform. Such information includes but is not limited to Your name,
            address of Your place of residence, and other certificates and
            corresponding numbers thereof that are issued by Your country or
            government to certify Your identity information, as well as all
            other information that can help us verify Your identity (hereinafter
            referred to as "Identity Information"). Such Identity Information
            may vary depending whether You are an individual user or
            institutional user. Furthermore, in accordance with the applicable
            laws and regulations of different jurisdictions, the information
            collected by the Platform from users may vary depending on the
            country or region that the users are from. The following information
            and data are intended to serve as an indicative example:
          </p>
          <p>&nbsp;</p>
          <p>
            For an individual user: his/her nationality, full legal name
            (including former names, if any, as well as his/her name presented
            in the local language of such individual user), Identity Card
            number, email address, telephone number, social security number
            ("SSN"), passport number or any other government-issued identity
            card number, date of birth, proof of identity (e.g. passport,
            driver's license or other identity certificates issued by the
            government).
          </p>
          <p>&nbsp;</p>
          <p>
            For an institutional user: its legal corporation name (including its
            legal corporation name in the local language of such user),
            registration information, business telephone number, personal
            information of the authorized representative, primary business
            premises, and personal information of all shareholders/beneficial
            owners thereof (passports or identity cards issued by the
            government), the corporate structure of the institutional user,
            documents relating to the formation of the institutional user,
            resolutions of the board of directors thereof, declaration of the
            source of funding thereof, risk and compliance control instructions
            and other information or documents as may be required by the
            Platform.
          </p>
          <p>&nbsp;</p>
          <p>
            (2) Service information. Such information helps us to contact You
            and successfully provide You with different forms of digital asset
            trading services. Such information includes but is not limited to:
            Your personal Identity Information, contact information, transaction
            information, as well as Your debit card information and/or other
            account information (hereinafter collectively referred to as
            "Service Information"). Furthermore, let it be understood that the
            Services We provide to You are subject to constant update and
            development. If You choose to use any other service that is not
            covered by the above description, and therefore it is necessary for
            us to collect additional information of Yours based on such service,
            We will explain to You the scope and purpose of such information
            collection through notices on web pages, interactive processes,
            agreements, and pop-up prompts, among others, to obtain Your consent
            to such collection.
          </p>
          <p>&nbsp;</p>
          <p>
            If You only need to use the browsing service, You do not need to
            register as a member and provide the above information.
          </p>
          <p>&nbsp;</p>
          <ol start="2">
            <li>Other Registration Services</li>
          </ol>
          <p>&nbsp;</p>
          <p>
            Real-name authentication: after You register and log in, You need to
            enter the "Personal Center" to complete the real-name
            authentication. During the process of such real-name authentication,
            You need to provide Your real name, identity card number or passport
            number to the Platform.
          </p>
          <p>&nbsp;</p>
          <p>
            Advanced Certification: when Your transaction volume on this
            Platform exceeds a certain limit, the Platform will require You to
            undergo advanced certification, in order to ensure the safety of
            Your assets. You agree that in such advanced authentication process,
            in addition to providing the information required for the real name
            authentication, You will also undergo the advanced authentication
            procedures of "facial recognition", as is required by the Platform.
            Facial recognition will involve the collection of Your relevant
            video streams or face images by the Platform.
          </p>
          <p>&nbsp;</p>
          <p>
            Account Information Display: if You already have an account with the
            Platform, We may display Your above-mentioned personal information
            in the services (for real-name authentication, only the outcome of
            such authentication will be displayed, i.e., whether You have passed
            such authentication or not) and the operations You perform in the
            products and services associated with Your account with the
            Platform, including centralized display of Your personal data and
            transaction orders through Your Platform account. We respect Your
            choices in connection with the set-up of Your account with the
            Platform.
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>
              Security Management Services and Service Information Push Services
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>
            When You use the services provided by this Platform or access this
            Platform, in order to ensure Your transaction security and the safe
            and stable operation of services, this Platform needs to
            automatically receive and record Your web browser information, Your
            device information (IDFA, operating system, device model, device
            manufacturer, system version, Android ID, IMEI, MAC address, etc.),
            Your IP address and records on the web page You request to visit,
            Your location, network environment, device fingerprint information,
            service log information, software information, location information,
            inter alia.
          </p>
          <p>&nbsp;</p>
          <p>
            Please note that device information alone cannot identify a specific
            natural person.&nbsp;If We use this kind of non-personal information
            in combination with other information to determine the identity of a
            specific natural person, or use it in combination with personal
            information, such non-personal information will be regarded as
            personal information during such combination. Unless otherwise
            authorized by You or required by any of the applicable laws and
            regulations, We will anonymize such information and remove all
            identification marks therein.
          </p>
          <p>&nbsp;</p>
          <p>
            In order to provide You with more convenient and personalized
            information display and push services, We may extract Your preferred
            features based on Your device information and Your browsing and
            usage information on this Platform or the client thereof in the
            future, and generate indirect group profiling based on such
            features, for the purpose of displaying and pushing information and
            possible commercial advertisements. If You do not agree to receive
            information pushed to You in this way, or wish to withdraw Your
            consent to the collection of Your personal information, You can
            contact us in accordance with the contact information provided under
            this Policy.
          </p>
          <p>&nbsp;</p>
          <p>
            We strive to ensure Your browsing experience. If You do not want to
            receive the commercial advertising We send to You, You can
            unsubscribe to such advertising or otherwise disable this function
            by sending us a short text message or in any other way We specify.
          </p>
          <p>&nbsp;</p>
          <p>
            Furthermore, We will also use the above-mentioned information of
            Yours to continuously improve and optimize the above functions.
          </p>
          <p>&nbsp;</p>
          <p>iii. Keeping and Inquiry of Transaction Records</p>
          <p>&nbsp;</p>
          <p>
            We collect data related to Your transactions on this Platform,
            including but not limited to transaction records. If You need to
            query Your order transaction records, You can query Your personal
            transaction details in the "Order Management" section of the
            Platform, which include: cyber name of the transaction
            counter-party, time of order placement, order serial numbers,
            transaction information, type and quantity of digital assets
            involved, inter alia.
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>Customer Service and Dispute Resolution</li>
          </ol>
          <p>&nbsp;</p>
          <p>
            When You contact us or apply to us for in-sales and after-sales
            service or for dispute resolution,&nbsp;We need You to provide
            necessary personal information to verify Your membership, so as to
            ensure the security of Your account and the system.
          </p>
          <p>&nbsp;</p>
          <p>
            In order to facilitate our contact with You, help You solve the
            problems as soon as possible or record the solutions and results of
            the problems, We may keep Your communication records and related
            content (including Your account information, order information,
            other information You provide to prove the relevant facts, or
            contact information You provide). If You make queries, lodge
            complaints or provide suggestions in connection with any specific
            order, We will use Your account information and order information.
          </p>
          <p>&nbsp;</p>
          <p>
            As may be reasonably necessary for us to provide services and
            improve service quality, We may also use other information of Yours,
            including the relevant information You provide when You contact our
            customer service department, and the replies You provide to our
            questionnaire surveys.
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>Miscellaneous</li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>
              If the information You provide involve any personal information of
              any other user, You must ensure that You have obtained legal
              authorization before providing such personal information to this
              Platform. If any personal information of any child is involved,
              You must obtain the consent of such child's guardian before You
              may release such information. Under the aforementioned
              circumstance, the guardian shall have the right to contact us in
              any of the ways specified under Article IX of this Policy to
              request for the correction or deletion of any content involving
              the personal information of such child.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start="2">
            <li>
              If We use any information hereunder for any purpose not specified
              in this Policy, or use any information collected for any specific
              purpose for any purposes other than such specific purpose, or We
              take the initiative to obtain Your personal information from a
              third party, We will obtain Your prior consent.
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>
            If We obtain Your information indirectly from a third party, We will
            explicitly require such third party in writing to obtain Your prior
            consent in accordance with law before collecting any personal
            information of Yours, inform You of the content of Your information
            that such third party shares with us, and obtain Your explicit
            confirmation before providing us with any sensitive information of
            Yours; furthermore, we will require such third party to provide an
            undertaking as to the legality and compliance of the source of
            personal information such third party provides to us. If the third
            party violates any of the applicable laws, We will explicitly
            require such third party to bear the corresponding legal
            responsibilities; furthermore, We will strengthen the security of
            personal information (including by proper filing of sensitive
            information, encrypted storage of sensitive information, access
            control, inter alia). We will use no less than the same methods and
            measures used by us to protect the personal information of our users
            to protect such personal information indirectly obtained by us.
          </p>
          <p>&nbsp;</p>
          <ol start="3">
            <li>
              Exceptions to the Requirements for Authorization and Consent
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>
            You fully understand and agree that under any of the following
            circumstances Your authorization is not required for us to collect
            and use Your personal information, and We are not obliged to respond
            to Your requests for correction/modification, deletion and
            cancellation of your information, or your requests for the
            withdrawal of your consent or your requests to access your
            information:
          </p>
          <p>
            (1) Where state security and/or national defense security are
            involved;
          </p>
          <p>
            (2) Where public security, public health and/or major public
            interests are involved;
          </p>
          <p>
            (3) Where judicial or administrative enforcement, e.g., criminal
            investigation, prosecution, trial and sentence enforcement, is
            involved;
          </p>
          <p>
            (4) Where it is for the purpose of protecting Your or another
            person’s life, property and/or any other important legal rights and
            interests, yet it is difficult to obtain the consent of Yours or
            such person, as the case may be;
          </p>
          <p>
            (5) Where such information is Your personal information that You
            disclose to the public by Yourself;
          </p>
          <p>
            (6) Where such information is Your personal information that is
            collected from legally disclosed information, such as legal news
            reports and government information disclosures, inter alia.
          </p>
          <p>
            (7) Where it is required by any agreement or any other written
            document signed with You;
          </p>
          <p>
            (8) Where it is necessary for maintaining the safe and stable
            operation of the products and/or services provided hereunder, such
            as uncovering and handling errors and faults in the products and/or
            services hereunder;
          </p>
          <p>(9) Where it is necessary for legal news reporting;</p>
          <p>
            (10) Where it is necessary for any public-interests-based
            statistical survey or academic research by any academic research
            institute, and when the results of such research or survey are
            provided externally, the personal information contained in such
            results shall be neutralized; and
          </p>
          <p>
            (11) Other circumstances stipulated by any of the applicable laws
            and regulations.
          </p>
          <p>&nbsp;</p>
          <p>
            Please be aware that in accordance with the applicable laws,&nbsp;if
            We adopt technical measures and other necessary measures to process
            personal information so that the data recipient cannot re-identify a
            specific individual and cannot restore such personal information to
            its status prior to our processing, or We may conduct unmarked
            research, statistical analysis and prediction on the collected
            information to improve the content and layout of this Platform, to
            provide product or service support for business decisions, and to
            improve our products and services (including using anonymous data
            for machine learning or model algorithm training),&nbsp;then the use
            of such processed information shall not be subject to a separate
            notice to You or Your consent.
          </p>
          <p>&nbsp;</p>
          <ol start="4">
            <li>
              If We stop operating the products or services of this Platform, We
              will stop collecting Your personal information in a timely manner,
              notify You of such termination of operation either through a
              special one-on-one notice to You or a general public announcement,
              and we will delete or anonymize the personal information that We
              hold in connection with such terminated business.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>How We Use Cookies</li>
          </ol>
          <p>&nbsp;</p>
          <p>
            If Your browser or browser’s add-on service permits, You can change
            the setting of Your browser so as to modify its degree of cookie
            acceptance or so that Your browser rejects Our cookies.&nbsp;For
            more details, please refer to AboutCookies.org. However, if You make
            such changes, it may affect Your safe access to our Platform under
            certain circumstances, and in this case, You may need to change Your
            user settings every time You visit our Platform. You understand and
            agree that:
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>
              When You visit our Platform, we will use Google Stats via cookies
              to record our performance and check the effect of online
              advertising. Cookies are small amounts of data sent to Your
              browser and stored on Your computer hard drive. Cookies can only
              be sent to Your computer hard disk when You use Your computer to
              access our Platform.
            </li>
            <li>
              Cookies are often used to record the habits and preferences of
              visitors when they visit various items on our Platform. The data
              collected via cookies are anonymous collective statistics and do
              not contain personal data.
            </li>
            <li>
              Cookies cannot be used to obtain data on Your hard disk, Your
              email address, or Your personal data; instead, they enable the
              Platform or service provider’s system to recognize Your browser
              and capture and remember information. Most browsers are preset to
              accept cookies. You can choose to set Your browser to refuse to
              accept cookies, or to notify You as soon as cookies are installed.
              However, if Your browser is preset to prohibit cookies, You may be
              unable to start or use some features of our Platform.
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>
            III. How We share, transfer and publicly disclose Your information
          </p>
          <p>&nbsp;</p>
          <p>(1) Sharing</p>
          <p>&nbsp;</p>
          <p>
            We will not share Your personal information with any company,
            organization and individual other than service providers of this
            Platform, except for under any of the following circumstances:
          </p>
          <ol>
            <li>
              Sharing under statutory circumstances: we may share Your personal
              information externally in accordance with applicable laws and
              regulations, or as may be necessary for litigation and dispute
              resolution requirements, or as is required by administrative and
              judicial authorities.
            </li>
            <li>
              Sharing with explicit consent: after obtaining Your explicit
              consent, We will share Your personal information with other
              parties.
            </li>
            <li>
              Sharing subject to Your voluntary choice: We will, in accordance
              with Your choice, share with the transaction counter-party of the
              digital assets the necessary information related to the
              transaction in Your order information, so as to meet Your
              transactions related to transaction and after-sales services.
            </li>
            <li>
              Sharing with authorized partners: we will share information with
              our affiliated parties and trusted third parties that help us
              operate our Platform, carry out business or provide services to
              You, as long as these parties agree to keep the information
              strictly confidential; We will share information when We believe
              that such disclosure of information is appropriate, in compliance
              with laws, regulations, rules and regulations or orders from
              courts of law or other competent authorities, implements our
              Platform strategy, is required for the normal operation of the
              Platform, is required for related parties to provide services or
              protect our or any other person’s rights, property or safety.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>Transfer</li>
          </ol>
          <p>&nbsp;</p>
          <p>
            We will not transfer Your personal information to any company,
            organization or individual except for under any of the following
            circumstances:
          </p>
          <ol>
            <li>
              Transfer subject to explicit consent: After obtaining Your
              explicit consent, We will transfer Your personal information to
              another party;
            </li>
            <li>
              In the event of the merger, acquisition or bankruptcy liquidation
              of the Platform, or any other situation involving merger,
              acquisition or bankruptcy liquidation, if the transfer of personal
              information is involved therein, We will require the new company
              or organization holding Your personal information originally held
              by us to continue abiding by this Policy; otherwise We will
              require such company or organization or individuals involved to
              seek Your authorization and consent.
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>iii. Public Disclosure</p>
          <p>&nbsp;</p>
          <p>
            We will only publicly disclose Your personal information under the
            following circumstances:
          </p>
          <ol>
            <li>
              We may publicly disclose Your personal information with Your
              explicit consent or based on Your voluntary choice;
            </li>
            <li>
              If We determine that You have violated any of the applicable laws
              and regulations or seriously violated any of the relevant
              agreement or rules of the Platform, or in order to protect the
              personal and property safety of users of the Platform or the
              public from infringement, We may disclose Your personal
              information in accordance with the applicable laws and regulations
              or with Your consent, including relevant violations and measures
              already taken against You by the Platform.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>
              Exceptions to the requirements for Your prior authorization and
              consent for the sharing, transfer or public disclosure of your
              personal information
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>
            Under the following circumstances, the sharing, transfer or public
            disclosure of Your personal information is not subject to Your prior
            authorization and consent:
          </p>
          <p>
            (1) where state security and/or national defense security are
            involved;
          </p>
          <p>
            (2) where public security, public health and/or major public
            interests are involved;
          </p>
          <p>
            (3) Where judicial or administrative enforcement, e.g., criminal
            investigation, prosecution, trial and sentence enforcement, is
            involved;
          </p>
          <p>
            (4) Where it is for the purpose of protecting Your or another
            person’s life, property and/or any other important legal rights and
            interests, yet it is difficult to obtain the consent of Yours or
            such person, as the case may be;
          </p>
          <p>
            (5) Where such information is Your personal information that You
            disclose to the public by Yourself; and
          </p>
          <p>
            (6) Where such information is Your personal information that is
            collected from legally disclosed information, such as legal news
            reports and government information disclosures, inter alia.
          </p>
          <p>&nbsp;</p>
          <p>
            Please be aware that in accordance with the applicable laws,&nbsp;if
            We adopt technical measures and other necessary measures to process
            personal information so that the data recipient cannot re-identify a
            specific individual and cannot restore such personal information to
            its status prior to our processing, then the sharing, transfer or
            public disclosure of such personal data so processed shall not be
            subject to notification to You or consent by You.
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>How We protect Your information</li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>
              We implement proper physical, electronic, management and technical
              measures to protect and safeguard Your personal data. We will
              exert our best efforts to ensure that any personal data collected
              through our Platform are free from any nuisance caused by any
              third party unrelated to us. The safety measures We adopt include
              but are not limited to:
            </li>
          </ol>
          <p>
            (1) Physical measures: Records containing Your personal data will be
            stored in a properly locked place.
          </p>
          <p>
            (2) Electronic measures: Computer data containing Your personal
            information will be stored in computer systems and storage media
            that are subject to strict log-in restriction.
          </p>
          <p>
            (3) Management measures: We have set up an internal safety defense
            department to protect the users' information, established relevant
            internal control systems, and adopted the principle of strict
            authorization for our employees who may come into contact with Your
            information; therefore, only properly authorized employees are
            permitted to come into contact with Your personal information and
            such employees must comply with our internal confidentiality rules
            for personal data. Furthermore, we provide sustained training to our
            staff on relevant laws and regulations, privacy and safety
            guidelines, enhance publicity and education on safety awareness, and
            organize our relevant internal personnel to carry out emergency
            response training and emergency drills on a regular basis, so as to
            enable them to fully understand their job duties and emergency
            response strategies and procedures.
          </p>
          <p>
            (4) Technical Measures: encryption technology such as Secure Socket
            Layer Encryption may be adopted to transfer Your personal data.
          </p>
          <p>
            (5) Security Measures: In order to ensure Your information security,
            we are committed to using various currently available general
            security technologies and supporting management systems to minimize
            the risks that Your information may be disclosed, damaged, misused,
            accessed without authorization, disclosed without authorization or
            altered. For example, the Secure Socket Layer (SSL) software is used
            for encrypted transmission, encrypted information storage and strict
            restriction of data center access. When transmitting and storing
            sensitive personal information (including personal biometric
            information), we will adopt security measures such as encryption,
            authority control, removal of identification marks, and
            de-sensitization, inter alia.
          </p>
          <p>
            (6) Other measures: We regularly review our personal data
            collection, storage and processing procedures; furthermore, we limit
            the access of our employees and suppliers to Your data in accordance
            with the principle of&nbsp;“as necessary”, and our employees and
            suppliers must abide by strict contractual confidentiality
            obligations.
          </p>
          <ol start="2">
            <li>
              If You become aware of any security vulnerability of our Platform,
              please contact us promptly so that We can adopt appropriate
              actions as soon as possible.
            </li>
            <li>
              Despite the above-mentioned technical and security measures, We
              cannot guarantee the absolute safety of data transmission on the
              Internet, so We cannot absolutely guarantee that the personal data
              You provide to us through our Platform are safe at all times. We
              will not regularly update and make public security risks and
              personal information security impact assessment reports, inter
              alia, which You can obtain through announcements made by this
              Platform.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>How You manage Your information</li>
          </ol>
          <p>&nbsp;</p>
          <p>
            You can access and manage Your information in any of the following
            ways:
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>Query, correct and delete Your information</li>
          </ol>
          <p>
            You have the right to obtain a copy of Your personal data upon Your
            request, and to determine whether the information we have about You
            is accurate and up-to-date. If any of Your personal data is not
            accurate, You can request to update Your information. You can also
            request for the deletion of Your personal data, provided, however,
            we may reject Your deletion request in some cases, e.g., where such
            rejection is required by law or for other legal purposes. For data
            access, correction or deletion requests, please contact us according
            to the contact information under Article VIII hereof.
          </p>
          <p>
            In response to application for access to or correction or deletion
            of data, we will verify the identity of the applicant to ensure that
            such applicant is legally entitled to make such application.
            Although our goal is to respond to such application on a gratuitous
            basis, we reserve the right to charge You a reasonable fee if Your
            request is repeated or onerous.
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>Changing the scope of Your authorization</li>
          </ol>
          <p>
            The fulfillment of each business function is subject to certain
            basic personal information (see Part I of this Privacy Policy).
            Furthermore, You can contact us through the contact information
            under Article VIII to grant or withdraw Your authorization.
          </p>
          <p>
            When You withdraw Your consent, We will no longer process the
            corresponding personal information.&nbsp;However, Your decision to
            withdraw Your consent will not affect the existing personal
            information processing based on Your authorization.
          </p>
          <p>&nbsp;</p>
          <p>iii. Cancellation of Your account</p>
          <p>
            You can contact us through the contact information under Article
            VIII hereof to apply for cancellation of Your account. After You
            voluntarily cancel Your account, We will stop providing You with
            products or services, and delete Your personal information or
            anonymize it in accordance with the requirements of applicable laws.
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>Respond to Your above requests</li>
          </ol>
          <p>
            To ensure safety, You may need to provide a written request or prove
            Your identity in other ways. We may ask You to verify Your identity
            before processing Your request.
          </p>
          <p>
            We will reply within fifteen(15) days. If You are not satisfied with
            our reply, You can also initiate a complaint through the contact
            information provided by us.
          </p>
          <p>
            In principle, We do not charge any fee on Your reasonable requests,
            provided, however, We may charge a certain fee on Your repeated
            requests or requests that exceed reasonable limits, based on the
            actual circumstances. We may reject requests involving information
            that is not directly related to Your identity, unjustified repeated
            information, or requests that require too many technical means (e.g.
            to develop new systems or fundamentally change existing practices),
            requests that pose risks to any other person's legitimate rights and
            interests, or are unrealistic.
          </p>
          <p>
            Under the following circumstances, We will not be able to respond to
            Your request in accordance with applicable laws and regulations:
          </p>
          <ol>
            <li>
              where state security and/or national defense security are
              involved;
            </li>
            <li>
              where public security, public health and/or major public interests
              are involved;
            </li>
            <li>
              Where judicial or administrative enforcement, e.g., criminal
              investigation, prosecution, trial and sentence enforcement, is
              involved;
            </li>
            <li>
              Where there is sufficient evidence proving that the owner of such
              personal information has subjective malice or abuse of power;
            </li>
            <li>
              Where response to Your request would cause serious damage to the
              legitimate rights and interests of You or any other individual or
              organization;
            </li>
            <li>Where any trade secret is involved.</li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li>How We deal with the information of minors</li>
          </ol>
          <p>
            This Platform is not open to children. If We find or suspect that
            the user is under 14 years old, We will ask the user to close his
            account and will not allow the user to continue to use the Platform
            products or services. We will delete the relevant information as
            soon as possible. If You know that anyone under the age of 14 uses
            our services, please inform us so that We can take action to prevent
            them from obtaining our products or services.
          </p>
          <p>
            If we find out or suspect that a user is less than fourteen (14)
            years old, we will ask the user to close his/her account and will
            not allow the user to continue using the products and services of
            the Platform. We will delete the relevant information of such users
            as soon as possible. If You know that any person who is under the
            age of fourteen (14) uses our services, please notify us so that we
            can adopt actions to prevent such user from accessing our products
            or services.
          </p>
          <p>
            For minors who have reached the age of fourteen(14) but are less
            than eighteen(18) years old, We expect their parents or guardians to
            guide such minors in using our services. We will protect the
            confidentiality and security of such minors' information in
            accordance with relevant state laws and regulations.
          </p>
          <p>
            If You are a minor, it is recommended that Your parents or guardians
            read this Policy and use our services or provide us with Your
            information subject to the consent of Your parents or guardian. If
            we collect Your information with the consent of Your parents or
            guardians, we will only use or disclose such information when it is
            permitted by law, expressly approved by Your parents or guardian or
            is necessary for protecting Your rights and interests. If Your
            parents or guardian disagrees with Your use of our services or Your
            providing us with information in accordance with this Policy, please
            stop using our services promptly and inform us in time so that we
            can take corresponding measures.
          </p>
          <p>
            If You are the parent or guardian of a minor, please contact us
            through the contact information hereunder when You have any
            questions in connection with our processing of the information of
            the minor of whom You are a parent or guardian.
          </p>
          <p>&nbsp;</p>
          <p>VII. How this Privacy Policy is updated</p>
          <p>Our Privacy Policy is subject to changes.</p>
          <p>
            Without Your explicit consent, We will not restrict Your rights
            under this Privacy Policy. We will publish any and all changes to
            this Privacy Policy via official channels. If You do not agree with
            any such change, You should stop accessing this Platform promptly.
            After an updated version of this Privacy Policy is released, Your
            continued visit to this Platform shall be deemed as showing and
            indicating that You agree with such update and agree to abide by the
            updated version of this Privacy Policy.
          </p>
          <p>
            For any major change made hereto, We will also issue more prominent
            notices (including notices made in the form of public announcements
            made through this Platform and even pop-up prompts to You).
          </p>
          <p>
            Major changes referred to in this Policy include but are not limited
            to:
          </p>
          <ol>
            <li>
              Significant changes in our service mode. For example, the purpose
              of processing personal information, the types of personal
              information processed, the ways in which personal information is
              used, inter alia.
            </li>
            <li>
              Significant changes in our control. Such as changes in information
              controllers caused by any merger and acquisition, inter alia;
            </li>
            <li>
              Any change in the major person(s) which personal information
              hereunder is shared with, transferred or publicly disclosed to;
            </li>
            <li>
              Significant changes in Your right to participate in personal
              information processing and the way You exercise such right;
            </li>
            <li>
              Changes in our department responsible for personal information
              security, or changes in the contact information of such
              department, or the ways in which complaints hereunder are lodged;
              and
            </li>
            <li>
              The security impact assessment report for personal information
              hows that there is a high degree of risks.
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>VIII. How to Contact Us</p>
          <ol>
            <li>
              If You have any requirement and opinion, You can contact us by
              e-mail at , which is the only valid and official e-mail address
              through which We communicate with You. Therefore, We are not
              responsible for any act or omission that arises due to Your
              failure to use a valid way of contact.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start="2">
            <li>
              We will publish announcements and information exclusively through
              the effective contact information on this Platform or post
              announcements on this Platform, so We are not responsible for any
              losses arising from Your trust in information that is not obtained
              through the aforementioned methods.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start="3">
            <li>
              If You have any question about our Privacy Policy, please feel
              free to contact us at any time.
            </li>
          </ol>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    <!-- 中文 -->
    <div class="main" v-show="lang == 'zh'">
      <div class="box">
        <div class="top">
          <div class="text1">隐私条款</div>
          <div class="text2">Last revision date：2022-08-20</div>
        </div>
        <div class="content">
          <p><strong>【导言及提示条款】</strong></p>
          <p>
            本平台是一个专门供用户进行数字资产交易和提供相关服务（以下称“该服务”或“服务”）的平台。为了表述之方便，本平台在本协议中合称&nbsp;“我们”或其他第一人称称呼，只要登陆该平台的自然人或其他主体均为本平台的用户，以下使用“您”或其他第二人称，我们和您合称为“双方”，我们或您单称为“一方”。
          </p>
          <p>
            我们充分理解与尊重您的个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，保护您的个人信息安全。鉴于此，制定本《隐私政策》（下称“本政策&nbsp;/本隐私政策”）并提醒您：
          </p>
          <p>
            本政策适用于我们提供的所有产品和服务。<strong
              >如<u
                >我们向您提供的产品或服务单独设立有隐私政策的，则该产品或服务适用其单独设立的隐私政策；如我们的产品或服务未单独设立隐私政策的，则适用于本政策。</u
              ></strong
            >
          </p>
          <p>
            需要特别说明的是，<strong
              ><u
                >本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您另行说明的隐私政策。</u
              ></strong
            >
          </p>
          <p>
            <strong
              ><u
                >在使用我们各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。如对本政策内容有任何疑问、意见或建议，您可通过我们官方提供的各种联系方式与我们联系。如您不同意本政策中的任何条款，您应立即停止访问本平台。</u
              ></strong
            >
          </p>
          <p>&nbsp;</p>
          <p><strong>第一部分&nbsp;定义</strong></p>
          <p>
            <strong>个人信息：</strong
            >指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
          </p>
          <p>
            <strong>个人敏感信息：指</strong
            >包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息（我们将在本隐私权政策中对具体个人敏感信息以<strong>粗体</strong>进行显著标识）。
          </p>
          <p>
            <strong>个人信息删除：</strong
            >指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
          </p>
          <p><strong>儿童</strong>：指不满十四周岁的未成年人。</p>
          <p><strong>第二部分&nbsp;&nbsp;隐私政策</strong></p>
          <p>本隐私政策部分将帮助您了解以下内容：</p>
          <p>一、我们如何收集和使用您的信息</p>
          <p>
            二、我们如何使用{{ this.basicConfig.CompanyNameEnglish }}和同类技术
          </p>
          <p>三、我们如何共享、转让、公开披露您的信息</p>
          <p>四、我们如何保护您的信息</p>
          <p>五、您如何管理您的信息</p>
          <p>六、我们如何处理未成年人的信息</p>
          <p>七、本隐私政策如何更新</p>
          <p>八、如何联系我们</p>
          <p>&nbsp;</p>
          <p><strong>一、 我们如何收集和使用您的信息</strong></p>
          <p>
            在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：
          </p>
          <p>
            1、<strong
              >为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；</strong
            >
          </p>
          <p>
            2、<strong
              >为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。</strong
            >
          </p>
          <p>
            <strong><u>您理解并同意：</u></strong>
          </p>
          <p>
            <strong>1</strong
            ><strong
              >、<u>我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准</u>；</strong
            >
          </p>
          <p>
            <strong>2</strong
            ><strong
              >、<u>为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用</u>。</strong
            >在此过程中，如果您有任何疑问、意见或建议的，您可通过本平台提供的各种联系方式与我们联系，我们会尽快为您作出解答。
          </p>
          <p>&nbsp;</p>
          <p>我们会为实现本政策下述的各项功能，收集和使用您的个人信息：</p>
          <p>
            <strong
              >（一）&nbsp;帮助您注册，并向您提供本平台所提供的服务；</strong
            >
          </p>
          <p><strong>1</strong><strong>、注册服务</strong></p>
          <p>
            我们通过本平台账户为您提供基础会员服务。为注册成为会员并使用我们的会员服务，您在申请注册时需要填写和提供以下信息：
          </p>
          <p>
            （1）身份信息。该信息可以帮助我们验证您是否有资格注册为本平台会员，包括但不限于您的名字、您的居住地址、您所属国家或政府签发的其他具有证明您身份信息的不同的证书以及涉及到的号码及所有其他可帮助我们验证您身份的信息（以下合称为“身份信息”&nbsp;）。该身份信息具体根据您是个人用户还是机构用户而有所不同，根据不同司法管辖区的法律和法规，我们针对来自不同国家或地区的用户所收集的信息可能有所不同。以下信息和数据作为一个指示性的示例：
          </p>
          <p>
            <strong>个人用户</strong
            >：国籍、法定全名（包括曾用名和当地语言名称）、身份证号、电子邮件地址、电话号码、社会安全号码（“SSN”），护照号码或任何政府颁发的身份证号码、出生日期、身份证明（例如护照，驾驶执照或政府颁发的身份证明）。
          </p>
          <p>
            <strong>机构用户</strong
            >：公司法定名称（包括当地语言的法定名称）、注册信息、商务电话号码、授权代表及授权用户个人信息、主要营业地点、所有实益拥有人的个人信息）、股东/实益拥有人的身份证明文件（护照或政府签发的身份证）、公司架构、公司成立相关文件、董事会决议、资金来源申报、风险与合规控制说明及平台要求的其他信息或者文件。
          </p>
          <p>
            （2）服务信息。该信息帮助我们与您联系并顺利为您提供不同模式的数字资产间交易服务，包括但不限于您的个人身份信息、联系信息、交易信息、以及您的借记卡信息和/或其他帐户信息（以下合称为“服务信息”&nbsp;）。<strong>同时</strong>请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的<strong>额外</strong>信息的，我们会通过页面提示、交互流程、协议约定、弹窗提示等方式向您说明信息收集的范围与目的，并征得您的同意。
          </p>
          <p>
            如果您仅需使用浏览服务，您不需要注册成为我们的会员及提供上述信息。
          </p>
          <p><strong>2</strong><strong>、其他注册服务</strong></p>
          <p>
            实名认证：当您注册登录后，需进入"个人中心"在身份认证中完成实名认证，您在使用实名认证过程中需向平台提供您的真实姓名、身份证号或护照号。
          </p>
          <p>
            高级认证：当您在本平台中的交易额超过一定限额时，平台为了维护您的资产安全，会要求对您进行高级认证。认证过程除实名认证信息外，您认可平台对您进行“人脸识别”的高级认证，人脸识别会涉及到平台对您的相关人脸图像的收集。
          </p>
          <p>
            <strong
              ><u
                >账户信息展示：如果您已拥有本平台账户，我们可能会在服务中显示您的上述个人信息（实名认证仅显示认证是否通过的结果），以及您在平台账户相关联的产品和服务中执行的操作，包括通过本平台账户集中展示您的个人资料、交易订单。我们会尊重您对平台账户设置所做的选择。</u
              ></strong
            >
          </p>
          <p>
            <strong>（二）</strong>&nbsp;<strong
              >安全管理服务和服务信息推送服务</strong
            >
          </p>
          <p>
            当您使用本平台提供的服务或访问本平台时，本平台为保障您的交易安全及服务的安全稳定运行，我们需要自动接收并记录您的Web浏览器信息，您登录账户使用的设备信息（IDFA、操作系统、设备型号、设备制造商、系统版本、Android
            ID、IMEI&nbsp;以及&nbsp;MAC&nbsp;地址等）、您请求访问的网页上的IP地址及记录、所在地区、网络环境、设备指纹信息、服务日志信息、软件信息、位置信息等。
          </p>
          <p>
            <strong
              ><u
                >请注意，单独的设备信息是无法识别特定自然人身份的信息。</u
              ></strong
            >如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
          </p>
          <p>
            <strong
              ><u
                >为向您提供更便捷、更符合您个性化需求的信息展示及推送服务，我们未来可能会根据您的设备信息和您本平台或客户端的浏览使用信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息和可能的商业广告。若您不同意接收此类推送信息或撤回同意收集个人信息，您可根据本政策提供的联系方式与我们联系。</u
              ></strong
            >
          </p>
          <p>
            我们努力保障您的浏览体验。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。
          </p>
          <p>此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。</p>
          <p><strong>（三）交易记录保管及查询服务</strong></p>
          <p>
            我们就您在本平台上的交易所收集的相关数据，包括但不限于交易记录。如您需查询您的订单交易记录，可在平台“订单管理”中查询您的个人交易明细，具体包括：交易对方昵称、下单时间、订单号、交易信息、数字资产种类及数量等。
          </p>
          <p><strong>（四） 客服及争议处理</strong></p>
          <p>
            当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，<strong
              ><u>我们需要您提供必要的个人信息以核验您的会员身份。</u></strong
            >
          </p>
          <p>
            <strong
              ><u
                >为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。</u
              ></strong
            >
          </p>
          <p>
            为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
          </p>
          <p><strong>（五）其他</strong></p>
          <p>
            <strong><u>1</u></strong
            ><strong
              ><u
                >、若你提供的信息中含有其他用户的个人信息，在向本平台提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策第九条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。</u
              ></strong
            >
          </p>
          <p>
            <strong><u>2</u></strong
            ><strong
              ><u
                >、若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。</u
              ></strong
            >
          </p>
          <p>
            若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
          </p>
          <p><strong>3</strong><strong>、征得授权同意的例外</strong></p>
          <p>
            您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
          </p>
          <p>（1）与国家安全、国防安全有关的；</p>
          <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
          <p>（3）与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
          <p>
            （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          </p>
          <p>（5）您自行向社会公众公开的个人信息；</p>
          <p>
            （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          </p>
          <p>（7）根据与您签订和履行相关协议或其他书面文件所必需的；</p>
          <p>
            （8）用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；
          </p>
          <p>（9）为合法的新闻报道所必需的；</p>
          <p>
            （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
          </p>
          <p>（11）法律法规规定的其他情形。</p>
          <p>
            请知悉，根据适用的法律，若我们<strong
              ><u
                >对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原</u
              ></strong
            >，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善本平台的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），<strong
              ><u
                >则此类处理后数据的使用无需另行向您通知并征得您的同意</u
              ></strong
            >。
          </p>
          <p>
            4、如我们停止运营本平台产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。
          </p>
          <p>
            <strong
              >二、我们如何使用{{ this.basicConfig.CompanyNameEnglish }}</strong
            >
          </p>
          <p>
            <strong
              ><u
                >如果您的浏览器或浏览器附加服务允许，您可修改对{{
                  this.basicConfig.CompanyNameEnglish
                }}的接受程度或拒绝我们的{{
                  this.basicConfig.CompanyNameEnglish
                }}。</u
              ></strong
            >。但如果您这么做，在某些情况下可能会影响您安全访问我们的平台，且可能需要在每一次访问我们的平台时更改用户设置。您理解并且同意：
          </p>
          <p>
            1、 当您到访我们平台时, 我们通过{{
              this.basicConfig.CompanyNameEnglish
            }}使用Google Stats去记录我们的业绩以及核对在线广告的效果。{{
              this.basicConfig.CompanyNameEnglish
            }}是发送到您的浏览器上并在您的电脑硬盘驱动器上存储的小量数据。只有当您使用您的电脑进入我们平台时,{{
              this.basicConfig.CompanyNameEnglish
            }}才能够被发送到您的电脑硬盘上。
          </p>
          <p>
            2、{{
              this.basicConfig.CompanyNameEnglish
            }}常用于记录访问者浏览我们平台上的各个项目时的习惯以及偏好。{{
              this.basicConfig.CompanyNameEnglish
            }}所搜集的资料是不记名的集体统计数据, 不载有个人资料。
          </p>
          <p>
            3、{{
              this.basicConfig.CompanyNameEnglish
            }}不能用于取得您的硬盘上的数据、您的电邮地址、及您的私人数据，其可使本平台或服务商系统识别到您的浏览器并捕捉和记忆信息。大多数浏览器都预设为可以接受{{
              this.basicConfig.CompanyNameEnglish
            }}。您可以选择将您的浏览器设定为不接受{{
              this.basicConfig.CompanyNameEnglish
            }},或如果{{
              this.basicConfig.CompanyNameEnglish
            }}一被装上就通知您。不过, 若设定为禁止{{
              this.basicConfig.CompanyNameEnglish
            }}, 您或许便不能启动或使用我们平台的某些功能。
          </p>
          <p><strong>三、我们如何共享、转让、公开披露您的信息</strong></p>
          <p><strong>（一）共享</strong></p>
          <p>
            我们不会与本平台服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
          </p>
          <p>
            1、在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
          </p>
          <p>
            2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
          </p>
          <p>
            3、在您主动选择情况下共享：我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给数字资产的交易对方，以实现您的交易及售后服务需求。
          </p>
          <p>
            4、与授权合作伙伴共享：我们的关联方、帮助我们经营我们的平台、开展业务、或者向您提供服务的受信任的第三方，只要这些当事方同意将这些信息保密；当我们相信披露的信息是适当的，是遵守法律、法规、规章制度的或来自于法院或他主管当局的命令、执行我们的平台策略，正常运行平台所需要，关联方提供服务所需或保护我们或他人的权利、财产或安全的。
          </p>
          <p><strong>（二）转让</strong></p>
          <p>
            我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          </p>
          <p>
            1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
          </p>
          <p>
            2、在本平台发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
          </p>
          <p><strong>（三）公开披露</strong></p>
          <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
          <p>
            1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
          </p>
          <p>
            2、<strong
              ><u
                >如果我们确定您出现违反法律法规或严重违反本平台相关协议及规则的情况，或为保护本平台用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息</u
              ></strong
            >，包括相关违规行为以及本平台已对您采取的措施。
          </p>
          <p>
            <strong
              >（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</strong
            >
          </p>
          <p>
            以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
          </p>
          <p>1、与国家安全、国防安全有关的；</p>
          <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
          <p>3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
          <p>
            4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          </p>
          <p>5、您自行向社会公众公开的个人信息；</p>
          <p>
            6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          </p>
          <p>
            请知悉，根据适用的法律，若我们<strong
              ><u
                >对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意</u
              ></strong
            >。
          </p>
          <p><strong>四、我们如何保护您的信息</strong></p>
          <p>
            1、我们实施妥善的实物、电子、管理及技术方面的措施来保护和保障您的个人资料的安全。我们尽力确保通过我们平台所搜集的任何个人资料皆免于任何与我们无关的第三者的滋扰。我们采取的安全措施包括但不限于：
          </p>
          <p>（1）实物措施：存有您个人资料的记录会被存放在有锁的地方。</p>
          <p>
            （2）电子措施：存有您个人资料的电脑数据会被存放在受严格登入限制的电脑系统和存储媒体上。
          </p>
          <p>
            （3）管理措施：我们内部设立有对用户信息进行安全保护的相关部门，并建立了相关内控制度，对可能接触到您的信息的工作人员采取严格授权原则，只有经我们授权的职员才能接触到您的个人资料,&nbsp;这些职员需要遵守我们个人资料保密的内部守则。此外，我们不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程
          </p>
          <p>
            （4）技术措施：可能采用如Secure Socket Layer
            Encryption这种加密技术来输送您的个人资料。
          </p>
          <p>
            （5）安全措施：为保障您的信息安全，我们致力于使用各种现行通用的安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、信息加密存储、严格限制数据中心的访问。传输和存储个人敏感信息（含个人生物识别信息）时，我们将采用加密、权限控制、去标识化、脱敏化等安全措施。
          </p>
          <p>
            （6）其它措施：定期审查我们的个人资料收集，存储和处理流程;&nbsp;并根据必要的原则限制我们的员工和供应商对您数据的访问权限，且我们的员工和供应商均需遵守严格的合同保密义务。。
          </p>
          <p>
            2、若您知悉我们的平台上有任何安全方面的漏洞,&nbsp;请马上联系我们,&nbsp;使我们可以尽快采取妥适的行动。
          </p>
          <p>
            3、尽管实施了上述技术和保安的措施,&nbsp;我们不能保证资料在互联网上的输送绝对安全,&nbsp;因此我们不能绝对保证您通过我们平台提供给我们的个人资料在一切时候都是安全的。我们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过本平台公告方式获得。
          </p>
          <p><strong>五、您如何管理您的信息</strong></p>
          <p>您可以通过以下方式访问及管理您的信息：</p>
          <p><strong>（一）查询、更正和删除您的信息</strong></p>
          <p>
            您有权根据要求获得您的个人资料副本，并确定我们掌握的关于您的信息是否准确并且是最新的。如果您的任何个人数据不准确，您可以要求更新您的信息。您也可以要求删除您的个人资料，但我们可能会在某些情况下拒绝您的删除请求，例如出于法律要求或其他法律目的。有关数据访问，更正或删除请求，您可以通过第八条中的联系方式联系我们。&nbsp;
          </p>
          <p>
            为了响应数据访问，更正或删除请求，我们将验证请求方的身份，以确保他/她在法律上有权提出此类请求。虽然我们的目标是免费回复这些请求，但我们保留在您的请求重复或繁重时向您收取合理费用的权利。&nbsp;
          </p>
          <p><strong>（二）改变您授权同意的范围</strong></p>
          <p>
            每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一部分”）。除此之外，您可以通过第八条中的联系方式联系我们给予或收回您的授权同意。
          </p>
          <p>
            当您收回同意后，我们将不再处理相应的个人信息。<strong
              ><u
                >但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</u
              ></strong
            >
          </p>
          <p><strong>（三）注销您的账户</strong></p>
          <p>
            您可以通过第八条中的联系方式联系我们来申请注销您的账户；在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。
          </p>
          <p><strong>（四）响应您的上述请求</strong></p>
          <p>
            为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
          </p>
          <p>
            我们将在15天内做出答复。如您不满意，还可以通过我们提供的联系方式发起投诉。
          </p>
          <p>
            对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
          </p>
          <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
          <p>1、与国家安全、国防安全有关的；</p>
          <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
          <p>3、与犯罪侦查、起诉、审判和执行判决等有关的；</p>
          <p>4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
          <p>
            5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
          </p>
          <p>6、涉及商业秘密的。</p>
          <p><strong>六、我们如何处理未成年人的信息</strong></p>
          <p>
            本平台向儿童开放，如果我们发现或怀疑用户未满14周岁，我们会要求用户关闭其帐户，且不会允许该用户继续使用平台产品或服务。我们会尽快删除有关资料。如果您知道有任何14周岁以下的人使用我们的服务，请通知我们，以便我们采取行动阻止他们获取我们的产品或服务。
          </p>
          <p>
            对于已满14岁但尚未满18周岁的未成年人，我们期望父母或监护人指导未成年人使用我们的服务。我们将根据相关法律法规的规定保护未成年人的信息的保密性及安全性。
          </p>
          <p>
            如您为未成年人，建议请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的信息。对于经父母或监护人同意而收集您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
          </p>
          <p>
            如您为未成年人的父母或监护人，当您对您所监护的未成年人的信息处理存在疑问时，请通过本政策公布的联系方式联系我们。
          </p>
          <p><strong>七、本隐私权政策如何更新</strong></p>
          <p>我们的隐私权政策可能变更。</p>
          <p>
            未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在官方渠道上发布对隐私政策所做的任何变更。如果您不同意修订的内容，您应停止立即访问本平台。当隐私政策的更新版本发布，您持续访问本平台显示和表明您同意该更新的内容，并同意遵守该更新的隐私政策。
          </p>
          <p>
            对于重大变更，我们还会提供更为显著的通知（包括我们会通过本平台公示的方式进行通知甚至向您提供弹窗提示）。
          </p>
          <p>本政策所指的重大变更包括但不限于：</p>
          <p>
            1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          </p>
          <p>
            2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；
          </p>
          <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
          <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
          <p>
            5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；
          </p>
          <p>6、个人信息安全影响评估报告表明存在高风险。&nbsp;</p>
          <p><strong>八、如何联系我们</strong></p>
          <p>
            1、如果您有任何要求和意见，您可以通过电子邮件，这是属于我们与您沟通的唯一有效和官方电子邮件，所以我们对您未使用有效的联系方式，任何作为或不作为不承担责任。
          </p>
          <p>
            2、我们只通过本平台上的有效的联系方式发布公告和信息或在本平台张贴公告，所以我们对由于您信任了未通过以上方式获得的信息而产生的任何损失不承担责任。
          </p>
          <p>3、若您对我们的隐私政策有任何问题,&nbsp;欢迎随时联系我们。</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    <!-- 泰语 -->
    <div class="main" v-show="lang == 'ty'">
      <div class="box">
        <div class="top">
          <div class="text1">นโยบายความเป็นส่วนตัว</div>
          <div class="text2">วันที่แก้ไขล่าสุด: 2022-08-20</div>
        </div>
        <div class="content">
          <p><strong>【ข้อกำหนดเบื้องต้นและคำเตือน】</strong></p>
          <p>
            แพลตฟอร์มนี้เป็นแพลตฟอร์มสำหรับผู้ใช้ในการทำธุรกรรมสินทรัพย์ดิจิทัลและให้บริการที่เกี่ยวข้อง
            (ต่อไปนี้จะเรียกว่า "บริการ" หรือ "บริการ")
            เพื่อความสะดวกในการแสดงออก แพลตฟอร์มนี้เรียกโดยรวมว่า&nbsp;"เรา"
            หรือสรรพนามบุรุษที่ 1
            ตราบเท่าที่บุคคลธรรมดาหรือเรื่องอื่นที่เข้าสู่ระบบเป็นผู้ใช้แพลตฟอร์มนี้
            "คุณ" หรือสรรพนามบุรุษที่ 2 อื่นๆ ที่ใช้ด้านล่าง
            เราและคุณจะถูกเรียกโดยรวม ในฐานะ "ทั้งสองฝ่าย" เราหรือคุณในฐานะ
            "ฝ่ายเดียว"
          </p>
          <p>
            เราเข้าใจและเคารพในความสำคัญของข้อมูลส่วนบุคคลของคุณที่มีต่อคุณอย่างเต็มที่
            และเราจะใช้มาตรการป้องกันความปลอดภัยที่เกี่ยวข้องตามข้อกำหนดของกฎหมายและข้อบังคับเพื่อปกป้องความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
            ด้วยเหตุนี้ "นโยบายความเป็นส่วนตัว" นี้ (ต่อไปนี้เรียกว่า
            "นโยบายนี้") จึงถูกกำหนดขึ้น&nbsp;/นโยบายความเป็นส่วนตัวนี้")
            และเตือนคุณว่า:
          </p>
          <p>
            นโยบายนี้ใช้กับผลิตภัณฑ์และบริการทั้งหมดที่เรานำเสนอ<strong
              >ชอบ<u
                >หากผลิตภัณฑ์หรือบริการที่เราจัดหาให้คุณมีนโยบายความเป็นส่วนตัวแยกต่างหาก
                ผลิตภัณฑ์หรือบริการนั้นจะต้องอยู่ภายใต้นโยบายความเป็นส่วนตัวแยกต่างหาก
                หากผลิตภัณฑ์หรือบริการของเราไม่มีนโยบายความเป็นส่วนตัวแยกต่างหาก
                นโยบายนี้จะมีผลบังคับใช้</u
              ></strong
            >
          </p>
          <p>
            ควรสังเกตว่า<strong
              ><u
                >นโยบายนี้ใช้ไม่ได้กับบริการที่บุคคลภายนอกอื่นๆ มอบให้คุณ
                และบริการที่บุคคลภายนอกมอบให้คุณอยู่ภายใต้นโยบายความเป็นส่วนตัวของบุคคลภายนอกที่อธิบายให้คุณทราบต่างหาก</u
              ></strong
            >
          </p>
          <p>
            <strong
              ><u
                >ก่อนใช้ผลิตภัณฑ์หรือบริการใดๆ ของเรา
                โปรดอ่านและทำความเข้าใจนโยบายนี้อย่างถี่ถ้วนโดยเฉพาะเงื่อนไขที่ทำเครื่องหมายเป็นตัวหนา/ตัวหนา
                ขีดเส้นใต้
                คุณควรอ่านอย่างละเอียดและเริ่มใช้หลังจากยืนยันความเข้าใจและข้อตกลงทั้งหมดของคุณแล้ว
                หากคุณมีคำถาม ข้อคิดเห็น
                หรือข้อเสนอแนะเกี่ยวกับเนื้อหาของนโยบายนี้
                คุณสามารถติดต่อเราผ่านวิธีการติดต่ออย่างเป็นทางการต่างๆ
                ที่เราจัดเตรียมไว้ให้ หากคุณไม่ยอมรับเงื่อนไขใดๆ ของนโยบายนี้
                คุณควรหยุดการเข้าถึงแพลตฟอร์มนี้ทันที</u
              ></strong
            >
          </p>
          <p>&nbsp;</p>
          <p><strong>ส่วนแรก&nbsp;คำนิยาม</strong></p>
          <p>
            <strong>ข้อมูลส่วนบุคคล:</strong>หมายถึงข้อมูลต่างๆ
            ที่บันทึกทางอิเล็กทรอนิกส์หรือด้วยวิธีอื่นที่สามารถระบุบุคคลธรรมดาที่เฉพาะเจาะจงหรือสะท้อนถึงกิจกรรมของบุคคลธรรมดาที่เฉพาะเจาะจงไม่ว่าจะโดยลำพังหรือร่วมกับข้อมูลอื่น
          </p>
          <p>
            <strong>ข้อมูลส่วนบุคคลที่ละเอียดอ่อน: อ้างถึง</strong
            >ข้อมูลส่วนบุคคล ได้แก่ หมายเลขบัตรประจำตัวประชาชน
            ข้อมูลไบโอเมตริกซ์ส่วนบุคคล หมายเลขบัญชีธนาคาร ข้อมูลทรัพย์สิน
            ที่อยู่ ข้อมูลการทำธุรกรรม และข้อมูลของเด็กอายุต่ำกว่า 14 ปี
            (รวมถึง)<strong>ตัวหนา</strong>ระบุได้ชัดเจน)
          </p>
          <p>
            <strong>การลบข้อมูลส่วนบุคคล:</strong
            >หมายถึงการดำเนินการลบข้อมูลส่วนบุคคลออกจากระบบที่เกี่ยวข้องกับหน้าที่ทางธุรกิจประจำวันเพื่อให้ไม่สามารถเรียกคืนและเข้าถึงได้
          </p>
          <p><strong>เด็ก</strong>: หมายถึง ผู้เยาว์ที่มีอายุต่ำกว่าสิบสี่ปี</p>
          <p><strong>ส่วนที่สอง&nbsp;&nbsp;นโยบายความเป็นส่วนตัว</strong></p>
          <p>ส่วนนโยบายความเป็นส่วนตัวนี้จะช่วยให้คุณเข้าใจสิ่งต่อไปนี้:</p>
          <p>1. เรารวบรวมและใช้ข้อมูลของคุณอย่างไร</p>
          <p>
            2. เราจะใช้อย่างไร{{
              this.basicConfig.CompanyNameEnglish
            }}และเทคโนโลยีที่คล้ายกัน
          </p>
          <p>3. วิธีที่เราแบ่งปัน ถ่ายโอน และเปิดเผยข้อมูลของคุณสู่สาธารณะ</p>
          <p>4. เราจะปกป้องข้อมูลของคุณอย่างไร</p>
          <p>V. คุณจัดการข้อมูลของคุณอย่างไร</p>
          <p>6. เราจะจัดการกับข้อมูลของผู้เยาว์อย่างไร</p>
          <p>7. วิธีปรับปรุงนโยบายความเป็นส่วนตัวนี้</p>
          <p>8. วิธีการติดต่อเรา</p>
          <p>&nbsp;</p>
          <p><strong>1. เรารวบรวมและใช้ข้อมูลของคุณอย่างไร</strong></p>
          <p>
            เมื่อคุณใช้ผลิตภัณฑ์และ/หรือบริการของเรา
            ข้อมูลส่วนบุคคลของคุณที่เราจำเป็น/อาจจำเป็นต้องรวบรวมและใช้มีสองประเภทดังต่อไปนี้:
          </p>
          <p>
            1、<strong
              >เพื่อให้ฟังก์ชันพื้นฐานของผลิตภัณฑ์และ/หรือบริการของเราแก่คุณ
              คุณต้องอนุญาตให้เรารวบรวมและใช้ข้อมูลที่จำเป็น
              หากคุณปฏิเสธที่จะให้ข้อมูลที่เกี่ยวข้อง
              คุณจะไม่สามารถใช้ผลิตภัณฑ์และ/หรือบริการของเราได้ตามปกติ</strong
            >
          </p>
          <p>
            2、<strong
              >เพื่อให้ฟังก์ชันเพิ่มเติมของผลิตภัณฑ์และ/หรือบริการของเราแก่คุณ
              คุณอาจเลือกที่จะอนุญาตให้เรารวบรวมและใช้ข้อมูล
              หากคุณปฏิเสธที่จะให้บริการ
              คุณจะไม่สามารถใช้ฟังก์ชันเพิ่มเติมที่เกี่ยวข้องหรือบรรลุผลตามหน้าที่ที่เราตั้งใจไว้ได้
              แต่จะไม่ส่งผลกระทบต่อการใช้งานฟังก์ชันพื้นฐานของผลิตภัณฑ์และ/หรือบริการของเราตามปกติ</strong
            >
          </p>
          <p>
            <strong><u>คุณเข้าใจและตกลงว่า:</u></strong>
          </p>
          <p>
            <strong>1</strong
            ><strong
              >、<u
                >เรามุ่งมั่นที่จะสร้างสรรค์ผลิตภัณฑ์และบริการที่หลากหลายเพื่อตอบสนองความต้องการของคุณ
                เนื่องจากเราจัดหาผลิตภัณฑ์และบริการที่หลากหลายให้กับคุณ
                และช่วงของผลิตภัณฑ์/บริการเฉพาะที่เลือกโดยผู้ใช้ที่แตกต่างกันนั้นแตกต่างกัน
                ดังนั้น ฟังก์ชันพื้นฐาน/เพิ่มเติม
                และประเภทและขอบเขตของข้อมูลส่วนบุคคลที่รวบรวมและใช้ก็จะแตกต่างกันตามไปด้วย
                โปรดดูคุณสมบัติผลิตภัณฑ์/บริการเฉพาะที่จะเหนือกว่า</u
              >；</strong
            >
          </p>
          <p>
            <strong>2</strong
            ><strong
              >、<u
                >เพื่อให้คุณได้รับประสบการณ์ผลิตภัณฑ์และบริการที่ดีขึ้น
                เรายังคงทำงานอย่างหนักเพื่อปรับปรุงเทคโนโลยีของเรา
                และเราอาจเปิดตัวฟังก์ชันใหม่หรือปรับให้เหมาะสมเป็นครั้งคราว
                และอาจจำเป็นต้องรวบรวมและใช้ข้อมูลส่วนบุคคลใหม่หรือเปลี่ยนวัตถุประสงค์ของข้อมูลส่วนบุคคล
                หรือลักษณะการใช้งาน. ในเรื่องนี้
                เราจะอธิบายให้คุณทราบถึงวัตถุประสงค์ ขอบเขต
                และการใช้ข้อมูลที่เกี่ยวข้องโดยแยกจากกันโดยการปรับปรุงนโยบายนี้
                หน้าต่างป๊อปอัป ข้อความแจ้งหน้า ฯลฯ
                และให้คุณมีวิธีเลือกความยินยอมของคุณเอง และหลังจากนั้น
                ได้รับความยินยอมอย่างชัดแจ้งจากคุณในการเก็บรวบรวม ใช้</u
              >。</strong
            >ในระหว่างขั้นตอนนี้ หากคุณมีคำถาม ข้อคิดเห็น หรือข้อเสนอแนะ
            คุณสามารถติดต่อเราผ่านช่องทางการติดต่อต่างๆ ที่เรามีให้
            และเราจะตอบกลับคุณโดยเร็วที่สุด
          </p>
          <p>&nbsp;</p>
          <p>
            เราจะรวบรวมและใช้ข้อมูลส่วนบุคคลของคุณเพื่อให้บรรลุหน้าที่ต่อไปนี้ของนโยบายนี้:
          </p>
          <p>
            <strong
              >(หนึ่ง)&nbsp;ช่วยคุณลงทะเบียนและให้บริการจากแพลตฟอร์มนี้แก่คุณ</strong
            >
          </p>
          <p><strong>1</strong><strong>,บริการจดทะเบียน</strong></p>
          <p>
            เราให้บริการสมาชิกขั้นพื้นฐานแก่คุณผ่านบัญชีแพลตฟอร์มของคุณ
            ในการลงทะเบียนเป็นสมาชิกและใช้บริการสมาชิกของเรา
            คุณต้องกรอกและให้ข้อมูลต่อไปนี้เมื่อทำการสมัคร:
          </p>
          <p>
            (1) ข้อมูลประจำตัว
            ข้อมูลนี้สามารถช่วยให้เราตรวจสอบได้ว่าคุณมีสิทธิ์ลงทะเบียนเป็นสมาชิกของแพลตฟอร์มนี้หรือไม่
            ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะชื่อ ที่อยู่ ใบรับรองอื่นๆ
            ที่ออกโดยประเทศหรือรัฐบาลของคุณพร้อมข้อมูลที่พิสูจน์ตัวตนของคุณ
            และหมายเลขที่เกี่ยวข้อง และข้อมูลอื่นๆ
            ที่ช่วยให้เรายืนยันตัวตนของคุณ (รวมเรียกว่า "ข้อมูลระบุตัวตน"&nbsp;)
            ข้อมูลระบุตัวตนจะแตกต่างกันไปขึ้นอยู่กับว่าคุณเป็นผู้ใช้รายบุคคลหรือผู้ใช้สถาบัน
            ทั้งนี้ ตามกฎหมายและระเบียบข้อบังคับของเขตอำนาจศาลต่าง ๆ
            ข้อมูลที่เรารวบรวมสำหรับผู้ใช้จากประเทศหรือภูมิภาคต่าง ๆ
            อาจแตกต่างกัน ข้อมูลและข้อมูลต่อไปนี้เป็นตัวอย่างที่บ่งชี้:
          </p>
          <p>
            <strong>ผู้ใช้ส่วนบุคคล</strong>: สัญชาติ, ชื่อเต็มตามกฎหมาย
            (รวมถึงชื่อเดิมและชื่อภาษาท้องถิ่น), หมายเลขประจำตัวประชาชน,
            ที่อยู่อีเมล, หมายเลขโทรศัพท์, หมายเลขประกันสังคม (“SSN”),
            หมายเลขหนังสือเดินทางหรือหมายเลขประจำตัวที่ออกโดยหน่วยงานราชการ,
            วันเกิด, หลักฐาน ของบัตรประจำตัว (เช่น หนังสือเดินทาง ใบขับขี่
            หรือบัตรประจำตัวที่ออกโดยราชการ)
          </p>
          <p>
            <strong>ผู้ใช้สถาบัน</strong>: ชื่อตามกฎหมายของบริษัท
            (รวมถึงชื่อตามกฎหมายในภาษาท้องถิ่น), ข้อมูลการลงทะเบียน,
            หมายเลขโทรศัพท์ของธุรกิจ,
            ข้อมูลส่วนบุคคลของตัวแทนที่ได้รับอนุญาตและผู้ใช้ที่ได้รับอนุญาต,
            สถานที่หลักของธุรกิจ, ข้อมูลส่วนบุคคลของเจ้าของผลประโยชน์ทั้งหมด),
            เอกสารระบุตัวตนของผู้ถือหุ้น/เจ้าของผลประโยชน์ (
            หนังสือเดินทางหรือบัตรประจำตัวที่ออกโดยรัฐบาล) โครงสร้างบริษัท
            เอกสารที่เกี่ยวข้องกับการจัดตั้งบริษัท มติคณะกรรมการ
            การประกาศแหล่งทุน
            คำแนะนำในการควบคุมความเสี่ยงและการปฏิบัติตามกฎระเบียบ
            และข้อมูลหรือเอกสารอื่นๆ ที่แพลตฟอร์มกำหนด
          </p>
          <p>
            (2) ข้อมูลบริการ
            ข้อมูลนี้ช่วยให้เราสามารถติดต่อคุณและให้บริการธุรกรรมสินทรัพย์ดิจิทัลในรูปแบบต่างๆ
            ได้อย่างราบรื่น รวมถึงแต่ไม่จำกัดเฉพาะข้อมูลที่ระบุตัวบุคคลได้
            ข้อมูลติดต่อ ข้อมูลธุรกรรม
            และข้อมูลบัตรเดบิตและ/หรือข้อมูลบัญชีอื่นๆ ของคุณ
            (ต่อไปนี้เรียกรวมกันว่า เรียกว่า "ข้อมูลบริการ"&nbsp;)
            <strong>ในขณะเดียวกัน</strong>
            โปรดเข้าใจว่าบริการที่เรามอบให้คุณได้รับการปรับปรุงและพัฒนาอย่างต่อเนื่อง
            หากคุณเลือกที่จะใช้บริการอื่นๆ ที่ไม่ครอบคลุมในคำอธิบายด้านบน
            ตามบริการที่เราจำเป็นต้องเก็บรวบรวมข้อมูล
            <strong>เพิ่มเติม</strong> ของคุณ เราจะใช้การแจ้งหน้าเว็บ
            กระบวนการโต้ตอบ ข้อตกลง การแจ้งหน้าต่างป๊อปอัป ฯลฯ
            อธิบายให้คุณทราบขอบเขตและวัตถุประสงค์ของการรวบรวมข้อมูล
            และขอความยินยอมจากคุณ
          </p>
          <p>
            หากคุณต้องการเพียงใช้บริการเรียกดู
            คุณไม่จำเป็นต้องลงทะเบียนเป็นสมาชิกของเราและให้ข้อมูลข้างต้น
          </p>
          <p><strong>2</strong><strong> บริการจดทะเบียนอื่นๆ</strong></p>
          <p>
            การตรวจสอบชื่อจริง: หลังจากที่คุณลงทะเบียนและเข้าสู่ระบบ คุณต้องป้อน
            "ศูนย์ส่วนบุคคล"
            เพื่อดำเนินการตรวจสอบชื่อจริงให้เสร็จสมบูรณ์ในการยืนยันตัวตน
            คุณต้องแจ้ง ชื่อจริง หมายเลขประจำตัวประชาชน หรือ
            หมายเลขหนังสือเดินทาง แก่แพลตฟอร์มในระหว่าง
            ขั้นตอนการตรวจสอบชื่อจริง
          </p>
          <p>
            การรับรองขั้นสูง: เมื่อปริมาณธุรกรรมของคุณบนแพลตฟอร์มนี้เกินขีดจำกัด
            แพลตฟอร์มจะกำหนดให้คุณต้องผ่านการรับรองขั้นสูงเพื่อรักษาความปลอดภัยของทรัพย์สินของคุณ
            นอกจากข้อมูลการยืนยันตัวตนด้วยชื่อจริงในระหว่างกระบวนการยืนยันตัวตน
            คุณตกลงว่าแพลตฟอร์มจะดำเนินการตรวจสอบขั้นสูงของ "การจดจำใบหน้า"
            กับคุณ
            และการจดจำใบหน้าจะเกี่ยวข้องกับการรวบรวมภาพใบหน้าที่เกี่ยวข้องของแพลตฟอร์ม
          </p>
          <p>
            <strong
              ><u
                >การแสดงข้อมูลบัญชี: หากคุณมีบัญชีบนแพลตฟอร์มนี้แล้ว
                เราอาจแสดงข้อมูลส่วนบุคคลของคุณที่กล่าวถึงข้างต้นในบริการ
                (การตรวจสอบชื่อจริงจะแสดงผลว่าผ่านการตรวจสอบหรือไม่เท่านั้น)
                และผลิตภัณฑ์และบริการของคุณ ดำเนินการในบัญชีแพลตฟอร์ม
                การดำเนินการ
                รวมถึงการแสดงข้อมูลส่วนตัวและคำสั่งธุรกรรมแบบรวมศูนย์ผ่านบัญชีของคุณบนแพลตฟอร์มนี้
                เราจะเคารพการตั้งค่าบัญชีแพลตฟอร์มที่คุณเลือก
              </u></strong
            >
          </p>
          <p>
            <strong>(2)</strong>&nbsp;<strong
              >บริการจัดการความปลอดภัยและบริการพุชข้อมูลบริการ</strong
            >
          </p>
          <p>
            เมื่อคุณใช้บริการจากแพลตฟอร์มนี้หรือเยี่ยมชมแพลตฟอร์มนี้
            เพื่อให้มั่นใจในความปลอดภัยของธุรกรรมของคุณและการทำงานที่ปลอดภัยและเสถียรของบริการ
            แพลตฟอร์มนี้จำเป็นต้องรับและบันทึกข้อมูลเว็บเบราว์เซอร์ของคุณโดยอัตโนมัติ
            ข้อมูลอุปกรณ์ของคุณ ใช้เพื่อเข้าสู่บัญชีของคุณ ( IDFA,
            ระบบปฏิบัติการ, รุ่นอุปกรณ์, ผู้ผลิตอุปกรณ์, เวอร์ชันระบบ, Android
            ID, IMEI และที่อยู่ MAC ฯลฯ), ที่อยู่ IP
            และบันทึกบนหน้าเว็บที่คุณขอเยี่ยมชม, ตำแหน่ง, สภาพแวดล้อมเครือข่าย,
            ข้อมูลลายนิ้วมือของอุปกรณ์, ข้อมูลบันทึกการบริการ, ข้อมูลซอฟต์แวร์,
            ข้อมูลตำแหน่ง ฯลฯ
          </p>
          <p>
            <strong
              ><u>
                โปรดทราบว่าข้อมูลอุปกรณ์แยกต่างหากเป็นข้อมูลที่ไม่สามารถระบุบุคคลธรรมดาได้
              </u></strong
            >
            หากเรารวมข้อมูลที่ไม่ใช่ข้อมูลส่วนบุคคลดังกล่าวเข้ากับข้อมูลอื่นเพื่อระบุบุคคลธรรมดาที่เฉพาะเจาะจง
            หรือใช้ร่วมกับข้อมูลส่วนบุคคล ในระหว่างการใช้ร่วมกัน
            ข้อมูลที่ไม่ใช่ข้อมูลส่วนบุคคลดังกล่าวจะถือเป็นข้อมูลส่วนบุคคล
            กำหนดไว้เป็นอย่างอื่นโดยการอนุญาตหรือกฎหมายและข้อบังคับ
            เราจะปกปิดชื่อและยกเลิกการระบุข้อมูลดังกล่าว
          </p>
          <p>
            <strong
              ><u
                >เพื่อให้บริการแสดงข้อมูลและบริการพุชที่สะดวกและสอดคล้องกับความต้องการส่วนบุคคลของคุณมากขึ้น
                เราอาจแยกลักษณะความชอบของคุณตามข้อมูลอุปกรณ์และข้อมูลการท่องเว็บและการใช้งานของคุณบนแพลตฟอร์มหรือไคลเอนต์นี้ในอนาคต
                และตามลักษณะเฉพาะ แท็กจะสร้างภาพบุคคลทางอ้อมสำหรับการแสดง
                ข้อมูลพุช และโฆษณาเชิงพาณิชย์ที่เป็นไปได้
                หากคุณไม่ยินยอมที่จะรับข้อมูลผลักดันดังกล่าวหรือเพิกถอนความยินยอมของคุณในการเก็บรวบรวมข้อมูลส่วนบุคคล
                คุณสามารถติดต่อเราได้ตามข้อมูลการติดต่อที่ให้ไว้ในนโยบายนี้
              </u></strong
            >
          </p>
          <p>
            เราทำงานอย่างหนักเพื่อปกป้องประสบการณ์การท่องเว็บของคุณ
            หากคุณไม่ต้องการยอมรับโฆษณาเชิงพาณิชย์ที่เราส่งให้คุณ
            คุณสามารถยกเลิกการสมัครหรือปิดโดยการตอบกลับข้อความแจ้งทาง SMS
            หรือวิธีการอื่นๆ ที่เรามีให้
          </p>
          <p>
            นอกจากนี้
            เราจะใช้ข้อมูลข้างต้นของคุณเพื่อปรับปรุงและเพิ่มประสิทธิภาพฟังก์ชันข้างต้นอย่างต่อเนื่อง
          </p>
          <p><strong>(3) บริการเก็บบันทึกธุรกรรมและสอบถาม</strong></p>
          <p>
            ข้อมูลที่เกี่ยวข้องที่เรารวบรวมเกี่ยวกับธุรกรรมของคุณบนแพลตฟอร์มนี้
            รวมถึงแต่ไม่จำกัดเพียงบันทึกธุรกรรม
            หากคุณต้องการสอบถามเกี่ยวกับบันทึกธุรกรรมการสั่งซื้อของคุณ
            คุณสามารถสอบถามเกี่ยวกับรายละเอียดธุรกรรมส่วนบุคคลของคุณได้ใน
            "การจัดการคำสั่งซื้อ" ของแพลตฟอร์ม รวมถึง:
            ชื่อเล่นของคู่สัญญาในการทำธุรกรรม เวลาสั่งซื้อ หมายเลขคำสั่งซื้อ
            ข้อมูลธุรกรรม ประเภทและปริมาณสินทรัพย์ดิจิทัล เป็นต้น
          </p>
          <p><strong>(4) การบริการลูกค้าและการระงับข้อพิพาท</strong></p>
          <p>
            เพื่อปกป้องความปลอดภัยของบัญชีและระบบของคุณ
            เมื่อคุณติดต่อเราหรือส่งใบสมัครสำหรับการจัดการข้อพิพาทระหว่างและหลังการขาย<strong
              ><u
                >เราต้องการให้คุณระบุข้อมูลส่วนบุคคลที่จำเป็นเพื่อยืนยันการเป็นสมาชิกของคุณ
              </u></strong
            >
          </p>
          <p>
            <strong
              ><u>
                เพื่ออำนวยความสะดวกในการติดต่อกับคุณ
                ช่วยคุณแก้ปัญหาโดยเร็วที่สุด
                หรือบันทึกแนวทางแก้ไขและผลลัพธ์ของปัญหาที่เกี่ยวข้อง
                เราอาจบันทึกบันทึกการสื่อสารของคุณกับเราและเนื้อหาที่เกี่ยวข้อง
                (รวมถึงข้อมูลบัญชี ข้อมูลการสั่งซื้อ และข้อมูลที่มีให้
                โดยคุณเพื่อพิสูจน์ข้อเท็จจริงที่เกี่ยวข้อง) ข้อมูลอื่น ๆ
                หรือข้อมูลติดต่อที่คุณทิ้งไว้) หากคุณปรึกษา ร้องเรียน
                หรือให้คำแนะนำสำหรับคำสั่งซื้อเฉพาะ
                เราจะใช้ข้อมูลบัญชีและข้อมูลการสั่งซื้อของคุณ
              </u></strong
            >
          </p>
          <p>
            เพื่อให้บริการและปรับปรุงความต้องการที่เหมาะสมของคุณภาพการบริการ
            เราอาจใช้ข้อมูลอื่นๆ เกี่ยวกับคุณ
            รวมถึงข้อมูลที่เกี่ยวข้องที่คุณให้ไว้เมื่อคุณติดต่อฝ่ายบริการลูกค้า
            และข้อมูลการตอบแบบสอบถามที่คุณส่งถึงเราเมื่อคุณเข้าร่วมในการสำรวจแบบสอบถาม
          </p>
          <p><strong>(5) อื่นๆ</strong></p>
          <p>
            <strong><u>1</u></strong
            ><strong
              ><u>
                หากข้อมูลที่คุณระบุมีข้อมูลส่วนบุคคลของผู้ใช้รายอื่น
                คุณต้องแน่ใจว่าคุณได้รับอนุญาตตามกฎหมายก่อนที่จะให้ข้อมูลส่วนบุคคลดังกล่าวแก่แพลตฟอร์มนี้
                หากเกี่ยวข้องกับข้อมูลส่วนบุคคลของเด็ก
                คุณต้องได้รับความยินยอมจากผู้ปกครองของเด็กที่เกี่ยวข้องก่อนที่จะโพสต์
                ในกรณีข้างต้น ผู้ปกครองมีสิทธิ์ติดต่อเราผ่านช่องทางในข้อ 9
                ของนโยบายนี้เพื่อขอให้แก้ไขหรือลบข้อมูลดังกล่าว
                เนื้อหาที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของเด็ก
              </u></strong
            >
          </p>
          <p>
            <strong><u>2</u></strong
            ><strong
              ><u>
                หากเราใช้ข้อมูลเพื่อวัตถุประสงค์อื่นที่ไม่ได้ระบุไว้ในนโยบายนี้
                หรือใช้ข้อมูลที่รวบรวมตามวัตถุประสงค์เฉพาะเพื่อวัตถุประสงค์อื่น
                หรือเราได้รับข้อมูลส่วนบุคคลของคุณจากบุคคลที่สามอย่างจริงจัง
                เราจะได้รับข้อมูลส่วนบุคคลของคุณล่วงหน้าตามที่ตกลงไว้
              </u></strong
            >
          </p>
          <p>
            หากเราได้รับข้อมูลของคุณโดยอ้อมจากบุคคลที่สาม
            เราจะร้องขออย่างชัดเจนให้บุคคลที่สามรวบรวมข้อมูลส่วนบุคคลหลังจากได้รับความยินยอมจากคุณตามกฎหมายก่อนที่จะเก็บรวบรวม
            และแจ้งให้คุณทราบถึงเนื้อหาของข้อมูลที่แบ่งปันซึ่งเกี่ยวข้องกับข้อมูลที่ละเอียดอ่อนมาก่อน
            มีไว้ให้เราใช้จำเป็นต้องได้รับการยืนยันอย่างชัดเจนจากคุณและบุคคลที่สามจะต้องให้คำมั่นสัญญาต่อความถูกต้องตามกฎหมายและการปฏิบัติตามแหล่งที่มาของข้อมูลส่วนบุคคลหากบุคคลที่สามละเมิดกฎหมายเราจะดำเนินการอย่างชัดเจน
            กำหนดให้อีกฝ่ายต้องรับผิดชอบทางกฎหมายที่เกี่ยวข้อง ในขณะเดียวกัน
            เราจะเสริมความปลอดภัยให้กับข้อมูลส่วนบุคคล
            (รวมถึงการรายงานข้อมูลที่ละเอียดอ่อน
            การจัดเก็บข้อมูลที่ละเอียดอ่อนที่เข้ารหัส การควบคุมการเข้าถึง ฯลฯ)
            เราจะใช้วิธีการและมาตรการในการป้องกันไม่น้อยไปกว่าที่เราใช้กับข้อมูลส่วนบุคคลของผู้ใช้ของเราเองเพื่อปกป้องข้อมูลส่วนบุคคลที่ได้รับโดยอ้อม
          </p>
          <p>
            <strong>3</strong
            ><strong> ข้อยกเว้นความยินยอมที่ได้รับอนุญาต</strong>
          </p>
          <p>
            คุณเข้าใจดีและตกลงว่าเราไม่ต้องการการอนุญาตและความยินยอมจากคุณในการรวบรวมและใช้ข้อมูลส่วนบุคคลของคุณภายใต้สถานการณ์ต่อไปนี้
            และเราอาจไม่ตอบสนองต่อคำขอแก้ไข/ปรับเปลี่ยน ลบ ยกเลิก
            เพิกถอนความยินยอม หรือคำขอข้อมูลของคุณ :
          </p>
          <p>
            (1) เกี่ยวข้องกับความมั่นคงของชาติและความมั่นคงในการป้องกันประเทศ
          </p>
          <p>
            (2) เกี่ยวข้องกับความปลอดภัยสาธารณะ สาธารณสุข
            และผลประโยชน์สาธารณะที่สำคัญ;
          </p>
          <p>
            (3) ที่เกี่ยวข้องกับการบังคับใช้กฎหมายของฝ่ายตุลาการหรือฝ่ายบริหาร
            เช่น การสืบสวนคดีอาญา การฟ้องร้อง การพิจารณาคดีและการบังคับคดี
          </p>
          <p>
            (4) เพื่อปกป้องชีวิตส่วนตัวของคุณ ทรัพย์สิน
            และสิทธิและผลประโยชน์ทางกฎหมายที่สำคัญอื่นๆ ของคุณ
            แต่เป็นการยากที่จะได้รับความยินยอมจากบุคคลนั้น
          </p>
          <p>(5) ข้อมูลส่วนบุคคลที่คุณเปิดเผยต่อสาธารณะ</p>
          <p>
            (6) ข้อมูลส่วนบุคคลถูกรวบรวมจากข้อมูลที่เปิดเผยตามกฎหมาย เช่น
            รายงานข่าวทางกฎหมาย การเปิดเผยข้อมูลของราชการ และช่องทางอื่นๆ
          </p>
          <p>
            (7)
            จำเป็นต้องลงนามและปฏิบัติตามข้อตกลงที่เกี่ยวข้องหรือเอกสารที่เป็นลายลักษณ์อักษรอื่นๆ
            กับคุณ
          </p>
          <p>
            (8)
            จำเป็นสำหรับการรักษาการทำงานที่ปลอดภัยและมีเสถียรภาพของผลิตภัณฑ์และ/หรือบริการที่จัดหาให้
            เช่น การค้นหาและจัดการกับผลิตภัณฑ์และ/หรือบริการที่ล้มเหลว;
          </p>
          <p>(9) จำเป็นสำหรับการรายงานข่าวที่ถูกต้องตามกฎหมาย</p>
          <p>
            (10)
            สถาบันวิจัยวิชาการจำเป็นต้องดำเนินการวิจัยเชิงสถิติหรือเชิงวิชาการตามความสนใจของสาธารณะ
            และเมื่อให้ผลการวิจัยทางวิชาการหรือคำอธิบายแก่โลกภายนอก
            ให้ยกเลิกการระบุข้อมูลส่วนบุคคลที่อยู่ในผลลัพธ์
          </p>
          <p>(11) สถานการณ์อื่น ๆ ที่กำหนดโดยกฎหมายและข้อบังคับ</p>
          <p>
            โปรดทราบว่าภายใต้กฎหมายที่ใช้บังคับ หากเรา<strong
              ><u
                >ใช้มาตรการทางเทคนิคและมาตรการที่จำเป็นอื่นๆ
                เพื่อประมวลผลข้อมูลส่วนบุคคล
                เพื่อให้ผู้รับข้อมูลไม่สามารถระบุตัวบุคคลซ้ำและไม่สามารถกู้คืนได้</u
              ></strong
            >
            หรือเราอาจทำการวิจัยที่ไม่ระบุตัวตน
            วิเคราะห์ทางสถิติและคาดการณ์ข้อมูลที่รวบรวมเพื่อปรับปรุงเนื้อหาและเค้าโครงของแพลตฟอร์มนี้
            ให้การสนับสนุนผลิตภัณฑ์หรือบริการสำหรับการตัดสินใจทางธุรกิจ
            และปรับปรุงผลิตภัณฑ์และบริการของเรา
            (รวมถึงการใช้ข้อมูลที่ไม่ระบุชื่อสำหรับ
            การเรียนรู้ของเครื่องหรือการฝึกอบรมอัลกอริทึมแบบจำลอง)
            <strong
              ><u
                >การใช้ข้อมูลที่ประมวลผลดังกล่าวไม่จำเป็นต้องแจ้งให้คุณทราบและได้รับความยินยอมจากคุณ</u
              ></strong
            >.
          </p>
          <p>
            4. หากเราหยุดดำเนินการผลิตภัณฑ์หรือบริการของแพลตฟอร์มนี้
            เราจะหยุดรวบรวมข้อมูลส่วนบุคคลของคุณในเวลาที่เหมาะสม
            แจ้งให้คุณทราบถึงการหยุดดำเนินการในรูปแบบของการจัดส่งหรือประกาศทีละรายการ
            และดำเนินการ
            การตรวจสอบข้อมูลที่เกี่ยวข้องที่เรามีอยู่อย่างละเอียดถี่ถ้วนข้อมูลส่วนบุคคลที่เกี่ยวข้องกับการระงับธุรกิจจะถูกลบหรือไม่ระบุชื่อ
          </p>
          <p>
            <strong
              >2. เราจะใช้อย่างไร{{
                this.basicConfig.CompanyNameEnglish
              }}</strong
            >
          </p>
          <p>
            <strong
              ><u
                >หากเบราว์เซอร์หรือส่วนขยายของเบราว์เซอร์อนุญาต คุณสามารถแก้ไข{{
                  this.basicConfig.CompanyNameEnglish
                }}การยอมรับหรือการปฏิเสธของเรา{{
                  this.basicConfig.CompanyNameEnglish
                }}。</u
              ></strong
            >. อย่างไรก็ตาม หากคุณทำเช่นนั้น
            อาจส่งผลต่อการเข้าถึงแพลตฟอร์มของเราอย่างปลอดภัยในบางกรณี
            และคุณอาจต้องเปลี่ยนการตั้งค่าผู้ใช้ทุกครั้งที่คุณเยี่ยมชมแพลตฟอร์มของเรา
            คุณเข้าใจและตกลงว่า:
          </p>
          <p>
            1. เมื่อคุณเยี่ยมชมแพลตฟอร์มของเรา เราจะผ่าน{{
              this.basicConfig.CompanyNameEnglish
            }}ใช้ Google Stats
            เพื่อบันทึกประสิทธิภาพของเราและตรวจสอบประสิทธิภาพของการโฆษณาออนไลน์{{
              this.basicConfig.CompanyNameEnglish
            }}เป็นข้อมูลจำนวนเล็กน้อยที่ส่งไปยังเบราว์เซอร์ของคุณและจัดเก็บไว้ในฮาร์ดไดรฟ์ของคอมพิวเตอร์
            เมื่อคุณใช้คอมพิวเตอร์ของคุณเพื่อเข้าถึงแพลตฟอร์มของเราเท่านั้น,{{
              this.basicConfig.CompanyNameEnglish
            }}ก่อนที่จะสามารถส่งไปยังฮาร์ดไดรฟ์ของคอมพิวเตอร์ของคุณ
          </p>
          <p>
            2、{{
              this.basicConfig.CompanyNameEnglish
            }}มักใช้เพื่อบันทึกพฤติกรรมและความชอบของผู้เยี่ยมชมเมื่อเรียกดูรายการต่างๆ
            บนแพลตฟอร์มของเรา{{
              this.basicConfig.CompanyNameEnglish
            }}ข้อมูลที่รวบรวมเป็นสถิติรวมที่ไม่ระบุตัวตนและไม่มีข้อมูลส่วนบุคคล
          </p>
          <p>
            3、{{
              this.basicConfig.CompanyNameEnglish
            }}ไม่สามารถใช้เพื่อรับข้อมูลในฮาร์ดดิสก์ของคุณ ที่อยู่อีเมล
            และข้อมูลส่วนตัวของคุณ
            ซึ่งจะทำให้แพลตฟอร์มหรือระบบผู้ให้บริการนี้รู้จักเบราว์เซอร์ของคุณและบันทึกและจดจำข้อมูลได้
            เบราว์เซอร์ส่วนใหญ่ยอมรับค่าเริ่มต้น{{
              this.basicConfig.CompanyNameEnglish
            }}. คุณสามารถเลือกที่จะกำหนดค่าเบราว์เซอร์ของคุณไม่ให้ยอมรับ{{
              this.basicConfig.CompanyNameEnglish
            }},หรือถ้า{{
              this.basicConfig.CompanyNameEnglish
            }}คุณจะได้รับแจ้งทันทีที่ติดตั้ง อย่างไรก็ตาม
            หากตั้งค่าเป็นปิดใช้งาน{{ this.basicConfig.CompanyNameEnglish }},
            คุณอาจไม่สามารถเปิดใช้งานหรือใช้คุณสมบัติบางอย่างของแพลตฟอร์มของเราได้
          </p>
          <p>
            <strong
              >3. วิธีที่เราแบ่งปัน ถ่ายโอน
              และเปิดเผยข้อมูลของคุณสู่สาธารณะ</strong
            >
          </p>
          <p><strong>(1) การแบ่งปัน</strong></p>
          <p>
            เราจะไม่แบ่งปันข้อมูลส่วนบุคคลของคุณกับบริษัท องค์กร
            และบุคคลอื่นนอกเหนือจากผู้ให้บริการของแพลตฟอร์มนี้
            ยกเว้นในกรณีต่อไปนี้:
          </p>
          <p>
            1. การแบ่งปันภายใต้สถานการณ์ทางกฎหมาย:
            เราอาจแบ่งปันข้อมูลส่วนบุคคลของคุณกับภายนอกตามกฎหมายและระเบียบข้อบังคับ
            การฟ้องร้อง ความจำเป็นในการระงับข้อพิพาท
            หรือตามข้อกำหนดทางกฎหมายของหน่วยงานด้านการบริหารและตุลาการ
          </p>
          <p>
            2. การแบ่งปันด้วยความยินยอมอย่างชัดแจ้ง:
            หลังจากได้รับความยินยอมอย่างชัดแจ้งจากคุณแล้ว
            เราจะแบ่งปันข้อมูลส่วนบุคคลของคุณกับบุคคลอื่น
          </p>
          <p>
            3. การแบ่งปันเมื่อคุณเลือกอย่างแข็งขัน:
            เราจะแบ่งปันข้อมูลที่จำเป็นเกี่ยวกับธุรกรรมในข้อมูลการสั่งซื้อของคุณกับคู่สัญญาของธุรกรรมสินทรัพย์ดิจิทัลตามที่คุณเลือก
            เพื่อรับทราบความต้องการในการทำธุรกรรมและบริการหลังการขายของคุณ
          </p>
          <p>
            4. การแบ่งปันกับพันธมิตรที่ได้รับอนุญาต: บริษัทในเครือของเรา
            บุคคลที่สามที่เชื่อถือได้ซึ่งช่วยให้เราใช้งานแพลตฟอร์มของเรา
            ดำเนินธุรกิจ หรือให้บริการแก่คุณ
            ตราบเท่าที่บุคคลเหล่านี้ตกลงที่จะเก็บข้อมูลนี้ไว้เป็นความลับ
            เมื่อเราเชื่อว่าการเปิดเผยข้อมูลนั้น เหมาะสมที่จะปฏิบัติตามกฎหมาย
            ข้อบังคับ กฎและข้อบังคับหรือคำสั่งจากศาลหรือหน่วยงานที่มีอำนาจอื่น ๆ
            เพื่อดำเนินการตามกลยุทธ์แพลตฟอร์มของเรา
            เพื่อใช้งานแพลตฟอร์มอย่างเหมาะสม เพื่อให้บริการแก่บุคคลที่เกี่ยวข้อง
            หรือเพื่อปกป้องเราหรือสิทธิ์ ทรัพย์สิน หรือความปลอดภัยอื่น ๆ .
          </p>
          <p><strong>(2) การโอนย้าย</strong></p>
          <p>
            เราจะไม่ถ่ายโอนข้อมูลส่วนบุคคลของคุณไปยังบริษัท องค์กร หรือบุคคลใดๆ
            ยกเว้นในกรณีต่อไปนี้:
          </p>
          <p>
            1. ถ่ายโอนด้วยความยินยอมอย่างชัดแจ้ง:
            หลังจากได้รับความยินยอมอย่างชัดแจ้งจากคุณ
            เราจะโอนข้อมูลส่วนบุคคลของคุณไปยังบุคคลอื่น
          </p>
          <p>
            2. ในกรณีของการควบรวมกิจการ
            การเข้าซื้อกิจการหรือการชำระบัญชีด้วยการล้มละลายบนแพลตฟอร์มนี้
            หรือสถานการณ์อื่นๆ ที่เกี่ยวข้องกับการควบรวมกิจการ
            การเข้าซื้อกิจการหรือการชำระบัญชีจากการล้มละลาย
            หากมีการถ่ายโอนข้อมูลส่วนบุคคล
            เราจะกำหนดให้บริษัทหรือองค์กรใหม่ที่เก็บข้อมูลส่วนบุคคลของคุณ
            ให้อยู่ภายใต้บังคับนี้ต่อไป มิฉะนั้น เราจะกำหนดให้บริษัท องค์กร
            และบุคคลต้องขออนุญาตจากคุณอีกครั้ง
          </p>
          <p><strong>(3) การเปิดเผยต่อสาธารณะ</strong></p>
          <p>
            เราจะเปิดเผยข้อมูลส่วนบุคคลของคุณต่อสาธารณะในสถานการณ์ต่อไปนี้เท่านั้น:
          </p>
          <p>
            1. ด้วยความยินยอมอย่างชัดแจ้งของคุณหรือขึ้นอยู่กับตัวเลือกของคุณ
            เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณต่อสาธารณะ
          </p>
          <p>
            2.
            <strong>
              ><u
                >หากเราพบว่าคุณได้ละเมิดกฎหมายและข้อบังคับหรือละเมิดข้อตกลงและกฎที่เกี่ยวข้องของแพลตฟอร์มนี้อย่างร้ายแรง
                หรือเพื่อปกป้องความปลอดภัยส่วนบุคคลและทรัพย์สินของผู้ใช้แพลตฟอร์มนี้หรือสาธารณะจากการละเมิด
                เราอาจปฏิบัติตาม กฎหมายและข้อบังคับหรือด้วยความยินยอมของคุณ
                การเปิดเผยข้อมูลส่วนบุคคลเกี่ยวกับคุณ</u
              ></strong
            >
            รวมถึงการละเมิดที่เกี่ยวข้องและมาตรการที่แพลตฟอร์มนี้ดำเนินการกับคุณ
          </p>
          <p>
            <strong
              >(4) ข้อยกเว้นสำหรับการอนุญาตล่วงหน้าและความยินยอมเมื่อแบ่งปัน
              ถ่ายโอน และเปิดเผยข้อมูลส่วนบุคคลต่อสาธารณะ</strong
            >
          </p>
          <p>
            ในสถานการณ์ต่อไปนี้ การแบ่งปัน การถ่ายโอน
            และการเปิดเผยข้อมูลส่วนบุคคลของคุณสู่สาธารณะไม่จำเป็นต้องได้รับอนุญาตและยินยอมล่วงหน้าจากคุณ:
          </p>
          <p>
            1. เกี่ยวข้องกับความมั่นคงของชาติและความมั่นคงในการป้องกันประเทศ
          </p>
          <p>
            2. เกี่ยวข้องกับความปลอดภัยสาธารณะ สาธารณสุข
            และผลประโยชน์สาธารณะที่สำคัญ
          </p>
          <p>
            3. เกี่ยวข้องกับการบังคับใช้กฎหมายของฝ่ายตุลาการหรือฝ่ายบริหาร เช่น
            การสืบสวนคดีอาญา การฟ้องร้อง การพิจารณาคดีและการบังคับคดี
          </p>
          <p>
            4. เพื่อปกป้องชีวิตส่วนตัวของคุณหรืออื่นๆ ทรัพย์สิน
            และสิทธิและผลประโยชน์โดยชอบด้วยกฎหมายที่สำคัญอื่นๆ
            แต่เป็นการยากที่จะได้รับความยินยอมจากบุคคลนั้น
          </p>
          <p>5. ข้อมูลส่วนบุคคลของคุณเปิดเผยต่อสาธารณะโดยตัวคุณเอง</p>
          <p>
            6. รวบรวมข้อมูลส่วนบุคคลจากข้อมูลที่เปิดเผยตามกฎหมาย เช่น
            รายงานข่าวทางกฎหมาย การเปิดเผยข้อมูลของทางราชการ และช่องทางอื่นๆ
          </p>
          <p>
            โปรดทราบว่าภายใต้กฎหมายที่ใช้บังคับ หากเรา<strong
              ><u
                >ใช้มาตรการทางเทคนิคและมาตรการที่จำเป็นอื่นๆ
                เพื่อประมวลผลข้อมูลส่วนบุคคล
                เพื่อให้ผู้รับข้อมูลไม่สามารถระบุตัวบุคคลที่เฉพาะเจาะจงอีกครั้งและไม่สามารถกู้คืนได้
                ดังนั้นการแบ่งปัน การถ่ายโอน
                และการเปิดเผยต่อสาธารณะของข้อมูลที่ประมวลผลดังกล่าวไม่จำเป็นต้องแจ้งให้ทราบ
                คุณและได้รับความยินยอมจากคุณ ตกลง</u
              ></strong
            >.
          </p>
          <p><strong>4. เราจะปกป้องข้อมูลของคุณอย่างไร</strong></p>
          <p>
            1. เราใช้มาตรการทางกายภาพ อิเล็กทรอนิกส์ การจัดการ
            และทางเทคนิคที่เหมาะสมเพื่อปกป้องและรับประกันความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
            เราพยายามอย่างดีที่สุดเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลใด ๆ
            ที่รวบรวมผ่านแพลตฟอร์มของเราจะปราศจากการแทรกแซงจากบุคคลที่สามที่ไม่เกี่ยวข้องกับเรา
            มาตรการรักษาความปลอดภัยที่เราใช้รวมถึงแต่ไม่จำกัดเพียง:
          </p>
          <p>
            (1) มาตรการทางกายภาพ:
            บันทึกที่มีข้อมูลส่วนบุคคลของคุณจะถูกจัดเก็บไว้ในที่ล็อก
          </p>
          <p>
            (2) มาตรการทางอิเล็กทรอนิกส์:
            ข้อมูลคอมพิวเตอร์ที่มีข้อมูลส่วนบุคคลของคุณจะถูกจัดเก็บไว้ในระบบคอมพิวเตอร์และสื่อบันทึกข้อมูลภายใต้ข้อจำกัดการเข้าสู่ระบบที่เข้มงวด
          </p>
          <p>
            (3) มาตรการการจัดการ:
            เราได้จัดตั้งหน่วยงานที่เกี่ยวข้องเป็นการภายในเพื่อปกป้องความปลอดภัยของข้อมูลผู้ใช้และจัดตั้งระบบควบคุมภายในที่เกี่ยวข้องและนำหลักการอนุญาตที่เข้มงวดมาใช้สำหรับพนักงานที่อาจเข้าถึงข้อมูลของคุณได้เฉพาะเจ้าหน้าที่ที่ได้รับอนุญาตเท่านั้นที่สามารถเข้าถึงข้อมูลของคุณได้
            ข้อมูลส่วนบุคคล
            พนักงานเหล่านี้จำเป็นต้องปฏิบัติตามรหัสภายในของเราว่าด้วยการรักษาความลับของข้อมูลส่วนบุคคล
            นอกจากนี้
            เรายังคงเสริมสร้างการประชาสัมพันธ์กฎหมายและข้อบังคับที่เกี่ยวข้อง
            มาตรฐานความเป็นส่วนตัวและความปลอดภัย
            และความตระหนักด้านความปลอดภัยสำหรับการฝึกอบรมพนักงาน
            และจัดบุคลากรภายในที่เกี่ยวข้องอย่างสม่ำเสมอเพื่อดำเนินการฝึกอบรมการตอบสนองเหตุฉุกเฉินและการฝึกซ้อมเหตุฉุกเฉิน
            เพื่อให้พวกเขาสามารถควบคุมความรับผิดชอบในงานและเหตุฉุกเฉินได้
            กลยุทธ์และขั้นตอนการตอบสนอง
          </p>
          <p>
            (4) มาตรการทางเทคนิค: อาจใช้ Secure Socket Layer
            การเข้ารหัสเป็นเทคโนโลยีการเข้ารหัสเพื่อส่งข้อมูลส่วนบุคคลของคุณ
          </p>
          <p>
            (5) มาตรการรักษาความปลอดภัย: เพื่อปกป้องความปลอดภัยของข้อมูลของคุณ
            เรามุ่งมั่นที่จะใช้เทคโนโลยีการรักษาความปลอดภัยทั่วไปในปัจจุบันและระบบการจัดการที่สนับสนุนเพื่อลดข้อมูลของคุณจากการรั่วไหล
            ความเสียหาย การใช้งานในทางที่ผิด การเข้าถึงโดยไม่ได้รับอนุญาต
            การเปิดเผยโดยไม่ได้รับอนุญาต และความเสี่ยงในการเปลี่ยนแปลง .
            ตัวอย่างเช่น
            การส่งผ่านการเข้ารหัสผ่านซอฟต์แวร์เลเยอร์ความปลอดภัยเครือข่าย (SSL)
            การจัดเก็บข้อมูลที่เข้ารหัส
            และการจำกัดการเข้าถึงศูนย์ข้อมูลอย่างเข้มงวด
            เมื่อส่งและจัดเก็บข้อมูลส่วนตัวที่ละเอียดอ่อน
            (รวมถึงข้อมูลชีวมาตรส่วนบุคคล) เราจะนำมาตรการรักษาความปลอดภัยมาใช้
            เช่น การเข้ารหัส การควบคุมสิทธิ์ การไม่ระบุตัวตน และการลดความไว
          </p>
          <p>
            (6) มาตรการอื่นๆ: ตรวจสอบการรวบรวม การจัดเก็บ
            และขั้นตอนการประมวลผลข้อมูลส่วนบุคคลของเราเป็นประจำ &nbsp;
            และจำกัดการเข้าถึงข้อมูลของคุณของพนักงานและซัพพลายเออร์ของเราตามหลักการที่จำเป็น
            และพนักงานและซัพพลายเออร์ของเราต้องปฏิบัติตามภาระหน้าที่ในการรักษาความลับตามสัญญาที่เข้มงวด
            .
          </p>
          <p>
            2. หากคุณทราบช่องโหว่ด้านความปลอดภัยบนแพลตฟอร์มของเรา
            โปรดติดต่อเราทันที เพื่อให้เราดำเนินการตามความเหมาะสมโดยเร็วที่สุด
          </p>
          <p>
            3. แม้จะมีการดำเนินการตามมาตรการทางเทคนิคและความปลอดภัยข้างต้น
            เราไม่สามารถรับประกันความปลอดภัยที่สมบูรณ์ของการส่งข้อมูลบนอินเทอร์เน็ต
            ดังนั้นเราจึงไม่สามารถรับประกันได้อย่างแน่นอนว่าข้อมูลส่วนบุคคลที่คุณให้กับเราผ่านแพลตฟอร์มของเรานั้นปลอดภัย
            ครั้ง . เราจะอัปเดตและเผยแพร่ความเสี่ยงด้านความปลอดภัย
            รายงานการประเมินผลกระทบด้านความปลอดภัยข้อมูลส่วนบุคคล
            และเนื้อหาที่เกี่ยวข้องอื่นๆ เป็นครั้งคราว
            ซึ่งคุณสามารถรับได้จากการประกาศบนแพลตฟอร์มนี้
          </p>
          <p><strong>5. คุณจัดการข้อมูลของคุณอย่างไร</strong></p>
          <p>คุณสามารถเข้าถึงและจัดการข้อมูลของคุณด้วยวิธีต่อไปนี้:</p>
          <p><strong>(1) สอบถาม แก้ไข และลบข้อมูลของคุณ</strong></p>
          <p>
            คุณมีสิทธิ์ที่จะได้รับสำเนาข้อมูลส่วนบุคคลของคุณเมื่อมีการร้องขอ
            และเพื่อตรวจสอบว่าข้อมูลที่เรามีเกี่ยวกับคุณนั้นถูกต้องและเป็นปัจจุบันหรือไม่
            หากข้อมูลส่วนบุคคลของคุณไม่ถูกต้อง คุณอาจร้องขอให้อัปเดตข้อมูลของคุณ
            คุณยังสามารถร้องขอให้ลบข้อมูลส่วนบุคคลของคุณได้
            แม้ว่าเราอาจปฏิเสธคำขอให้ลบของคุณในบางสถานการณ์ เช่น
            ตามที่กฎหมายกำหนดหรือเพื่อวัตถุประสงค์ทางกฎหมายอื่นๆ
            สำหรับคำขอเข้าถึง แก้ไข หรือลบข้อมูล
            คุณสามารถติดต่อเราผ่านข้อมูลติดต่อในข้อ 8 &nbsp;
          </p>
          <p>
            ในการตอบสนองต่อคำขอการเข้าถึงข้อมูล การแก้ไข หรือการลบ
            เราจะตรวจสอบตัวตนของบุคคลที่ร้องขอเพื่อให้แน่ใจว่าเขา/เธอได้รับอนุญาตตามกฎหมายในการร้องขอดังกล่าว
            แม้ว่าเป้าหมายของเราคือการตอบสนองคำขอเหล่านี้โดยไม่คิดค่าใช้จ่าย
            แต่เราขอสงวนสิทธิ์ในการเรียกเก็บค่าธรรมเนียมที่เหมาะสมหากคำขอของคุณซ้ำซากหรือยุ่งยาก
            &nbsp;
          </p>
          <p>
            <strong>(2) เปลี่ยนขอบเขตการให้สิทธิ์และความยินยอมของคุณ</strong>
          </p>
          <p>
            หน้าที่ทางธุรกิจแต่ละอย่างจำเป็นต้องกรอกข้อมูลส่วนบุคคลพื้นฐานบางส่วน
            (ดู "ส่วนที่ 1" ของนโยบายความเป็นส่วนตัวนี้) นอกจากนี้
            คุณสามารถติดต่อเราเพื่อให้หรือถอนการอนุญาตและความยินยอมของคุณผ่านข้อมูลการติดต่อในข้อ
            8
          </p>
          <p>
            เมื่อคุณเพิกถอนความยินยอม
            เราจะไม่ประมวลผลข้อมูลส่วนบุคคลที่เกี่ยวข้องอีกต่อไป
            <strong
              ><u>
                อย่างไรก็ตาม
                การตัดสินใจเพิกถอนความยินยอมของคุณจะไม่ส่งผลต่อการประมวลผลข้อมูลส่วนบุคคลตามการอนุญาตของคุณก่อนหน้านี้
              </u></strong
            >
          </p>
          <p><strong>(3) ยกเลิกบัญชีของคุณ</strong></p>
          <p>
            คุณสามารถติดต่อเราผ่านข้อมูลการติดต่อในข้อ 8
            เพื่อสมัครขอยกเลิกบัญชีของคุณ
            หลังจากที่คุณยกเลิกบัญชีของคุณโดยสมัครใจ
            เราจะหยุดให้บริการผลิตภัณฑ์หรือบริการแก่คุณ
            ลบข้อมูลส่วนบุคคลของคุณตามข้อกำหนดของกฎหมายที่บังคับใช้ หรือทำให้
            มันไม่เปิดเผยชื่อ
          </p>
          <p><strong>(4) ตอบสนองต่อคำขอข้างต้นของคุณ</strong></p>
          <p>
            เพื่อความปลอดภัย
            คุณอาจต้องส่งคำขอเป็นลายลักษณ์อักษรหรือพิสูจน์ตัวตนของคุณ
            เราอาจขอให้คุณยืนยันตัวตนของคุณก่อนที่จะดำเนินการตามคำขอของคุณ
          </p>
          <p>
            เราจะตอบกลับภายใน 15 วัน หากคุณไม่พอใจ
            คุณสามารถเริ่มการร้องเรียนผ่านข้อมูลการติดต่อที่เราให้ไว้
          </p>
          <p>
            สำหรับคำขอที่สมเหตุสมผลของคุณ เราไม่คิดค่าธรรมเนียมในหลักการ
            แต่สำหรับการร้องขอซ้ำที่เกินขีดจำกัดที่สมเหตุสมผล
            เราจะเรียกเก็บค่าธรรมเนียมตามความเหมาะสม
            สำหรับข้อมูลที่ไม่ได้เชื่อมโยงโดยตรงกับตัวตนของคุณ
            ข้อมูลซ้ำโดยไม่มีเหตุผล หรือข้อมูลที่ต้องใช้เทคนิคมากเกินไป (เช่น
            ต้องพัฒนาระบบใหม่หรือการเปลี่ยนแปลงพื้นฐานในแนวทางปฏิบัติที่มีอยู่)
            ก่อให้เกิดความเสี่ยงต่อความถูกต้องตามกฎหมาย
            สิทธิและผลประโยชน์ของผู้อื่น หรือไม่สามารถทำได้ เราอาจปฏิเสธ
          </p>
          <p>
            ในสถานการณ์ต่อไปนี้
            เราจะไม่สามารถตอบสนองต่อคำขอของคุณตามข้อกำหนดของกฎหมายและข้อบังคับ:
          </p>
          <p>
            1. เกี่ยวข้องกับความมั่นคงของชาติและความมั่นคงในการป้องกันประเทศ
          </p>
          <p>
            2. เกี่ยวข้องกับความปลอดภัยสาธารณะ สาธารณสุข
            และผลประโยชน์สาธารณะที่สำคัญ
          </p>
          <p>
            3. ที่เกี่ยวข้องกับการสืบสวนคดีอาญา การฟ้องร้อง
            การพิจารณาคดีและการดำเนินการตามคำพิพากษา
          </p>
          <p>
            4.
            มีหลักฐานเพียงพอว่าเจ้าของข้อมูลส่วนบุคคลมีความอาฆาตมาดร้ายหรือละเมิดสิทธิ์
          </p>
          <p>
            5.
            การตอบสนองต่อคำขอของคุณจะสร้างความเสียหายอย่างร้ายแรงต่อสิทธิและผลประโยชน์โดยชอบด้วยกฎหมายของคุณหรือบุคคลหรือองค์กรอื่น
            ๆ
          </p>
          <p>6. ผู้ที่เกี่ยวข้องกับความลับทางการค้า</p>
          <p><strong>6. เราจะจัดการกับข้อมูลของผู้เยาว์อย่างไร</strong></p>
          <p>
            แพลตฟอร์มนี้เปิดสำหรับเด็ก ๆ หากเราพบหรือสงสัยว่าผู้ใช้มีอายุต่ำกว่า
            14 ปี
            เราจะขอให้ผู้ใช้ปิดบัญชีของเขาและจะไม่อนุญาตให้ผู้ใช้ใช้ผลิตภัณฑ์หรือบริการของแพลตฟอร์มต่อไป
            เราจะลบข้อมูลที่เกี่ยวข้องโดยเร็วที่สุด
            หากคุณรู้จักใครก็ตามที่มีอายุต่ำกว่า 14 ปีกำลังใช้บริการของเรา
            โปรดแจ้งให้เราทราบ
            เพื่อที่เราจะได้ดำเนินการป้องกันไม่ให้พวกเขาเข้าถึงผลิตภัณฑ์หรือบริการของเรา
          </p>
          <p>
            สำหรับผู้เยาว์ที่มีอายุครบ 14 ปีแต่ยังไม่ถึงอายุ 18 ปี
            เราคาดหวังให้พ่อแม่หรือผู้ปกครองแนะนำผู้เยาว์ในการใช้บริการของเรา
            เราจะปกป้องความลับและความปลอดภัยของข้อมูลของผู้เยาว์ตามกฎหมายและข้อบังคับที่เกี่ยวข้อง
          </p>
          <p>
            หากคุณเป็นผู้เยาว์ ขอแนะนำให้พ่อแม่หรือผู้ปกครองของคุณอ่านนโยบายนี้
            และใช้บริการของเราหรือให้ข้อมูลของคุณแก่เราโดยได้รับความยินยอมจากพ่อแม่หรือผู้ปกครองของคุณ
            ในกรณีของการรวบรวมข้อมูลของคุณโดยได้รับความยินยอมจากพ่อแม่หรือผู้ปกครองของคุณ
            เราจะใช้หรือเปิดเผยข้อมูลนี้ต่อสาธารณะเฉพาะเมื่อกฎหมายอนุญาต
            โดยได้รับความยินยอมอย่างชัดแจ้งจากพ่อแม่หรือผู้ปกครองของคุณ
            หรือตามความจำเป็นเพื่อปกป้องสิทธิและผลประโยชน์ของคุณ
            หากผู้ปกครองของคุณไม่เห็นด้วยกับคุณที่จะใช้บริการของเราหรือให้ข้อมูลแก่เราตามนโยบายนี้
            โปรดหยุดใช้บริการของเราทันทีและแจ้งให้เราทราบทันเวลาเพื่อที่เราจะได้ดำเนินการตามมาตรการที่เกี่ยวข้อง
          </p>
          <p>
            หากคุณเป็นพ่อแม่หรือผู้ปกครองของผู้เยาว์
            โปรดติดต่อเราผ่านข้อมูลติดต่อที่เผยแพร่ในนโยบายนี้
            เมื่อคุณมีคำถามใดๆ
            เกี่ยวกับการประมวลผลข้อมูลของผู้เยาว์ภายใต้การปกครองของคุณ
          </p>
          <p><strong>7. วิธีอัปเดตนโยบายความเป็นส่วนตัวนี้</strong></p>
          <p>นโยบายความเป็นส่วนตัวของเราอาจมีการเปลี่ยนแปลง</p>
          <p>
            เราจะไม่จำกัดสิทธิ์ของคุณภายใต้นโยบายความเป็นส่วนตัวนี้โดยไม่ได้รับความยินยอมอย่างชัดแจ้งจากคุณ
            เราจะโพสต์การเปลี่ยนแปลงนโยบายความเป็นส่วนตัวในช่องอย่างเป็นทางการของเรา
            หากคุณไม่เห็นด้วยกับเนื้อหาที่แก้ไข
            คุณควรหยุดการเข้าถึงแพลตฟอร์มนี้ทันที
            เมื่อมีการเผยแพร่นโยบายความเป็นส่วนตัวเวอร์ชันอัปเดต
            การเข้าถึงแพลตฟอร์มนี้อย่างต่อเนื่องของคุณจะแสดงและระบุว่าคุณยอมรับเนื้อหาที่อัปเดตและตกลงที่จะปฏิบัติตามนโยบายความเป็นส่วนตัวที่อัปเดต
          </p>
          <p>
            สำหรับการเปลี่ยนแปลงที่สำคัญ เราจะแจ้งให้คุณทราบอย่างชัดเจนมากขึ้น
            (รวมถึงเราจะแจ้งให้คุณทราบผ่านการประชาสัมพันธ์ของแพลตฟอร์มนี้
            และแม้กระทั่งแจ้งป๊อปอัปให้คุณทราบ)
          </p>
          <p>
            การเปลี่ยนแปลงเนื้อหาที่อ้างถึงในนโยบายนี้รวมถึงแต่ไม่จำกัดเพียง:
          </p>
          <p>
            1. การเปลี่ยนแปลงครั้งใหญ่เกิดขึ้นกับรูปแบบการบริการของเรา เช่น
            วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล
            ประเภทของข้อมูลส่วนบุคคลที่ประมวลผล วิธีการใช้ข้อมูลส่วนบุคคล
            เป็นต้น
          </p>
          <p>
            2. เรามีการเปลี่ยนแปลงครั้งใหญ่ในด้านต่างๆ เช่น สิทธิ์ในการควบคุม
            เช่น การเปลี่ยนแปลงผู้ควบคุมข้อมูลที่เกิดจากการควบรวมกิจการ เป็นต้น;
          </p>
          <p>
            3. วัตถุประสงค์หลักของการแบ่งปัน ถ่ายโอน
            หรือเปิดเผยข้อมูลส่วนบุคคลต่อสาธารณะมีการเปลี่ยนแปลง
          </p>
          <p>
            4.
            มีการเปลี่ยนแปลงครั้งใหญ่ในสิทธิ์ของคุณในการเข้าร่วมในการประมวลผลข้อมูลส่วนบุคคลและวิธีการใช้ข้อมูลนั้น
          </p>
          <p>
            5. การเปลี่ยนแปลงแผนกที่รับผิดชอบ วิธีการติดต่อ
            และช่องทางการร้องเรียนที่รับผิดชอบในการจัดการความปลอดภัยของข้อมูลส่วนบุคคล
          </p>
          <p>
            6.
            รายงานการประเมินผลกระทบด้านความปลอดภัยของข้อมูลส่วนบุคคลระบุว่ามีความเสี่ยงสูง
            &nbsp;
          </p>
          <p><strong>VIII วิธีติดต่อเรา</strong></p>
          <p>
            1. หากคุณมีข้อกำหนดและความคิดเห็น คุณสามารถส่งอีเมล
            ซึ่งเป็นอีเมลที่ถูกต้องและเป็นทางการเพียงฉบับเดียวที่เราใช้เพื่อสื่อสารกับคุณ
            ดังนั้นเราจะไม่รับผิดชอบต่อการกระทำหรือการละเว้นใดๆ
            ที่คุณไม่ได้ใช้ข้อมูลติดต่อที่ถูกต้อง
          </p>
          <p>
            2.
            เราเผยแพร่ประกาศและข้อมูลหรือโพสต์ประกาศบนแพลตฟอร์มนี้ผ่านข้อมูลการติดต่อที่มีประสิทธิภาพบนแพลตฟอร์มนี้เท่านั้น
            ดังนั้นเราจะไม่รับผิดชอบต่อความสูญเสียใดๆ
            ที่เกิดจากความไว้วางใจของคุณในข้อมูลที่ไม่ได้รับจากวิธีการข้างต้น
          </p>
          <p>
            3. หากคุณมีคำถามใดๆ เกี่ยวกับนโยบายส่วนบุคคลของเรา
            โปรดอย่าลังเลที่จะติดต่อเรา
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    <!-- 日语 -->
    <div class="main" v-show="lang == 'jp'">
      <div class="box">
        <div class="top">
          <div class="text1">プライバシー ポリシー</div>
          <div class="text2">最終改訂日: 2022-08-20</div>
        </div>
        <div class="content">
          <p><strong>【紹介と注意事項】</strong></p>
          <p>
            このプラットフォームは、ユーザーがデジタル資産の取引を行い、関連するサービス
            (以下、「サービス」または「サービス」といいます)
            を提供するためのプラットフォームです。
            表現の便宜上、このプラットフォームは、本契約において「私たち」またはその他の一人称称称で総称されます.
            プラットフォームにログインする自然人またはその他の対象がこのプラットフォームのユーザーである限り、「あなた」またはその他の二人称代名詞が以下で使用されています。当社およびお客様を総称して「当事者」と呼び、当社またはお客様個人を「当事者」と呼びます。
          </p>
          <p>
            当社は、お客様の個人情報の重要性を十分に理解し、尊重し、お客様の個人情報のセキュリティを保護するために、法律および規制の要件に従って、対応するセキュリティ保護措置を講じます。
            これを考慮して、この「プライバシーポリシー」（以下、「このポリシー/このプライバシーポリシー」と呼びます）を策定し、次のことを思い出させてください。
          </p>
          <p>
            このポリシーは、当社が提供するすべての製品およびサービスに適用されます。<strong
              >お気に入り<u
                >当社がお客様に提供する製品またはサービスに別のプライバシー
                ポリシーがある場合、その製品またはサービスには別のプライバシー
                ポリシーが適用されます。当社の製品またはサービスに別のプライバシー
                ポリシーがない場合、このポリシーが適用されます。</u
              ></strong
            >
          </p>
          <p>
            注意すべきこと、<strong
              ><u
                >このポリシーは、他の第三者がお客様に提供するサービスには適用されません。また、第三者がお客様に提供するサービスは、別途お客様に説明されるプライバシー
                ポリシーによって管理されます。</u
              ></strong
            >
          </p>
          <p>
            <strong
              ><u
                >当社の製品またはサービスを使用する前に、必ずこのポリシーをよく読み、完全に理解してください。特に、太字/太字の下線でマークされた用語をよく読んでから、完全に理解し、使用を開始することに同意する必要があります。
                このポリシーの内容について質問、コメント、または提案がある場合は、当社が提供するさまざまな公式の連絡方法でご連絡ください。
                このポリシーの条件に同意しない場合は、このプラットフォームへのアクセスを直ちに停止する必要があります。
              </u></strong
            >
          </p>
          <p>&nbsp;</p>
          <p><strong>パート 1&nbsp;定義</strong></p>
          <p>
            <strong>個人情報:</strong>
            特定の自然人を識別できる、または特定の自然人の活動を単独または他の情報と組み合わせて反映できる、電子的またはその他の方法で記録されたさまざまな情報を指します。
          </p>
          <p>
            <strong>個人の機密情報: </strong> ID
            番号、個人の生体情報、銀行口座番号、財産情報、居場所、取引情報、14
            歳未満の子供に関する情報 (含む)
            を含む個人情報を指します。情報は、このプライバシー
            ポリシーで<strong>太字</strong>で目立つようにマークされています)。
          </p>
          <p>
            <strong>個人情報の削除:</strong>
            とは、日常のビジネス機能の実現に関与するシステムから個人情報を削除して、取得またはアクセスできない状態のままにする行為を指します。
          </p>
          <p><strong>子供</strong>: 14 歳未満の未成年者を指します。</p>
          <p><strong>パート 2&nbsp;&nbsp;プライバシー ポリシー</strong></p>
          <p>
            このプライバシー ポリシー
            セクションは、次の内容を理解するのに役立ちます。
          </p>
          <p>1. お客様の情報を収集および使用する方法</p>
          <p>
            2.
            {{ this.basicConfig.CompanyNameEnglish }}
            および類似のテクノロジーの使用方法
          </p>
          <p>3. お客様の情報を共有、転送、公開する方法</p>
          <p>4. お客様の情報をどのように保護しますか</p>
          <p>5. 情報をどのように管理していますか</p>
          <p>6. 未成年者の情報の取り扱い方法</p>
          <p>7. このプライバシー ポリシーの更新方法</p>
          <p>VIII. お問い合わせ方法</p>
          <p>&nbsp;</p>
          <p><strong>1. お客様の情報を収集および使用する方法</strong></p>
          <p>
            お客様が当社の製品および/またはサービスを使用する際に、当社が収集および使用する必要がある、または必要になる可能性があるお客様の個人情報には、次の
            2 つのタイプが含まれます。
          </p>
          <p>
            1.
            <strong
              >当社の製品および/またはサービスの基本機能を提供するために、必要な情報を収集および使用することを当社に許可する必要があります。対応する情報の提供を拒否すると、当社の製品および/またはサービスを正常に使用できなくなります。</strong
            >
          </p>
          <p>
            2.
            <strong
              >当社の製品および/またはサービスの追加機能を提供するために、情報の収集および使用を当社に許可することを選択できます。提供を拒否した場合、関連する追加機能を使用したり、当社が達成しようとする機能効果を達成したりすることはできませんが、当社の製品および/またはサービスの基本機能の通常の使用には影響しません。
            </strong>
          </p>
          <p>
            <strong><u>次のことを理解し、同意します:</u></strong>
          </p>
          <p>
            <strong>1</strong
            ><strong
              >、<u>私たちは、お客様のニーズを満たすさまざまな製品とサービスの作成に取り組んでいます。当社は多種多様な製品とサービスを提供しており、ユーザーによって選択される特定の製品/サービスの範囲が異なるため、それに応じて、基本/追加機能、収集および使用される個人情報の種類と範囲も異なります。特定の製品/サービス機能が優先するを参照してください</u>;</strong
            >
          </p>
          <p>
            <strong>2</strong
            ><strong
              >、<u
                >より良い製品とサービス体験をお届けするために、当社はテクノロジーの改善に継続的に取り組んでおり、新しい製品または最適化された製品を随時発売する場合があります。新たに個人情報を収集・利用する必要が生じた場合、または個人情報の利用目的や利用方法を変更する必要がある場合。この点に関して、当社は、このポリシー、ポップアップ
                ウィンドウ、ページ
                プロンプトなどを更新することにより、対応する情報の目的、範囲、および使用について個別に説明し、お客様自身の同意を選択する方法を提供します。明示的な同意を得る
                収集、使用</u
              >。
            </strong>
            このプロセス中に、質問、コメント、または提案がある場合は、このプラットフォームが提供するさまざまな連絡方法でご連絡ください。できるだけ早く回答いたします。
          </p>
          <p>&nbsp;</p>
          <p>
            当社は、このポリシーの次の機能を達成するために、お客様の個人情報を収集および使用します。
          </p>
          <p>
            <strong
              >(1)&nbsp;このプラットフォームが提供するサービスの登録と提供を支援する;</strong
            >
          </p>
          <p><strong>1</strong><strong>、登録サービス</strong></p>
          <p>
            プラットフォーム アカウントを通じて、基本的なメンバーシップ
            サービスを提供します。会員として登録し、会員サービスを利用するには、登録申請時に次の情報を入力して提供する必要があります。
          </p>
          <p>
            (1)
            身元情報。この情報は、あなたがこのプラットフォームのメンバーとして登録する資格があるかどうかを確認するのに役立ちます。これには、あなたの名前、住所、あなたの国または政府によって発行された、あなたの身元を証明する情報を含むその他のさまざまな証明書、および関連する番号が含まれますが、これらに限定されませんおよびお客様の身元を確認するのに役立つその他の情報
            (まとめて「身元情報」)。身元情報は、個人ユーザーか機関ユーザーかによって異なります.
            さまざまな法域の法律および規制に従って、さまざまな国または地域のユーザーについて収集する情報が異なる場合があります.次の情報とデータは、参考例として役立ちます。
          </p>
          <p>
            <strong>個人ユーザー</strong>: 国籍、正式な氏名
            (以前の名前と現地の言語名を含む)、社会保障番号
            (「SSN」)、パスポート番号、または政府発行の ID
            番号、生年月日、身分証明書(パスポート、運転免許証、政府発行の身分証明書など)。
          </p>
          <p>
            <strong>機関ユーザー</strong>: 会社の正式名称
            (現地語での正式名称を含む)、登録情報、会社の電話番号、承認された代表者および承認されたユーザーの個人情報、主な事業所、すべての受益所有者の個人情報)、株主/受益者の身分証明書
            (パスポートまたは政府発行の ID
            カード)、会社構造、会社設立関連の文書、取締役会の決議、資本源の宣言、リスクおよびコンプライアンス管理の指示、およびプラットフォームが必要とするその他の情報または文書。
          </p>
          <p>
            (2)
            サービス情報。この情報は、当社がお客様に連絡し、さまざまなモードのデジタル資産取引サービスをスムーズに提供するのに役立ちます。これには、個人を特定できる情報、連絡先情報、取引情報、およびデビットカード情報および/またはその他のアカウント情報が含まれますが、これらに限定されません
            (以下、総称して「サービス情報」といいます&nbsp;)。
            <strong>同時に</strong
            >、当社が提供するサービスは常に更新および開発されていることをご理解ください。上記の説明に含まれていない他のサービスを使用することを選択した場合、<strong>追加</strong>情報を収集するために必要なサービスに基づいて、ページ
            プロンプト、インタラクティブ プロセス、同意書、ポップアップ
            ウィンドウ プロンプトを使用します。等
            情報収集の範囲と目的をご説明し、同意を得ます。
          </p>
          <p>
            ブラウジング
            サービスのみを使用する必要がある場合は、メンバーとして登録して上記の情報を提供する必要はありません。
          </p>
          <p><strong>2</strong><strong>、その他の登録サービス</strong></p>
          <p>
            実名認証:
            登録してログインした後、「個人センター」に入って身元認証の実名認証を完了する必要があります.
            実名,
            ID番号またはパスポート番号をプラットフォームに提供する必要があります.実名認証プロセス。
          </p>
          <p>
            高度な認証:
            このプラットフォームでの取引量が一定の制限を超えると、プラットフォームは資産のセキュリティを維持するために高度な認証を受ける必要があります。
            認証プロセス中の実名認証情報に加えて、プラットフォームが「顔認識」の高度な認証を実行し、顔認識にはプラットフォームが関連する顔画像を収集することに同意するものとします。
          </p>
          <p>
            <strong
              ><u
                >アカウント情報の表示:
                すでにこのプラットフォームにアカウントを持っている場合、上記の個人情報をサービスに表示することがあります
                (実名認証は、認証が成功したかどうかの結果のみを表示します)。プラットフォームに関連付けられたお客様のアカウント製品やサービスに対して実行される操作。これには、お客様のプラットフォーム
                アカウントを介した個人データや取引注文の集中表示が含まれます。
                プラットフォーム アカウント設定の選択を尊重します。
              </u></strong
            >
          </p>
          <p>
            <strong>(2)</strong>&nbsp;<strong
              >セキュリティ管理サービスおよびサービス情報プッシュ
              サービス</strong
            >
          </p>
          <p>
            このプラットフォームが提供するサービスを使用するとき、またはこのプラットフォームにアクセスするとき、トランザクションのセキュリティとサービスの安全で安定した運用を確保するために、このプラットフォームは、Web
            ブラウザー情報、デバイス情報を自動的に受信して記録する必要があります。アカウントへのログインに使用します
            (IDFA、オペレーティング システム、デバイス モデル、デバイス
            メーカー、システム バージョン、Android ID、IMEI &nbsp; および
            &nbsp;MAC&nbsp; アドレスなど)、アクセスを要求した Web ページの IP
            アドレスと記録、場所、ネットワーク環境、デバイスの指紋情報、サービス
            ログ情報、ソフトウェア情報、場所情報など。
          </p>
          <p>
            <strong
              ><u
                >個別デバイス情報は、特定の自然人を識別できない情報であることに注意してください。</u
              ></strong
            >このような非個人情報を他の情報と結合して特定の自然人の識別に使用したり、個人情報と結合して使用したりする場合は、結合して使用している間、このような非個人情報は個人情報とみなされ、あなたの許可や法律法規に別途規定されている以外は、匿名化、非識別化処理されます。
          </p>
          <p>
            <strong
              ><u
                >より便利で個々のニーズに沿った情報表示およびプッシュ
                サービスを提供するために、当社は、お客様のデバイス情報およびこのプラットフォームでのブラウジングおよび使用情報に基づいて、お客様の好みを抽出する場合があります。将来の機能のクライアントを表示し、表示用の機能タグに基づいて間接的な群衆のポートレートを生成し、情報をプッシュし、可能な商用広告を生成します。そのようなプッシュ情報の受信に同意しない場合、または個人情報を収集するための同意を撤回する場合は、このポリシーに記載されている連絡先情報に従って当社に連絡することができます。
              </u></strong
            >
          </p>
          <p>
            私たちはあなたのブラウジング体験を保護するために懸命に取り組んでいます.当社が送信する商用広告を受け入れたくない場合は、SMS
            プロンプトまたは当社が提供するその他の方法に返信することで、購読を解除するか、閉じることができます。
          </p>
          <p>
            さらに、上記の機能を継続的に改善および最適化するために、上記の情報も使用します。
          </p>
          <p><strong>(3)取引記録保管・照会サービス</strong></p>
          <p>
            取引記録を含むがこれに限定されない、このプラットフォームでの取引に関して当社が収集する関連データ。注文取引記録について問い合わせる必要がある場合は、プラットフォームの「注文管理」で、取引相手のニックネーム、注文時間、注文番号、取引情報、デジタル資産の種類と数量、等
          </p>
          <p><strong>(4) カスタマー サービスと紛争解決</strong></p>
          <p>
            <strong
              ><u
                >アカウントとシステムのセキュリティを保護するために、メンバーシップを確認するために必要な個人情報を提供する必要があります。
              </u></strong
            >
          </p>
          <p>
            <strong
              ><u
                >お客様との連絡を容易にするため、問題をできるだけ早く解決するため、または関連する問題の解決策と結果を記録するために、お客様の通信記録と関連コンテンツ
                (アカウント情報、注文を含む)
                を保存する場合があります。関連する事実を証明するために提供されたその他の情報、またはあなたが残した連絡先情報）、特定の注文について問い合わせ、苦情、または提案を提供する場合、私たちはあなたのアカウント情報と注文情報を使用します。
              </u></strong
            >
          </p>
          <p>
            サービスを提供し、サービス品質の合理的なニーズを改善するために、当社は、お客様がカスタマー
            サービスに連絡する際に提供する関連情報や、アンケート調査に参加する際に当社に送信するアンケート回答情報など、お客様に関するその他の情報を使用する場合があります。
          </p>
          <p><strong>(5) その他</strong></p>
          <p>
            <strong><u>1</u></strong
            ><strong
              ><u
                >、提供する情報に他のユーザーの個人情報が含まれている場合は、そのような個人情報をこのプラットフォームに提供する前に、次のことを確認する必要があります。法的許可を取得します。お子様の個人情報が含まれる場合は、投稿前に対応するお子様の保護者の同意を得る必要があります.前述の状況では、保護者は、このポリシーの第9条のチャネルを通じて、修正または削除を要求するために当社に連絡する権利を有します.子供の個人情報を含むコンテンツ。
              </u></strong
            >
          </p>
          <p>
            <strong><u>2</u></strong
            ><strong
              ><u
                >、このポリシーで指定されていない他の目的で情報を使用する場合、または特定の目的のために収集された情報を他の目的で使用する場合、またはお客様の個人情報を第三者から積極的に取得する場合は、事前にお客様の同意を得るものとします。
              </u></strong
            >
          </p>
          <p>
            当社がお客様の情報を第三者から間接的に取得する場合は、収集前に法律に従ってお客様の同意を得た上で、その第三者に個人情報の収集を明示的に要求し、機密情報を含む共有情報の内容をお客様に通知します。利用のために当社に提供される場合、お客様が明確に確認する必要がある場合、および第三者は、個人情報の情報源の合法性およびコンプライアンスに責任を負う必要があります。対応する法的責任を相手方に要求すると同時に、個人情報のセキュリティを強化します
            (機密情報の報告、機密情報の暗号化された保管、アクセス制御などを含む)。当社は、間接的に取得した個人情報を保護するために、当社のユーザーの個人情報に対して使用するものと同等の保護手段および手段を使用します。
          </p>
          <p><strong>3</strong><strong>、承認済み同意の例外</strong></p>
          <p>
            お客様は、以下の場合において、お客様の個人情報の収集および利用について、お客様の同意および同意を必要としないこと、および、お客様からの訂正・修正、削除、取り消し、同意の撤回、資料請求等の求めに応じられない場合があることを十分に理解し、同意するものとします。
            :
          </p>
          <p>(1) 国家安全保障および国防安全保障に関連する;</p>
          <p>(2) 公共の安全、公衆衛生、および主要な公益に関連する;</p>
          <p>
            (3)
            犯罪捜査、起訴、裁判および判決執行などの司法または行政法執行に関連するもの;
          </p>
          <p>
            (4)
            本人またはその他の私生活、財産その他の重大な法的権利および利益を保護するためであり、本人の同意を得ることが困難である場合
          </p>
          <p>(5) お客様が公開する個人情報</p>
          <p>
            (6)
            個人情報は、法的ニュース報道、政府の情報開示、およびその他のチャネルなど、法的に開示された情報から収集されます。
          </p>
          <p>
            (7) 関連する契約書またはその他の文書に署名し、履行するために必要。
          </p>
          <p>
            (8)
            製品やサービスの不具合の発見や対応など、提供する製品やサービスの安全かつ安定的な運用を維持するために必要な場合。
          </p>
          <p>(9) 正当なニュース報道に必要;</p>
          <p>
            (10)
            学術研究機関は、公共の利益に基づく統計的または学術的研究を行う必要があり、学術的研究または記述結果を外部に提供する場合には、その結果に含まれる個人情報を匿名化する必要があります。
          </p>
          <p>(11)その他法令に定める場合。</p>
          <p>
            適用される法律に従って、<strong
              ><u
                >データの受信者が特定の個人を再識別できず、復元できないように、技術的手段およびその他の必要な手段を講じて個人情報を処理する場合</u
              ></strong
            >、または、このプラットフォームのコンテンツとレイアウトを改善し、ビジネス上の意思決定のための製品またはサービスのサポートを提供し、製品とサービスを改善するために、収集された情報に対して匿名化された調査、統計分析、および予測を行う場合があります
            (以下を含む)。機械学習またはモデル
            アルゴリズムのトレーニングに匿名データを使用する場合)、<strong
              ><u
                >そのような処理されたデータの使用は、ユーザーに通知して同意を得る必要はありません</u
              ></strong
            >。
          </p>
          <p>
            4.
            当社が本プラットフォームの製品またはサービスの運用を停止する場合、当社は適時にお客様の個人情報の収集を停止し、順次配信または発表の形で運用停止の通知を通知し、保有する関連情報を総合的に見直し、業務停止に係る個人情報は削除または匿名化いたします。
          </p>
          <p>
            <strong
              >2. 使用方法{{ this.basicConfig.CompanyNameEnglish }}</strong
            >
          </p>
          <p>
            <strong
              ><u
                >ブラウザまたはブラウザの拡張機能が許可されている場合は、{{
                  this.basicConfig.CompanyNameEnglish
                }}私たちの承認または拒否{{
                  this.basicConfig.CompanyNameEnglish
                }}。</u
              ></strong
            >.
            ただし、そうすると、場合によってはプラットフォームへの安全なアクセスに影響を与える可能性があり、プラットフォームにアクセスするたびにユーザー設定を変更する必要がある場合があります。
            次のことを理解し、同意します。
          </p>
          <p>
            1.
            あなたが私たちのプラットフォームにアクセスすると、私たちはパスします{{
              this.basicConfig.CompanyNameEnglish
            }}Google Stats
            を使用してパフォーマンスを記録し、オンライン広告の効果を確認してください。{{
              this.basicConfig.CompanyNameEnglish
            }}ブラウザに送信され、コンピュータのハード
            ドライブに保存される少量のデータです。
            コンピューターを使用して当社のプラットフォームにアクセスする場合のみ,{{
              this.basicConfig.CompanyNameEnglish
            }}コンピューターのハード ドライブに送信する前に。
          </p>
          <p>
            2,{{
              this.basicConfig.CompanyNameEnglish
            }}プラットフォームでさまざまなアイテムを閲覧する際の訪問者の習慣や好みを記録するためによく使用されます。{{
              this.basicConfig.CompanyNameEnglish
            }}収集される情報は匿名の集合統計であり、個人データは含まれません。
          </p>
          <p>
            3.{{
              this.basicConfig.CompanyNameEnglish
            }}ハードディスク上のデータ、電子メール
            アドレス、および個人データを取得するために使用することはできません。これにより、このプラットフォームまたはサービス
            プロバイダー
            システムがブラウザを認識し、情報を取得して記憶することができます。
            ほとんどのブラウザはデフォルトで受け入れます{{
              this.basicConfig.CompanyNameEnglish
            }}. ブラウザを受け入れないように設定することができます{{
              this.basicConfig.CompanyNameEnglish
            }},または場合{{
              this.basicConfig.CompanyNameEnglish
            }}インストールするとすぐに通知されます。 ただし、無効に設定すると{{
              this.basicConfig.CompanyNameEnglish
            }},
            当社のプラットフォームの特定の機能をアクティブ化または使用できない場合があります。
          </p>
          <p><strong>3. お客様の情報を共有、転送、公開する方法</strong></p>
          <p><strong>(1) 共有</strong></p>
          <p>
            以下の場合を除き、お客様の個人情報を、このプラットフォームのサービスプロバイダー以外の企業、組織、および個人と共有することはありません。
          </p>
          <p>
            1. 法定状況下での共有:
            当社は、法律および規制、訴訟、紛争解決の必要性に従って、または行政および司法当局の法的要件に従って、お客様の個人情報を外部と共有する場合があります。
          </p>
          <p>
            2. 明示的な同意による共有:
            お客様の明示的な同意を得た後、当社はお客様の個人情報を他の当事者と共有します。
          </p>
          <p>
            3. お客様が積極的に選択した場合の共有:
            当社は、お客様の取引およびアフターサービスのニーズを実現するために、お客様の選択に応じて、注文情報の取引に関連する必要な情報をデジタル資産取引の相手方と共有します。
          </p>
          <p>
            4. 認定パートナーとの共有:
            当社の関連会社、当社のプラットフォームの運営、事業の遂行、またはお客様へのサービスの提供を支援する信頼できる第三者
            (これらの当事者がこの情報の機密を保持することに同意する場合に限る)。法律、規制、規則、規則、または裁判所やその他の所轄官庁からの命令を遵守するため、当社のプラットフォーム戦略を実施するため、プラットフォームを適切に運用するため、関連当事者にサービスを提供するため、または当社または他者の権利、財産または安全を保護するために適切です。
            .
          </p>
          <p><strong>(2) 転送</strong></p>
          <p>
            以下の場合を除き、お客様の個人情報を企業、組織、または個人に転送することはありません。
          </p>
          <p>
            1. 明示的な同意による転送:
            お客様の明示的な同意を得た後、お客様の個人情報を他の当事者に転送します。
          </p>
          <p>
            2.
            このプラットフォーム上での合併、買収または破産清算、または合併、買収または破産清算を伴うその他の状況において、個人情報の移転が伴う場合、当社は、お客様の個人情報を保持する新しい会社または組織を必要とします。そうしないと、会社、組織、および個人に再度許可を求める必要があります。
          </p>
          <p><strong>(3) 一般公開</strong></p>
          <p>当社は、次の状況でのみ、お客様の個人情報を公開します:</p>
          <p>
            1.
            お客様の明示的な同意がある場合、またはお客様の積極的な選択に基づいて、当社はお客様の個人情報を公開する場合があります。
          </p>
          <p>
            2.
            <strong
              ><u
                >あなたが法律や規制に違反したか、このプラットフォームの関連する契約や規則に重大な違反をしたと当社が判断した場合、またはこのプラットフォームのユーザーまたは一般の人々の個人的および財産的安全を侵害から保護するため、関連する違反およびこのプラットフォームがあなたに対して取った措置を含め、法律および規制に従って、またはあなたの同意を得て、あなたの個人情報の開示</u
              ></strong
            >を行う場合があります。
          </p>
          <p>
            <strong
              >(4)
              個人情報を共有、転送、公開する際の事前承認と同意の例外</strong
            >
          </p>
          <p>
            以下の状況では、お客様の個人情報の共有、転送、公開に、お客様の事前の承認と同意は必要ありません。
          </p>
          <p>1. 国家安全保障および国防安全保障に関連する;</p>
          <p>2. 公安、公衆衛生、主要な公益に関連するもの;</p>
          <p>
            3.
            犯罪捜査、起訴、裁判、判決執行などの司法または行政法執行に関連するもの;
          </p>
          <p>
            4.
            本人またはその他の私生活、財産その他の重大な正当な権利および利益を保護するためであって、本人の同意を得ることが困難である場合
          </p>
          <p>5. 自分自身が公開した個人情報</p>
          <p>
            6.
            法的報道、政府による情報開示、その他のチャネルなど、法的に開示された情報から個人情報を収集する。
          </p>
          <p>
            データの受信者が特定の個人を再識別できないように、<strong
              ><u
                >個人情報を処理するために技術的措置およびその他の必要な措置を講じる場合、適用法に従って、そのような処理されたデータの共有は禁止されることに注意してください。個人であり、回収、譲渡、公開することはできません。通知して同意を得る必要はありません</u
              ></strong
            >。
          </p>
          <p><strong>4. お客様の情報をどのように保護するか</strong></p>
          <p>
            1.
            当社は、お客様の個人データのセキュリティを保護および保証するために、適切な物理的、電子的、管理的および技術的対策を実施します。当社は、当社のプラットフォームを通じて収集された個人情報が、当社に関係のない第三者による干渉を受けないように最善を尽くしています。当社が講じるセキュリティ対策には以下が含まれますが、これらに限定されません。
          </p>
          <p>
            (1) 物理的対策: 個人データを含む記録は施錠された場所に保管されます。
          </p>
          <p>
            (2) 電子的措置: お客様の個人データを含むコンピューター
            データは、厳格なログイン制限の下でコンピューター
            システムおよび記憶媒体に保存されます。
          </p>
          <p>
            (3) 管理措置:
            当社は、ユーザー情報のセキュリティを保護するために社内に関連部門を設置し、関連する内部統制システムを確立し、お客様の情報にアクセスする可能性のあるスタッフに対して厳格な承認原則を採用しています.
            承認されたスタッフのみがお客様にアクセスできます.これらのスタッフは、個人データの機密保持に関する社内規定を遵守する必要があります。さらに、関連する法律や規制、プライバシーと安全基準、およびスタッフトレーニングのための安全意識の広報を強化し続け、関連する社内担当者を定期的に組織して緊急対応トレーニングと緊急事態訓練を実施し、職務と緊急事態を習得できるようにします。対応戦略と手順
          </p>
          <p>
            (4) 技術的対策：Secure Socket Layer の使用など
            暗号化は、個人データを送信するための暗号化技術です。
          </p>
          <p>
            （5）セキュリティ対策：お客様の情報セキュリティを保護するために、現在のさまざまな一般的なセキュリティ技術とサポート管理システムを使用して、お客様の情報の漏洩、損傷、誤用、不正アクセス、不正開示および変更のリスクを最小限に抑えることに取り組んでいます.たとえば、ネットワーク
            セキュリティ レイヤー ソフトウェア (SSL)
            を介した暗号化された送信、暗号化された情報の保存、データ
            センターへの厳密な制限付きアクセスなどです。機密性の高い個人情報
            (個人の生体情報を含む)
            を送信および保存する場合、暗号化、許可制御、非識別化、および無感作化などのセキュリティ対策を採用します。
          </p>
          <p>
            (6) その他の措置:
            個人データの収集、保管、処理手順を定期的に見直し、&nbsp;
            必要な原則に従って従業員とサプライヤーのデータへのアクセスを制限し、従業員とサプライヤーは厳格な契約上の守秘義務を遵守する必要があります。
            .
          </p>
          <p>
            2.
            当社のプラットフォームにセキュリティの抜け穴があることをご存知の場合は、すぐにご連絡ください。できるだけ早く適切な措置を取らせていただきます。
          </p>
          <p>
            3.
            上記の技術的およびセキュリティ対策の実施にもかかわらず、&nbsp;インターネット上でのデータ送信の絶対的なセキュリティを保証することはできません。したがって、&nbsp;プラットフォームを通じて提供された個人データが安全であることを完全に保証することはできません。回。当社は、セキュリティ
            リスク、個人情報セキュリティ影響評価レポート、およびその他の関連コンテンツを随時更新および公開します。これらは、このプラットフォームでの発表を通じて入手できます。
          </p>
          <p><strong>5. 自分の情報をどのように管理していますか</strong></p>
          <p>次の方法で情報にアクセスして管理できます:</p>
          <p><strong>(1) お客様情報の照会、訂正、削除</strong></p>
          <p>
            お客様は、要求に応じてお客様の個人データのコピーを取得し、当社が保持するお客様に関する情報が正確かつ最新であるかどうかを確認する権利を有します。個人データのいずれかが不正確である場合、情報の更新を要求することができます。また、個人データの消去を要求することもできますが、法律またはその他の法的目的で要求される場合など、特定の状況では消去要求を拒否する場合があります。データへのアクセス、修正または削除の要求については、第
            8 条の連絡先情報からご連絡ください。 &nbsp;
          </p>
          <p>
            データへのアクセス、修正、または削除の要求に対応する際に、要求元の身元を確認して、その要求を行う法的権限があることを確認します。当社の目標はこれらの要求に無料で対応することですが、お客様の要求が反復的または面倒な場合は、妥当な料金を請求する権利を留保します。
            &nbsp;
          </p>
          <p><strong>(2) 承認と同意の範囲を変更する</strong></p>
          <p>
            各ビジネス機能では、いくつかの基本的な個人情報を入力する必要があります
            (このプライバシー ポリシーの「パート 1」を参照)。さらに、第 8
            条の連絡先情報を通じて、承認および同意を付与または撤回するために当社に連絡することができます。
          </p>
          <p>
            同意を撤回すると、対応する個人情報は処理されなくなります。
            <strong
              ><u
                >ただし、同意を撤回するというあなたの決定は、あなたの許可に基づく以前の個人情報の処理には影響しません。
              </u></strong
            >
          </p>
          <p><strong>(3) アカウントをキャンセルする</strong></p>
          <p>
            お客様は、第 8
            条の連絡先情報を通じて当社に連絡し、アカウントのキャンセルを申請することができます;
            お客様が自発的にアカウントをキャンセルした後、当社は、お客様への製品またはサービスの提供を停止し、適用法の要件に従ってお客様の個人情報を削除するか、または匿名化。
          </p>
          <p><strong>(4) 上記のリクエストへの対応</strong></p>
          <p>
            セキュリティ上の理由から、書面による要求を提供するか、その他の方法で身元を証明する必要がある場合があります。リクエストを処理する前に、本人確認をお願いする場合があります。
          </p>
          <p>
            15日以内に返信いたします。ご満足いただけない場合は、当社が提供する連絡先情報から苦情を申し立てることもできます。
          </p>
          <p>
            合理的なご請求については、原則として手数料はいただきませんが、合理的な限度を超えて繰り返し請求された場合には、必要に応じて一定の手数料を請求させていただきます。あなたの身元に直接関連付けられていない情報、理由もなく情報を繰り返す情報、または過度の技術的手段を必要とする情報
            (たとえば、新しいシステムの開発や既存の慣行の根本的な変更を必要とする)
            については、正当な人にリスクをもたらします。他人の権利や利益、または非現実的である場合、拒否する場合があります。
          </p>
          <p>
            以下の場合は、法令の要請に従い、ご要望に応じることができません。
          </p>
          <p>1. 国家安全保障および国防安全保障に関連する;</p>
          <p>2. 公安、公衆衛生、主要な公益に関連するもの;</p>
          <p>3. 犯罪捜査、訴追、裁判、判決の執行に関連するもの;</p>
          <p>
            4.
            個人情報の主体が主観的な悪意または権利の濫用を持っているという十分な証拠がある;
          </p>
          <p>
            5.
            お客様の要求に応じることにより、お客様または他の個人または組織の正当な権利および利益に重大な損害が生じる場合。
          </p>
          <p>6. 営業秘密に関わるもの。</p>
          <p><strong>6. 未成年者の情報の取り扱い方法</strong></p>
          <p>
            このプラットフォームは子供に開かれています. ユーザーが 14
            歳未満であることが判明した場合、またはその疑いがある場合は、ユーザーにアカウントを閉鎖するよう求め、ユーザーがプラットフォームの製品またはサービスを引き続き使用することを許可しません.関連情報は速やかに削除いたします。当社のサービスを使用している
            14
            歳未満の人を知っている場合は、当社に通知してください。当社の製品またはサービスへのアクセスを防止するための措置を講じることができます。
          </p>
          <p>
            14 歳に達しているが 18
            歳に達していない未成年者については、親または保護者が未成年者に当社のサービスを使用するように案内することを期待しています。関係法令に従い、未成年者の情報の機密性と安全性を守ります。
          </p>
          <p>
            あなたが未成年者である場合、あなたの両親または保護者がこのポリシーを読み、あなたの両親または保護者の同意を得て、当社のサービスを使用するか、またはあなたの情報を提供することをお勧めします.親または保護者の同意を得て情報を収集する場合、法律で許可されている場合、親または保護者の明示的な同意がある場合、またはあなたの権利と利益を保護するために必要な場合にのみ、この情報を使用または公開します。保護者が、このポリシーに従って当社のサービスを使用すること、または当社に情報を提供することに同意しない場合は、当社のサービスの使用を直ちに停止し、対応する措置を講じることができるように、時間内に当社に通知してください。
          </p>
          <p>
            あなたが未成年者の親または後見人である場合、あなたの後見下にある未成年者の情報処理について質問がある場合は、このポリシーに掲載されている連絡先情報を通じて当社に連絡してください。
          </p>
          <p><strong>7. このプライバシー ポリシーの更新方法</strong></p>
          <p>当社のプライバシー ポリシーは変更される場合があります。</p>
          <p>
            お客様の明示的な同意なしに、本プライバシー
            ポリシーに基づくお客様の権利を制限することはありません。プライバシー
            ポリシーの変更は、公式チャンネルに掲載されます。改訂された内容に同意しない場合は、このプラットフォームへのアクセスを直ちに停止する必要があります。プライバシー
            ポリシーの更新版がリリースされると、このプラットフォームへの継続的なアクセスが表示され、更新されたコンテンツに同意し、更新されたプライバシー
            ポリシーを順守することに同意したことが示されます。
          </p>
          <p>
            主要な変更については、より目立つ通知も提供します
            (このプラットフォームの宣伝を通じて通知したり、ポップアッププロンプトを提供したりすることも含まれます)。
          </p>
          <p>
            このポリシーで言及されている重大な変更には、以下が含まれますが、これらに限定されません:
          </p>
          <p>
            1. サービス
            モデルに大きな変更が加えられました。個人情報の処理目的、処理する個人情報の種類、個人情報の利用方法等
          </p>
          <p>
            2.
            支配権等を大きく変更いたしました。合併や買収などによる情報管理者の変更など。
          </p>
          <p>3. 個人情報の共有、転送、または公開の主な目的が変更された;</p>
          <p>
            4.
            個人情報の処理に参加する権利とその行使方法に大きな変更がありました;
          </p>
          <p>
            5. 個人情報保護に関する当社の担当部署、連絡方法、苦情窓口の変更
          </p>
          <p>
            6.
            個人情報セキュリティ影響評価レポートは、リスクが高いことを示しています。
            &nbsp;
          </p>
          <p><strong>VIII. お問い合わせ方法</strong></p>
          <p>
            1.
            要件やコメントがある場合は、電子メールを送信できます。これは、当社がお客様との通信に使用する唯一の有効かつ公式の電子メールです。したがって、有効な連絡先情報を使用しない行為または不作為について、当社は責任を負いません。
          </p>
          <p>
            2.私たちは、このプラットフォーム上の有効な連絡先情報を通じてのみ、このプラットフォームに発表や情報を公開したり、発表を投稿したりするため、上記の方法で取得されなかった情報に対するあなたの信頼によって引き起こされた損失について、当社は責任を負いません。
          </p>
          <p>
            3. 当社のプライバシー
            ポリシーについてご不明な点がございましたら、お気軽にお問い合わせください。
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    <!-- 韩语 -->
    <div class="main" v-show="lang == 'hy'">
      <div class="box">
        <div class="top">
          <div class="text1">개인정보 보호정책</div>
          <div class="text2">마지막 개정일: 2022-08-20</div>
        </div>
        <div class="content">
          <p><strong>【소개 및 알림 약관】</strong></p>
          <p>
            이 플랫폼은 사용자가 디지털 자산 거래를 수행하고 관련 서비스(이하
            "서비스" 또는 "서비스")를 제공하는 플랫폼입니다. 표현의 편의를 위해
            이 플랫폼은 본 계약에서 "당사" 또는 기타 1인칭 칭호로 통칭합니다.
            플랫폼에 로그인하는 자연인 또는 기타 주체가 이 플랫폼의 사용자인 한
            "귀하"는 또는 기타 2인칭 대명사가 아래에 사용됩니다. 당사와 귀하를
            총칭하여 "당사자"라고 하며 당사 또는 귀하를 개별적으로 "당사자"라고
            합니다.
          </p>
          <p>
            당사는 귀하에 대한 귀하의 개인 정보의 중요성을 충분히 이해하고
            존중하며 귀하의 개인 정보 보안을 보호하기 위해 법률 및 규정의 요구
            사항에 따라 상응하는 보안 보호 조치를 취할 것입니다. 이를 고려하여
            당사는 이 "개인정보 보호정책"(이하 "이 정책&nbsp;/이 개인정보
            보호정책")을 공식화하고 귀하에게 다음을 상기시킵니다.
          </p>
          <p>
            이 정책은 당사가 제공하는 모든 제품 및 서비스에 적용됩니다.
            <strong
              ><u>
                당사가 귀하에게 제공하는 제품 또는 서비스에 별도의 개인정보
                보호정책이 있는 경우 해당 제품 또는 서비스는 별도의 개인정보
                보호정책을 적용하며, 당사 제품 또는 서비스에 별도의 개인정보
                보호정책이 없는 경우에는 정책.
              </u></strong
            >
          </p>
          <p>
            <strong
              ><u
                >이 정책은 다른 제3자가 귀하에게 제공하는 서비스에는 적용되지
                않으며, 제3자가 귀하에게 제공하는 서비스에는 달리 설명된
                개인정보 보호정책이 적용됩니다.
              </u></strong
            >
          </p>
          <p>
            <strong
              ><u
                >당사의 제품 또는 서비스를 사용하기 전에 이 정책, 특히 굵게/굵은
                밑줄로 표시된 용어를 주의 깊게 읽고 이해해야 합니다. 주의 깊게
                읽고 완전히 이해했는지 확인하고 사용을 시작하는 데 동의해야
                합니다. . 이 정책의 내용에 대한 질문, 의견 또는 제안이 있는 경우
                당사에서 제공하는 다양한 공식 연락 방법을 통해 당사에 연락할 수
                있습니다. 이 정책의 조항에 동의하지 않는 경우 이 플랫폼에 대한
                액세스를 즉시 중지해야 합니다.
              </u></strong
            >
          </p>
          <p>&nbsp;</p>
          <p><strong>1부 정의</strong></p>
          <p>
            <strong>개인정보:</strong> 전자적 또는 기타 방법으로 기록되어 특정
            자연인을 식별하거나 특정 자연인의 활동을 반영할 수 있는 각종 정보를
            단독으로 또는 다른 정보와 결합하여 말합니다.
          </p>
          <p>
            <strong>개인민감정보:</strong> 주민등록번호, 개인생체정보,
            은행계좌번호, 재산정보, 소재지, 거래정보, 만 14세 미만 아동정보 등을
            포함한 개인정보(특정개인민감정보 포함)를 말합니다. 정보는 이
            개인정보 보호정책에서 <strong>굵게</strong>로 눈에 띄게 표시됩니다).
          </p>
          <p>
            <strong>개인정보 삭제:</strong> 개인정보를 일상적인 비즈니스 기능
            구현과 관련된 시스템에서 제거하여 검색하거나 액세스할 수 없는 상태로
            유지하는 행위를 말합니다.
          </p>
          <p><strong>어린이</strong>: 14세 미만의 미성년자를 말합니다.</p>
          <p><strong>2부&nbsp;&nbsp;개인정보 보호정책</strong></p>
          <p>
            이 개인정보 보호정책 섹션은 다음 사항을 이해하는 데 도움이 됩니다.
          </p>
          <p>1. 귀하의 정보를 수집하고 사용하는 방법</p>
          <p>
            2. 사용 방법{{ this.basicConfig.CompanyNameEnglish }}및 이와 유사한
            기술
          </p>
          <p>3. 귀하의 정보를 공유, 전송 및 공개하는 방법</p>
          <p>4. 귀하의 정보를 보호하는 방법</p>
          <p>5. 정보 관리 방법</p>
          <p>6. 미성년자 정보 취급 방법</p>
          <p>7. 이 개인 정보 보호 정책을 업데이트하는 방법</p>
          <p>VIII. 문의 방법</p>
          <p>&nbsp;</p>
          <p><strong>1. 귀하의 정보를 수집하고 사용하는 방법</strong></p>
          <p>
            귀하가 당사 제품 및/또는 서비스를 사용할 때 수집하고 사용해야 하는
            개인 정보에는 다음 두 가지 유형이 포함됩니다.
          </p>
          <p>
            1.
            <strong
              >제품 및/또는 서비스의 기본 기능을 제공하기 위해 필요한 정보를
              수집하고 사용할 수 있도록 승인해야 합니다. 해당 정보 제공을
              거부하면 제품 및/또는 서비스를 정상적으로 사용할 수
              없습니다.</strong
            >
          </p>
          <p>
            2.
            <strong
              >당사 제품 및/또는 서비스의 추가 기능을 제공하기 위해 귀하는
              당사가 정보를 수집하고 사용하도록 승인할 수 있습니다. 제공을
              거부하는 경우 관련 추가 기능을 사용할 수 없거나 당사가 달성하려는
              기능적 효과를 얻을 수 없지만 당사 제품 및/또는 서비스의 정상적인
              기본 기능 사용에는 영향을 미치지 않습니다.
            </strong>
          </p>
          <p>
            <strong><u>귀하는 다음 사항을 이해하고 동의합니다.</u></strong>
          </p>
          <p>
            <strong>1</strong
            ><strong
              >、<u
                >우리는 귀하의 요구를 충족시키는 다양한 제품과 서비스를 만들기
                위해 최선을 다하고 있습니다. 당사는 귀하에게 다양한 제품 및
                서비스를 제공하고 있고, 다양한 사용자가 선택한 특정
                제품/서비스의 범위가 다르기 때문에 그에 따라 기본/추가 기능과
                수집 및 사용되는 개인 정보의 유형 및 범위가 다릅니다. 특정
                제품/서비스 기능이 우선합니다</u
              >;</strong
            >를 참조하십시오.
          </p>
          <p>
            <strong>2</strong
            ><strong
              >,
              <u
                >더 나은 제품 및 서비스 경험을 제공하기 위해 당사는 기술 개선을
                위해 지속적으로 노력하고 있으며, 수시로 새롭거나 최적화된 제품을
                출시할 수 있습니다. 새로운 개인정보를 수집·이용하거나 개인정보의
                이용목적 또는 이용방법을 변경하기 위하여 필요한 경우 이와
                관련하여 본 방침, 팝업창, 페이지 안내 등을 업데이트하여 해당
                정보의 목적, 범위 및 용도 등을 귀하에게 별도로 설명하고, 귀하가
                동의를 선택할 수 있는 방법을 안내해 드린 후, 명시적인 동의 얻기
                수집, 사용</u
              >.
            </strong>
            이 과정에서 질문, 의견 또는 제안이 있는 경우 이 플랫폼에서 제공하는
            다양한 연락 방법을 통해 문의할 수 있으며 최대한 빨리 답변해
            드리겠습니다.
          </p>
          <p>&nbsp;</p>
          <p>
            당사는 이 정책의 다음 기능을 달성하기 위해 귀하의 개인 정보를
            수집하고 사용합니다.
          </p>
          <p>
            <strong
              >(1)&nbsp;등록을 돕고 이 플랫폼에서 제공하는 서비스를
              제공합니다.</strong
            >
          </p>
          <p><strong>1</strong><strong>, 등록 서비스</strong></p>
          <p>
            플랫폼 계정을 통해 기본 멤버십 서비스를 제공합니다. 회원가입 및
            회원제 서비스 이용을 위해서는 회원가입 신청 시 다음의 정보를 기입 및
            제공하셔야 합니다.
          </p>
          <p>
            (1) 신원 정보. 이 정보는 귀하의 이름, 거주지 주소, 귀하의 신원을
            증명하는 정보와 함께 귀하의 국가 또는 정부에서 발행한 기타 인증서 및
            관련 번호를 포함하되 이에 국한되지 않는 귀하가 이 플랫폼의 회원으로
            등록할 자격이 있는지 여부를 확인하는 데 도움이 될 수 있습니다.
            귀하의 신원을 확인하는 데 도움이 되는 기타 모든 정보(총칭하여 "신원
            정보"&nbsp;). 신원 정보는 귀하가 개인 사용자인지 기관 사용자인지에
            따라 다르며, 관할권의 법률 및 규정에 따라 국가 또는 지역에 따라
            사용자에 대해 수집하는 정보가 다를 수 있습니다. 다음 정보와 데이터는
            예시로 사용됩니다.
          </p>
          <p>
            <strong>개인 사용자</strong>: 국적, 법정 전체 이름 (증용명 및 현지
            언어 이름 포함), 주민등록번호, 이메일 주소, 전화번호, 사회안전번호
            ("SSN"), 여권번호 또는 정부가 발급한 주민등록번호, 생년월일, 신분증
            (예: 여권, 운전면허증 또는 정부가 발급한 신분증)
          </p>
          <p>
            <strong> 기관 사용자 </strong>: 회사 법정 명칭(현지 언어의 법정 명칭
            포함), 등록 정보, 비즈니스 전화 번호, 권한 수여 대표 및 권한 수여
            사용자 개인 정보, 주요 영업 장소, 모든 실익 소유자의 개인 정보),
            주주/실익 소유자의 신분증 서류(여권 또는 정부가 발급한 신분증), 회사
            구조, 회사 설립 관련 서류, 이사회 결의, 자금 출처 신고,위험 및 규정
            준수 지침 및 플랫폼 요구 사항에 대한 추가 정보 또는 파일
          </p>
          <p>
            (2) 서비스 정보.이 정보는 귀하의 개인 식별 정보, 연락처 정보, 거래
            정보, 그리고 귀하의 직불 카드 정보 및/또는 기타 계정 정보(이하
            "서비스 정보" )를 포함하되 이에 국한되지 않는 다양한 모델의 디지털
            자산 간 거래 서비스를 귀하에게 연락하고 원활하게 제공할 수 있도록
            도와줍니다.<strong>과 동시에 </strong>은 우리가 당신에게 제공하는
            서비스가 끊임없이 갱신되고 발전한다는 것을 이해해 주십시오.만약에
            귀하가 상술한 설명에 포함되지 않은 다른 서비스를 선택하여
            사용하셨다면 저희는 귀하의 <strong> 추가 </strong> 정보를 수집해야
            하기 때문에 저희는 페이지 제시, 상호작용 절차, 협의 약정, 탄창 제시
            등 방식으로 귀하께 정보 수집의 범위와 목적을 설명하고 귀하의 동의를
            구할 것입니다.
          </p>
          <p>
            브라우징 서비스만 사용할 경우 회원으로 등록하거나 위의 정보를 제공할
            필요가 없습니다.
          </p>
          <p><strong>2</strong><strong>, 기타 등록 서비스</strong></p>
          <p>
            실명인증: 귀하가 등록하여 로그인한 후"개인센터"에 들어가서
            신분인증에서 실명인증을 완성해야 합니다. 귀하는 실명인증을 사용하는
            과정에서 플랫폼에 귀하의 실명, 신분증번호 또는 여권번호를 제공해야
            합니다.
          </p>
          <p>
            고급 인증: 귀하가 이 플랫폼에서 거래액이 일정한 한도를 초과하면
            플랫폼은 귀하의 자산 안전을 유지하기 위해 귀하에게 고급 인증을
            요구합니다.인증 과정은 실명 인증 정보 외에 귀하는 플랫폼이
            귀하에게"안면 인식"의 고급 인증을 하는 것을 인정합니다. 안면 인식은
            플랫폼이 귀하의 관련 안면 이미지를 수집하는 것과 관련됩니다.
          </p>
          <p>
            <strong
              ><u>
                계정 정보 표시: 귀하가 이미 이 플랫폼 계정을 가지고 있다면
                저희는 귀하의 상기 개인 정보(실명인증은 인증이 통과되었는지의
                결과만 표시)와 귀하가 플랫폼 계정과 관련된 제품과 서비스에서
                수행한 조작(본 플랫폼 계정을 통해 귀하의 개인 정보, 거래 주문을
                집중적으로 보여주는 것을 포함합니다.플랫폼 계정 설정에 대한
                귀하의 선택을 존중합니다.</u
              ></strong
            >
          </p>
          <p>
            <strong>(2)</strong>
            <strong>보안 관리 서비스 및 서비스 정보 푸시 서비스</strong>
          </p>
          <p>
            귀하가 이 플랫폼에서 제공한 서비스를 사용하거나 이 플랫폼에 액세스할
            때 이 플랫폼은 귀하의 거래 안전과 서비스의 안전하고 안정적인 운행을
            보장하기 위해 귀하의 웹 브라우저 정보를 자동으로 수신하고 기록해야
            합니다. 귀하가 계정에 로그인하여 사용하는 장치 정보(IDFA, 운영체제,
            설치 모델, 장치 제조업체, 시스템 버전, Android ID、IMEI 그리고 MAC
            주소 등), 귀하가 방문을 요청한 웹 페이지의 IP 주소 및 기록, 지역,
            네트워크 환경, 장치 지문 정보, 서비스 로그 정보, 소프트웨어 정보,
            위치 정보 등.
          </p>
          <p>
            <strong
              ><u>
                개별 장치 정보는 특정 자연인의 신원을 식별할 수 없는 정보입니다.
              </u>
            </strong>
            만약에 저희가 이런 비개인 정보를 다른 정보와 결합하여 특정한
            자연인의 신분을 식별하거나 이를 개인 정보와 결합하여 사용한다면 결합
            사용 기간에 이런 비개인 정보는 개인 정보로 간주되고 귀하의 권한을
            얻거나 법률과 법규에 규정된 다른 규정을 제외하고 저희는 이런 정보를
            익명화, 식별화 처리합니다.
          </p>
          <p>
            <strong
              ><u
                >는 귀하에게 더욱 편리하고 귀하의 개성화 요구에 부합되는 정보
                전시와 푸시 서비스를 제공하기 위해 저희는 앞으로 귀하의 설비
                정보와 귀하의 플랫폼이나 클라이언트의 조회 사용 정보에 근거하여
                귀하의 선호 특징을 추출하고 특징 라벨을 바탕으로 간접적인
                사람들의 초상화를 산출하여 전시, 푸시 정보와 가능한 상업 광고에
                사용할 수 있습니다.귀하가 이러한 푸시 정보 수신에 동의하지
                않거나 개인정보 수집 동의를 철회하는 경우, 귀하는 본 정책에서
                제공한 연락처에 따라 저희에게 연락할 수 있습니다.</u
              ></strong
            >
          </p>
          <p>
            우리는 귀하의 브라우징 경험을 보호하기 위해 열심히 노력합니다.
            당사가 귀하에게 보내는 상업 광고를 수락하고 싶지 않다면 SMS 프롬프트
            또는 당사가 제공하는 다른 방법에 회신하여 구독을 취소하거나 닫을 수
            있습니다.
          </p>
          <p>
            또한 위의 기능을 지속적으로 개선하고 최적화하기 위해 위의 정보를
            사용할 것입니다.
          </p>
          <p><strong>(3) 거래기록 보관 및 조회 서비스</strong></p>
          <p>
            거래 기록을 포함하되 이에 국한되지 않는 이 플랫폼에서의 거래에 대해
            당사가 수집하는 관련 데이터. 주문 거래 기록 조회가 필요한 경우
            플랫폼의 "주문 관리"에서 거래 상대방 닉네임, 주문 시간, 주문 번호,
            거래 정보, 디지털 자산 유형 및 수량, 등.
          </p>
          <p><strong>(4) 고객 서비스 및 분쟁 해결</strong></p>
          <p>
            귀하가 당사에 연락하거나 판매 중 및 판매 후 분쟁 처리 신청서를
            제출할 때
            <strong
              ><u
                >귀하의 계정 및 시스템 보안을 보호하기 위해 귀하의 회원 자격을
                확인하는 데 필요한 개인 정보를 제공해야 합니다.
              </u></strong
            >
          </p>
          <p>
            <strong
              ><u
                >귀하와의 연락을 용이하게 하고 가능한 한 빨리 문제를 해결하도록
                돕거나 관련 문제의 해결책과 결과를 기록하기 위해 당사는 귀하의
                통신 기록 및 관련 콘텐츠(계정 정보, 주문 정보 포함)를 저장할 수
                있습니다. 해당 사실을 증명하기 위해 제공한 기타 정보 또는 귀하가
                남긴 연락처) 특정 주문에 대해 문의, 불만 또는 제안을 제공하는
                경우 귀하의 계정 정보 및 주문 정보를 사용합니다.
              </u></strong
            >
          </p>
          <p>
            서비스를 제공하고 서비스 품질에 대한 합당한 요구 사항을 개선하기
            위해 당사는 귀하가 고객 서비스에 연락할 때 제공하는 관련 정보 및
            설문 조사에 참여할 때 당사에 보내는 설문 응답 정보를 포함하여 귀하에
            대한 기타 정보를 사용할 수도 있습니다.
          </p>
          <p><strong>(5) 기타</strong></p>
          <p>
            <strong><u>1</u></strong
            ><strong
              ><u>
                귀하가 제공하는 정보에 다른 사용자의 개인 정보가 포함되어 있는
                경우 해당 개인 정보를 이 플랫폼에 제공하기 전에 다음을 확인해야
                합니다. 법적 승인을 얻습니다. 아동의 개인정보와 관련된 경우
                게시하기 전에 해당 아동의 보호자의 동의를 얻어야 하며, 위와 같은
                경우 보호자는 본 정책 제9조의 채널을 통해 당사에 연락하여 해당
                아동의 개인정보에 대한 정정 또는 삭제를 요청할 권리가 있습니다.
                아동의 개인 정보가 포함된 콘텐츠.
              </u></strong
            >
          </p>
          <p>
            <strong><u>2</u></strong
            ><strong
              ><u>
                본 정책에 명시되지 않은 다른 목적으로 정보를 사용하거나 특정
                목적으로 수집된 정보를 다른 목적으로 사용하거나 귀하의
                개인정보를 제3자로부터 적극적으로 취득하는 경우 사전에 귀하의
                동의를 받도록 하겠습니다.
              </u></strong
            >
          </p>
          <p>
            귀하의 정보를 제3자로부터 간접적으로 취득하는 경우에는 수집 전 관련
            법령에 따라 귀하의 개인정보 수집 동의를 얻은 후 명시적으로 제3자에게
            개인정보 수집을 요청하고, 공유하는 정보의 내용 중 민감한 정보가
            포함된 내용을 사전에 알려드립니다. 사용을 위해 당사에 제공되며,
            귀하의 명확한 확인이 필요하며, 제3자는 개인정보 출처의 적법성 및
            준수에 대한 약속을 해야 합니다. 상대방에게 해당 법적 책임을 지게
            함과 동시에 개인정보의 보안을 강화합니다(민감정보 신고, 민감정보
            암호화 저장, 접근통제 등 포함). 당사는 간접적으로 취득한 개인 정보를
            보호하기 위해 당사가 이용자의 개인 정보에 대해 사용하는 것과 동일한
            보호 수단 및 조치를 사용합니다.
          </p>
          <p><strong>3</strong><strong>, 승인된 동의에 대한 예외</strong></p>
          <p>
            귀하는 다음과 같은 경우에는 귀하의 개인정보 수집 및 이용에 귀하의
            승인 및 동의가 필요하지 않으며, 귀하의 정정/수정, 삭제, 취소,
            동의철회, 정보제공 요구에 응하지 않을 수 있음을 충분히 이해하고
            동의합니다. :
          </p>
          <p>(1) 국가 안보 및 국방 보안 관련</p>
          <p>(2) 공공 안전, 공중 보건 및 주요 공익과 관련</p>
          <p>
            (3) 형사 수사, 기소, 재판 및 판결 집행과 같은 사법 또는 행정법
            집행과 관련된
          </p>
          <p>
            (4) 귀하 또는 기타 개인의 생명, 재산 및 기타 주요 법적 권리와 이익을
            보호하기 위해 당사자의 동의를 얻는 것이 어려운 경우
          </p>
          <p>(5) 대중에게 공개하는 개인 정보</p>
          <p>
            (6) 법률 뉴스 보도, 정부 정보 공개 및 기타 채널과 같은 합법적으로
            공개된 정보에서 개인 정보를 수집합니다.
          </p>
          <p>
            (7) 귀하와 관련 계약 또는 기타 서면 문서에 서명하고 수행하는 데
            필요합니다.
          </p>
          <p>
            (8) 제품 및/또는 서비스의 장애 발견 및 처리 등 제공되는 제품 및/또는
            서비스의 안전하고 안정적인 운영을 유지하기 위해 필요한 경우
          </p>
          <p>(9) 합법적인 뉴스 보도에 필요</p>
          <p>
            (10) 학술연구기관이 공공리익에 기초하여 통계 또는 학술연구소를
            전개하는데 필요하고 학술연구 또는 묘사한 결과를 대외에 제공할 때
            결과에 포함된 개인정보를 탈표화처리한 경우;
          </p>
          <p>(11) 법률, 법규에 규정된 기타 상황.</p>
          <p>
            관련 법률에 따라 만약에 저희
            <strong
              ><u
                >가 개인정보에 대해 기술적 조치와 기타 필요한 조치를 취해
                처리하면 데이터 수신자가 특정한 개인을 다시 식별할 수 없고
              </u></strong
            >을 복원할 수 없거나 저희가 수집한 정보에 대해 식별화된 연구, 통계
            분석과 예측을 해서 본 플랫폼의 내용과 구조를 개선하고 상업적 결정에
            제품이나 서비스를 제공할 수 있습니다.또한 기계 학습 또는 모델
            알고리즘 훈련을 위해 익명의 데이터를 사용하는 것을 포함하여 당사의
            제품 및 서비스를 개선합니다.<strong
              ><u
                >는 이러한 처리 후 데이터의 사용에 대해 귀하에게 별도로 통지하고
                동의를 구할 필요가 없습니다</u
              ></strong
            >.
          </p>
          <p>
            4. 만약에 저희가 본 플랫폼의 제품이나 서비스의 운영을 중지하면
            저희는 귀하의 개인정보를 계속 수집하는 활동을 중지하고 운영을
            중지하는 통지를 귀하께 일일이 전달하거나 공지하는 형식으로 귀하께
            알리며 저희가 보유한 폐쇄된 업무와 관련된 개인정보를 삭제하거나
            익명으로 처리합니다.
          </p>
          <p>
            <strong
              >2. 사용 방법{{ this.basicConfig.CompanyNameEnglish }}</strong
            >
          </p>
          <p>
            <strong
              ><u
                >브라우저 또는 브라우저 확장 프로그램에서 허용하는 경우 다음을
                수정할 수 있습니다.{{
                  this.basicConfig.CompanyNameEnglish
                }}우리의 수락 또는 거부{{
                  this.basicConfig.CompanyNameEnglish
                }}。</u
              ></strong
            >. 그러나 그렇게 하면 경우에 따라 당사 플랫폼에 대한 안전한 액세스에
            영향을 미칠 수 있으며 당사 플랫폼을 방문할 때마다 사용자 설정을
            변경해야 할 수 있습니다. 귀하는 다음 사항을 이해하고 동의합니다.
          </p>
          <p>
            1. 귀하가 당사 플랫폼을 방문하면 당사는 통과합니다.{{
              this.basicConfig.CompanyNameEnglish
            }}Google 통계를 사용하여 성과를 기록하고 온라인 광고의 효과를
            확인하십시오.{{ this.basicConfig.CompanyNameEnglish }}귀하의
            브라우저로 전송되어 귀하의 컴퓨터 하드 드라이브에 저장되는 소량의
            데이터입니다. 컴퓨터를 사용하여 당사 플랫폼에 액세스하는 경우에만,{{
              this.basicConfig.CompanyNameEnglish
            }}컴퓨터 하드 드라이브로 전송되기 전에.
          </p>
          <p>
            2、{{ this.basicConfig.CompanyNameEnglish }}당사 플랫폼에서 다양한
            항목을 탐색할 때 방문자의 습관과 선호도를 기록하는 데 자주
            사용됩니다.{{ this.basicConfig.CompanyNameEnglish }}수집된 정보는
            익명의 집단 통계이며 개인 데이터를 포함하지 않습니다.
          </p>
          <p>
            3、{{ this.basicConfig.CompanyNameEnglish }}이 플랫폼 또는 서비스
            제공업체 시스템이 귀하의 브라우저를 인식하고 정보를 캡처하고
            기억하게 할 수 있는 귀하의 하드 디스크 데이터, 이메일 주소 및 개인
            데이터를 얻는 데 사용할 수 없습니다. 대부분의 브라우저는
            기본적으로{{ this.basicConfig.CompanyNameEnglish }}. 수락하지 않도록
            브라우저를 구성하도록 선택할 수 있습니다.{{
              this.basicConfig.CompanyNameEnglish
            }},또는 만약{{ this.basicConfig.CompanyNameEnglish }}설치되는 즉시
            알려드립니다. 그러나 비활성화로 설정하면{{
              this.basicConfig.CompanyNameEnglish
            }}, 당사 플랫폼의 특정 기능을 활성화하거나 사용하지 못할 수
            있습니다.
          </p>
          <p><strong>3. 귀하의 정보를 공유, 전송 및 공개하는 방법</strong></p>
          <p><strong>(1) 공유</strong></p>
          <p>
            당사는 다음과 같은 경우를 제외하고 이 플랫폼의 서비스 제공업체
            이외의 회사, 조직 및 개인과 귀하의 개인 정보를 공유하지 않습니다.
          </p>
          <p>
            1. 법적 상황에서의 공유: 당사는 법률 및 규정, 소송, 분쟁 해결 요구
            또는 행정 및 사법 당국의 법적 요구 사항에 따라 귀하의 개인 정보를
            외부에 공유할 수 있습니다.
          </p>
          <p>
            2. 명시적 동의에 따른 공유: 귀하의 명시적 동의를 얻은 후 당사는
            귀하의 개인 정보를 다른 당사자와 공유합니다.
          </p>
          <p>
            3. 귀하가 적극적으로 선택할 때 공유: 귀하의 거래 및 애프터 서비스
            요구를 실현하기 위해 귀하의 선택에 따라 디지털 자산 거래 상대방과
            귀하의 주문 정보에서 거래와 관련된 필요한 정보를 디지털 자산 거래
            상대방과 공유합니다.
          </p>
          <p>
            4. 승인된 파트너와의 공유: 당사 플랫폼 운영, 비즈니스 수행 또는
            귀하에게 서비스 제공을 지원하는 계열사, 신뢰할 수 있는 제3자, 이러한
            당사자가 이 정보를 기밀로 유지하는 데 동의하는 한, 정보 공개가 법률,
            규정, 규칙 및 규정을 준수하거나 법원 또는 기타 권한 있는 당국의
            명령을 준수하고, 플랫폼 전략을 구현하고, 플랫폼을 적절하게 운영하고,
            관련 당사자에게 서비스를 제공하고, 당사 또는 타인의 권리, 재산 또는
            안전을 보호하는 데 적합합니다. .
          </p>
          <p><strong>(2) 양도</strong></p>
          <p>
            당사는 다음과 같은 경우를 제외하고 귀하의 개인 정보를 회사, 조직
            또는 개인에게 이전하지 않습니다.
          </p>
          <p>
            1. 명시적 동의에 의한 전송: 귀하의 명시적 동의를 얻은 후 귀하의 개인
            정보를 제3자에게 전송합니다.
          </p>
          <p>
            2. 이 플랫폼에서 합병, 인수 또는 파산 청산 또는 합병, 인수 또는 파산
            청산과 관련된 기타 상황에서 개인 정보 이전이 관련된 경우 당사는
            귀하의 개인 정보를 보유한 새로운 회사 또는 조직에 요구할 것입니다.
            그렇지 않으면 회사, 조직 및 개인이 귀하에게 다시 승인을 요청하도록
            요구할 것입니다.
          </p>
          <p><strong>(3) 공시</strong></p>
          <p>당사는 다음과 같은 경우에만 귀하의 개인 정보를 공개합니다.</p>
          <p>
            1. 귀하의 명시적인 동의 또는 귀하의 적극적인 선택에 따라 당사는
            귀하의 개인 정보를 공개할 수 있습니다.
          </p>
          <p>
            2.
            <strong
              ><u
                >귀하가 법률 및 규정을 위반했거나 이 플랫폼의 관련 계약 및
                규칙을 심각하게 위반했다고 판단하거나 이 플랫폼 사용자 또는
                대중의 개인 및 재산 안전을 침해로부터 보호하기 위해 , 우리는
                관련 위반 및 이 플랫폼이 귀하에 대해 취한 조치를 포함하여 귀하의
                동의 하에 법률 및 규정에 따라 귀하의 개인 정보를 공개</u
              ></strong
            >할 수 있습니다.
          </p>
          <p>
            <strong
              >(4) 개인정보 공유, 이전, 공개 시 사전 승인 및 동의 예외</strong
            >
          </p>
          <p>
            다음과 같은 상황에서 귀하의 개인 정보를 공유, 이전 및 공개하는 데
            귀하의 사전 승인 및 동의가 필요하지 않습니다.
          </p>
          <p>1. 국가안보 및 국방안보 관련</p>
          <p>2. 공공 보안, 공중 보건 및 주요 공익 관련</p>
          <p>
            3. 범죄 수사, 기소, 재판 및 판결 집행과 같은 사법 또는 행정법 집행과
            관련된
          </p>
          <p>
            4. 본인 또는 기타 개인의 생명, 재산, 기타 주요 정당한 권익의 보호를
            위하여 본인의 동의를 받는 것이 곤란한 경우
          </p>
          <p>5. 본인이 공개한 개인정보</p>
          <p>
            6. 법률 뉴스 보도, 정부 정보 공개 및 기타 채널과 같이 합법적으로
            공개된 정보로부터 개인 정보를 수집합니다.
          </p>
          <p>
            해당 법률에 따라 정보를 받는 사람이 특정 개인을 재식별할 수 없도록
            개인정보를 처리하기 위해
            <strong
              ><u
                >기술적 조치 및 기타 필요한 조치를 취하는 경우 이러한 처리된
                데이터의 공유가 금지됨을 유의하시기 바랍니다. 개인은 복구, 양도
                및 공개할 수 없으며 귀하에게 알리고 귀하의 동의를 얻을 필요가
                없습니다</u
              ></strong
            >.
          </p>
          <p><strong>4. 귀하의 정보를 보호하는 방법</strong></p>
          <p>
            1. 당사는 귀하의 개인 데이터를 보호하고 보안을 보장하기 위해 적절한
            물리적, 전자적, 관리 및 기술적 조치를 구현합니다. 당사는 플랫폼을
            통해 수집된 모든 개인 정보가 당사와 관련이 없는 제3자의 간섭을 받지
            않도록 최선을 다합니다. 우리가 취하는 보안 조치에는 다음이
            포함되지만 이에 국한되지는 않습니다.
          </p>
          <p>
            (1) 물리적 조치: 귀하의 개인 데이터가 포함된 기록은 잠금 장치가 있는
            장소에 보관됩니다.
          </p>
          <p>
            (2) 전자적 조치: 귀하의 개인 데이터가 포함된 컴퓨터 데이터는 엄격한
            로그인 제한에 따라 컴퓨터 시스템 및 저장 매체에 저장됩니다.
          </p>
          <p>
            (3) 관리적 조치: 이용자 정보의 보안을 위하여 내부적으로 유관부서를
            두어 관련 내부통제시스템을 구축하고, 귀하의 정보에 접근할 수 있는
            직원에 대해 엄격한 권한 부여 원칙을 적용하고 있으며, 권한이 있는
            직원만이 귀하의 정보에 접근할 수 있도록 하고 있습니다. 개인
            데이터,&nbsp;이 직원은 개인 데이터의 기밀 유지에 대한 내부 코드를
            준수해야 합니다. 또한 직원 교육을 위한 관련 법규, 개인정보 및
            안전기준, 안전의식에 대한 홍보를 지속적으로 강화하고 있으며, 관련
            내부 인력을 정기적으로 편성하여 비상대응훈련 및 비상훈련을 실시하여
            직무 및 비상사태를 숙달하도록 하고 있습니다. 대응 전략 및 절차
          </p>
          <p>
            (4) 기술적 조치 : Secure Socket Layer 등을 사용할 수 있음 암호화는
            개인 데이터를 전송하는 암호화 기술입니다.
          </p>
          <p>
            (5) 보안 조치: 귀하의 정보 보안을 보호하기 위해 당사는 귀하의 정보가
            누출, 손상, 오용, 무단 액세스, 무단 공개 및 변경 위험으로부터
            최소화하기 위해 현재의 다양한 일반 보안 기술과 지원 관리 시스템을
            사용하기 위해 최선을 다하고 있습니다. . 예를 들어 네트워크 보안
            레이어 소프트웨어(SSL)를 통한 암호화된 전송, 암호화된 정보 저장,
            데이터 센터에 대한 액세스가 엄격하게 제한됩니다. 민감한
            개인정보(개인생체정보 포함)를 전송 및 보관 시 암호화, 권한제어,
            비식별화, 둔감화 등의 보안조치를 취하고 있습니다.
          </p>
          <p>
            (6) 기타 조치: 당사의 개인 데이터 수집, 저장 및 처리 절차를
            정기적으로 검토하고 필요한 원칙에 따라 당사 직원 및 공급업체의
            데이터 액세스를 제한하며 당사 직원 및 공급업체는 엄격한 계약상의
            기밀 유지 의무를 준수해야 합니다. .
          </p>
          <p>
            2. 당사 플랫폼의 보안 허점을 알고 있는 경우&nbsp;가능한 한 빨리
            적절한 조치를 취할 수 있도록 즉시&nbsp;연락해 주십시오.
          </p>
          <p>
            3. 위의 기술 및 보안 조치의 구현에도
            불구하고&nbsp;우리는&nbsp;인터넷에서 데이터 전송의 절대적인 보안을
            보장할 수 없으므로&nbsp;귀하가 우리 플랫폼을 통해 우리에게 제공한
            개인 데이터가 전혀 안전하다는 것을 절대적으로 보장할 수 없습니다.
            시간 . 당사는 보안 위험, 개인 정보 보안 영향 평가 보고서 및 기타
            관련 콘텐츠를 수시로 업데이트하고 게시할 것이며, 귀하는 이 플랫폼의
            발표를 통해 얻을 수 있습니다.
          </p>
          <p><strong>5. 정보 관리 방법</strong></p>
          <p>다음과 같은 방법으로 정보에 액세스하고 관리할 수 있습니다.</p>
          <p><strong>(1) 귀하의 정보 조회, 정정 및 삭제</strong></p>
          <p>
            귀하는 요청 시 귀하의 개인 데이터 사본을 얻을 수 있고 당사가 귀하에
            대해 보유하고 있는 정보가 정확하고 최신인지 확인할 권리가 있습니다.
            개인 데이터가 정확하지 않은 경우 정보 업데이트를 요청할 수 있습니다.
            귀하는 또한 귀하의 개인 데이터 삭제를 요청할 수 있지만, 당사는 법률
            또는 기타 법적 목적을 위해 요구되는 특정 상황에서 귀하의 삭제 요청을
            거부할 수 있습니다. 데이터 액세스, 수정 또는 삭제 요청에 대해서는
            제8조의 연락처 정보를 통해 당사에 문의할 수 있습니다. &nbsp;
          </p>
          <p>
            데이터 액세스, 수정 또는 삭제 요청에 응답할 때 당사는 요청 당사자의
            신원을 확인하여 해당 요청을 할 수 있는 법적 권한이 있는지
            확인합니다. 우리의 목표는 이러한 요청에 무료로 응답하는 것이지만
            귀하의 요청이 반복적이거나 부담스러운 경우 합당한 수수료를 청구할
            권리가 있습니다. &nbsp;
          </p>
          <p><strong>(2) 승인 및 동의 범위 변경</strong></p>
          <p>
            각 비즈니스 기능에는 몇 가지 기본 개인 정보를 작성해야 합니다(본
            개인 정보 보호 정책의 "파트 1" 참조). 또한 귀하는 제8조의 연락처를
            통해 당사에 연락하여 귀하의 승인 및 동의를 철회할 수 있습니다.
          </p>
          <p>
            동의 철회 시 해당 개인정보를 더 이상 처리하지 않습니다.
            <strong
              ><u
                >그러나 귀하의 동의 철회 결정은 귀하의 승인에 따른 이전의 개인
                정보 처리에 영향을 미치지 않습니다.
              </u></strong
            >
          </p>
          <p><strong>(3) 계정 해지</strong></p>
          <p>
            제8조의 연락처로 연락하여 해지 신청을 하실 수 있으며, 귀하가
            자발적으로 해지한 후에는 제품 또는 서비스 제공을 중단하거나 관련
            법령의 규정에 따라 개인정보를 삭제하거나 그것 익명화.
          </p>
          <p><strong>(4) 위 요청에 응답</strong></p>
          <p>
            보안상의 이유로 귀하는 서면 요청을 제공하거나 귀하의 신원을 증명해야
            할 수 있습니다. 귀하의 요청을 처리하기 전에 신원 확인을 요청할 수
            있습니다.
          </p>
          <p>
            15일 이내에 답변드리겠습니다. 만족스럽지 않은 경우 제공된 연락처
            정보를 통해 불만을 제기할 수도 있습니다.
          </p>
          <p>
            귀하의 정당한 요청에 대해서는 원칙적으로 수수료를 부과하지 않으나,
            합리적인 한도를 초과하는 반복 요청에 대해서는 적정한 일정 수수료를
            부과합니다. 귀하의 신원과 직접적으로 연결되지 않은 정보, 이유 없이
            정보를 반복하거나 과도한 기술적 수단을 요구하는 정보(예: 새로운
            시스템의 개발 또는 기존 관행의 근본적인 변경을 요구하는 정보)는
            정당한 사람에게 위험을 제기합니다. 타인의 권리와 이익 또는
            비현실적인 경우 거부할 수 있습니다.
          </p>
          <p>
            다음 상황에서는 법률 및 규정의 요구 사항에 따라 귀하의 요청에 응답할
            수 없습니다.
          </p>
          <p>1. 국가안보 및 국방안보 관련</p>
          <p>2. 공공 보안, 공중 보건 및 주요 공익 관련</p>
          <p>3. 범죄 수사, 기소, 재판 및 판결 집행 관련</p>
          <p>
            4. 개인정보주체가 주관적 악의가 있거나 권리를 남용한다는 충분한
            증거가 있는 경우
          </p>
          <p>
            5. 귀하의 요청에 응답하는 것은 귀하 또는 다른 개인 또는 조직의
            정당한 권익에 심각한 피해를 줄 수 있습니다.
          </p>
          <p>6. 상업 비밀과 관련된 것.</p>
          <p><strong>6. 미성년자 정보 처리 방법</strong></p>
          <p>
            이 플랫폼은 어린이에게 개방되어 있습니다.사용자가 14세 미만임을
            발견하거나 의심하는 경우 당사는 사용자에게 계정을 폐쇄하도록
            요청하고 사용자가 플랫폼 제품 또는 서비스를 계속 사용하도록 허용하지
            않습니다. 관련 정보는 최대한 빨리 삭제하겠습니다. 당사 서비스를
            사용하는 14세 미만의 사람을 알고 있는 경우 당사에 알려주시면 그들이
            당사 제품이나 서비스에 액세스하지 못하도록 조치를 취할 수 있습니다.
          </p>
          <p>
            만 14세 이상 만 18세 미만의 미성년자의 경우, 당사는 부모 또는
            보호자가 미성년자가 당사 서비스를 이용할 수 있도록 안내할 것을
            기대합니다. 관련법규에 따라 미성년자 정보의 비밀과 안전을
            보호하겠습니다.
          </p>
          <p>
            귀하가 미성년자인 경우, 귀하의 부모 또는 보호자가 본 정책을 읽고
            귀하의 부모 또는 보호자의 동의하에 당사 서비스를 사용하거나 귀하의
            정보를 제공하는 것이 좋습니다. 귀하의 부모 또는 보호자의 동의를 받아
            귀하의 정보를 수집하는 경우, 당사는 법률이 허용하거나 부모 또는
            보호자의 명시적인 동의가 있거나 귀하의 권리와 이익을 보호하기 위해
            필요한 경우에만 이 정보를 사용하거나 공개합니다. 귀하의 보호자가 본
            정책에 따라 귀하의 서비스 이용 또는 정보 제공에 동의하지 않는 경우,
            즉시 서비스 이용을 중단하고 적절한 조치를 취할 수 있도록 적시에 알려
            주시기 바랍니다.
          </p>
          <p>
            귀하가 미성년자의 부모 또는 보호자인 경우 귀하의 후견인에 있는
            미성년자의 정보 처리에 대해 질문이 있는 경우 본 정책에 게시된 연락처
            정보를 통해 당사에 문의하십시오.
          </p>
          <p><strong>7. 이 개인정보 보호정책을 업데이트하는 방법</strong></p>
          <p>저희 개인정보 보호정책은 변경될 수 있습니다.</p>
          <p>
            당사는 귀하의 명시적인 동의 없이 본 개인정보 보호정책에 따른 귀하의
            권리를 제한하지 않습니다. 개인 정보 보호 정책에 대한 변경 사항은
            공식 채널에 게시됩니다. 수정된 콘텐츠에 동의하지 않는 경우 이
            플랫폼에 대한 액세스를 즉시 중지해야 합니다. 업데이트된 버전의 개인
            정보 보호 정책이 발표되면 이 플랫폼에 대한 귀하의 지속적인 액세스가
            표시되고 업데이트된 콘텐츠에 동의하고 업데이트된 개인 정보 보호
            정책을 준수하는 데 동의함을 나타냅니다.
          </p>
          <p>
            주요 변경 사항의 경우 더 눈에 잘 띄는 알림을 제공할 것입니다(이
            플랫폼의 홍보를 통해 귀하에게 알리고 팝업 프롬프트를 제공하는 것
            포함).
          </p>
          <p>
            이 정책에 언급된 중요한 변경 사항은 다음을 포함하지만 이에 국한되지
            않습니다.
          </p>
          <p>
            1. 서비스 모델이 크게 변경되었습니다. 개인정보의 처리목적, 처리하는
            개인정보의 종류, 개인정보의 이용방법 등
          </p>
          <p>
            2. 지배권 등의 측면에서 큰 변화를 겪었습니다. 인수합병 등에 따른
            정보처리자의 변경 등
          </p>
          <p>3. 개인정보 공유, 이전 또는 공개의 주요 대상이 변경된 경우</p>
          <p>
            4. 개인정보 처리에 대한 귀하의 참여 권리 및 그 행사 방법에 큰 변화가
            있었습니다.
          </p>
          <p>
            5. 개인정보보호 담당부서, 연락방법 및 불만처리 창구의 변경
          </p>
          <p>
            6. 개인정보 보안 영향 평가 보고서에서 위험성이 높은 것으로
            나타났습니다. &nbsp;
          </p>
          <p><strong>VIII. 문의 방법</strong></p>
          <p>
            1. 요구 사항 및 의견이 있는 경우 이메일을 보낼 수 있습니다. 이는
            당사가 귀하와 소통하는 데 사용하는 유일한 유효하고 공식 이메일이므로
            유효한 연락처 정보를 사용하지 않는 행위 또는 누락에 대해 당사는
            책임을 지지 않습니다.
          </p>
          <p>
            2. 당사는 본 플랫폼의 유효한 연락처 정보를 통해서만 공지 및 정보를
            게시하거나 본 플랫폼에 공지를 게시하므로 위의 방법을 통해 얻지 못한
            정보에 대한 신뢰로 인해 발생한 손실에 대해 책임을 지지 않습니다.
          </p>
          <p>
            3. 개인 정보 보호 정책에 대해 궁금한 점이 있으면&nbsp;언제든지
            문의해 주십시오.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    <!-- 印尼语 -->
    <div class="main" v-show="lang == 'yn'">
      <div class="box">
        <div class="top">
          <div class="text1">Kebijakan Privasi</div>
          <div class="text2">Tanggal revisi terakhir: 20-08-2022</div>
        </div>
        <div class="content">
          <p><strong>【Ketentuan Pendahuluan dan Pengingat】</strong></p>
          <p>
            Platform ini adalah platform bagi pengguna untuk melakukan transaksi
            aset digital dan menyediakan layanan terkait (selanjutnya disebut
            sebagai "layanan" atau "layanan"). Untuk kenyamanan berekspresi,
            platform ini secara kolektif disebut sebagai "kami" atau gelar orang
            pertama lainnya dalam perjanjian ini. Selama orang atau subjek lain
            yang masuk ke platform adalah pengguna platform ini, "Anda" atau
            kata ganti orang kedua lainnya digunakan di bawah ini, Kami dan Anda
            secara bersama-sama disebut sebagai "Para Pihak" dan kami atau Anda
            secara individu disebut sebagai "Pihak".
          </p>
          <p>
            Kami sepenuhnya memahami dan menghormati pentingnya informasi
            pribadi Anda bagi Anda, dan kami akan mengambil tindakan
            perlindungan keamanan yang sesuai sesuai dengan persyaratan hukum
            dan peraturan untuk melindungi keamanan informasi pribadi Anda.
            Mengingat hal ini, kami merumuskan "Kebijakan Privasi" ini
            (selanjutnya disebut sebagai "Kebijakan ini/Kebijakan Privasi ini")
            dan mengingatkan Anda:
          </p>
          <p>
            Kebijakan ini berlaku untuk semua produk dan layanan yang kami
            tawarkan.
            <strong
              >Jika
              <u>
                produk atau layanan yang kami sediakan untuk Anda memiliki
                kebijakan privasi terpisah, produk atau layanan tersebut akan
                menerapkan kebijakan privasinya yang terpisah; jika produk atau
                layanan kami tidak memiliki kebijakan privasi terpisah, itu akan
                berlaku dalam hal ini Aturan.
              </u></strong
            >
          </p>
          <p>
            Perlu diperhatikan bahwa
            <strong
              ><u
                >kebijakan ini tidak berlaku untuk layanan yang disediakan oleh
                pihak ketiga lainnya kepada Anda, dan layanan yang disediakan
                oleh pihak ketiga untuk Anda diatur oleh kebijakan privasi
                mereka yang dijelaskan secara berbeda kepada Anda.
              </u></strong
            >
          </p>
          <p>
            <strong
              ><u
                >Sebelum menggunakan produk atau layanan kami, pastikan untuk
                membaca dan memahami kebijakan ini dengan hati-hati, terutama
                persyaratan yang ditandai dengan huruf tebal/garis bawah tebal,
                Anda harus membacanya dengan cermat, dan konfirmasikan pemahaman
                Anda sepenuhnya dan Setuju untuk mulai menggunakan . Jika Anda
                memiliki pertanyaan, komentar, atau saran tentang isi kebijakan
                ini, Anda dapat menghubungi kami melalui berbagai metode kontak
                resmi yang disediakan oleh kami. Jika Anda tidak menyetujui
                ketentuan apa pun dari kebijakan ini, Anda harus segera berhenti
                mengakses platform ini.
              </u></strong
            >
          </p>
          <p>&nbsp;</p>
          <p><strong>Bagian Satu&nbsp;Definisi</strong></p>
          <p>
            <strong>Informasi pribadi:</strong> mengacu pada berbagai informasi
            yang direkam secara elektronik atau dengan cara lain yang dapat
            mengidentifikasi orang perseorangan tertentu atau mencerminkan
            kegiatan orang perseorangan tertentu baik sendiri atau digabungkan
            dengan informasi lainnya.
          </p>
          <p>
            <strong>Informasi sensitif pribadi: Mengacu pada</strong> informasi
            pribadi termasuk nomor ID, informasi biometrik pribadi, nomor
            rekening bank, informasi properti, keberadaan, informasi transaksi,
            dan informasi tentang anak di bawah usia 14 tahun (termasuk)
            sensitif pribadi khusus informasi secara mencolok ditandai dengan
            <strong>tebal</strong> dalam Kebijakan Privasi ini).
          </p>
          <p>
            <strong>Penghapusan informasi pribadi:</strong> mengacu pada
            tindakan menghapus informasi pribadi dari sistem yang terlibat dalam
            realisasi fungsi bisnis sehari-hari, sehingga tetap dalam keadaan
            tidak dapat diambil atau diakses.
          </p>
          <p>
            <strong>Anak-anak</strong>: Mengacu pada anak di bawah usia empat
            belas tahun.
          </p>
          <p><strong>Bagian Kedua&nbsp;&nbsp;Kebijakan Privasi</strong></p>
          <p>
            Bagian Kebijakan Privasi ini akan membantu Anda memahami hal
            berikut:
          </p>
          <p>1. Bagaimana kami mengumpulkan dan menggunakan informasi Anda</p>
          <p>
            2. Bagaimana kita menggunakan{{
              this.basicConfig.CompanyNameEnglish
            }}dan teknologi serupa
          </p>
          <p>
            3. Cara kami membagikan, mentransfer, dan mengungkapkan informasi
            Anda secara publik
          </p>
          <p>4. Bagaimana kami melindungi informasi Anda</p>
          <p>5. Bagaimana Anda mengelola informasi Anda</p>
          <p>6. Bagaimana kami menangani informasi anak di bawah umur</p>
          <p>7. Cara memperbarui kebijakan privasi ini</p>
          <p>VIII. Cara menghubungi kami</p>
          <p>&nbsp;</p>
          <p>
            <strong
              >1. Bagaimana kami mengumpulkan dan menggunakan informasi
              Anda</strong
            >
          </p>
          <p>
            Saat Anda menggunakan produk dan/atau layanan kami, informasi
            pribadi Anda yang kami butuhkan/mungkin perlu kami kumpulkan dan
            gunakan mencakup dua jenis berikut:
          </p>
          <p>
            1.
            <strong
              >Untuk memberi Anda fungsi dasar dari produk dan/atau layanan
              kami, Anda harus mengizinkan kami untuk mengumpulkan dan
              menggunakan informasi yang diperlukan. Jika Anda menolak
              memberikan informasi terkait, Anda tidak akan dapat menggunakan
              produk dan/atau layanan kami secara normal;</strong
            >
          </p>
          <p>
            2.
            <strong
              >Untuk memberi Anda fungsi tambahan dari produk dan/atau layanan
              kami, Anda dapat memilih untuk mengizinkan kami mengumpulkan dan
              menggunakan informasi tersebut. Jika Anda menolak untuk
              menyediakannya, Anda tidak akan dapat menggunakan fungsi tambahan
              yang relevan atau mencapai efek fungsional yang ingin kami capai,
              namun hal itu tidak akan memengaruhi penggunaan normal Anda atas
              fungsi dasar produk dan/atau layanan kami.
            </strong>
          </p>
          <p>
            <strong><u>Anda memahami dan menyetujui bahwa:</u></strong>
          </p>
          <p>
            <strong>1</strong
            ><strong
              >,
              <u
                >Kami berkomitmen untuk menciptakan berbagai produk dan layanan
                untuk memenuhi kebutuhan Anda., demikian pula, fungsi
                dasar/tambahan serta jenis dan ruang lingkup informasi pribadi
                yang dikumpulkan dan digunakan akan berbeda. Silakan lihat
                Fungsi produk/layanan spesifik yang akan berlaku</u
              >;</strong
            >
          </p>
          <p>
            <strong>2</strong
            ><strong
              >,
              <u
                >Untuk memberi Anda pengalaman produk dan layanan yang lebih
                baik, kami terus berupaya meningkatkan teknologi kami, dan kami
                dapat meluncurkan produk baru atau yang dioptimalkan dari waktu
                ke waktu. diperlukan untuk mengumpulkan dan menggunakan
                informasi pribadi baru atau mengubah tujuan atau metode
                penggunaan informasi pribadi. Dalam hal ini, kami akan
                menjelaskan secara terpisah kepada Anda tujuan, ruang lingkup,
                dan penggunaan informasi terkait dengan memperbarui kebijakan
                ini, jendela pop-up, petunjuk halaman, dll., dan memberi Anda
                cara untuk memilih persetujuan Anda sendiri, dan setelah
                mendapatkan persetujuan tegas Anda Kumpulkan, gunakan</u
              >.
            </strong>
            Selama proses ini, jika Anda memiliki pertanyaan, komentar, atau
            saran, Anda dapat menghubungi kami melalui berbagai metode kontak
            yang disediakan oleh platform ini, dan kami akan menjawab Anda
            sesegera mungkin.
          </p>
          <p>&nbsp;</p>
          <p>
            Kami akan mengumpulkan dan menggunakan informasi pribadi Anda untuk
            mencapai fungsi berikut dari kebijakan ini:
          </p>
          <p>
            <strong
              >(1)&nbsp;Membantu Anda mendaftar dan memberi Anda layanan yang
              disediakan oleh platform ini;</strong
            >
          </p>
          <p><strong>1</strong><strong>, Layanan Pendaftaran</strong></p>
          <p>
            Kami memberi Anda layanan keanggotaan dasar melalui akun platform
            Anda. Untuk mendaftar sebagai anggota dan menggunakan layanan
            keanggotaan kami, Anda harus mengisi dan memberikan informasi
            berikut saat mengajukan permohonan pendaftaran:
          </p>
          <p>
            (1) Informasi identitas. Informasi ini dapat membantu kami
            memverifikasi apakah Anda memenuhi syarat untuk mendaftar sebagai
            anggota platform ini, termasuk namun tidak terbatas pada nama Anda,
            alamat tempat tinggal Anda, sertifikat lain yang dikeluarkan oleh
            negara atau pemerintah Anda dengan informasi yang membuktikan
            identitas Anda, dan nomor yang terlibat dan informasi lain apa pun
            yang membantu kami memverifikasi identitas Anda (secara kolektif,
            "Informasi Identitas"&nbsp;). Informasi identitas bervariasi
            tergantung pada apakah Anda adalah pengguna individu atau pengguna
            institusi Menurut undang-undang dan peraturan yurisdiksi yang
            berbeda, informasi yang kami kumpulkan untuk pengguna dari berbagai
            negara atau wilayah mungkin berbeda. Informasi dan data berikut
            berfungsi sebagai contoh indikatif:
          </p>
          <p>
            <strong>Pengguna individu</strong>: Kewarganegaraan, nama lengkap
            resmi (termasuk nama lama dan nama bahasa lokal), nomor jaminan
            sosial ("SSN"), nomor paspor atau nomor identifikasi yang
            dikeluarkan pemerintah, tanggal lahir, bukti identitas (seperti
            paspor, SIM, atau tanda pengenal yang dikeluarkan pemerintah).
          </p>
          <p>
            <strong>Pengguna institusional</strong>: nama resmi perusahaan
            (termasuk nama resmi dalam bahasa lokal), informasi pendaftaran,
            nomor telepon bisnis, perwakilan resmi dan informasi pribadi
            pengguna resmi, tempat bisnis utama, informasi pribadi semua pemilik
            manfaat), Dokumen identifikasi pemegang saham/pemilik manfaat
            (paspor atau kartu identitas yang dikeluarkan pemerintah), struktur
            perusahaan, dokumen terkait pendirian perusahaan, resolusi dewan,
            deklarasi sumber modal, instruksi kontrol risiko dan kepatuhan, dan
            informasi atau dokumen lain yang diperlukan oleh platform.
          </p>
          <p>
            (2) Informasi layanan. Informasi ini membantu kami untuk menghubungi
            Anda dan dengan lancar memberi Anda berbagai mode layanan transaksi
            aset digital, termasuk namun tidak terbatas pada informasi identitas
            pribadi Anda, informasi kontak, informasi transaksi, dan informasi
            kartu debit Anda dan/atau informasi akun lainnya (selanjutnya secara
            bersama-sama disebut sebagai "Informasi Layanan"&nbsp;).
            <strong>Pada saat yang sama</strong> harap dipahami bahwa layanan
            yang kami sediakan untuk Anda terus diperbarui dan dikembangkan.
            Jika Anda memilih untuk menggunakan layanan lain yang tidak tercakup
            dalam uraian di atas, berdasarkan layanan yang kami perlukan untuk
            mengumpulkan informasi <strong>tambahan</strong> Anda, kami akan
            menggunakan perintah halaman, proses interaktif, perjanjian
            perjanjian, perintah jendela pop-up , dll. Jelaskan kepada Anda
            ruang lingkup dan tujuan pengumpulan informasi, dan dapatkan
            persetujuan Anda.
          </p>
          <p>
            Jika Anda hanya perlu menggunakan layanan penelusuran, Anda tidak
            perlu mendaftar sebagai anggota kami dan memberikan informasi di
            atas.
          </p>
          <p>
            <strong>2</strong><strong>, layanan pendaftaran lainnya</strong>
          </p>
          <p>
            Otentikasi nama asli: Setelah Anda mendaftar dan masuk, Anda harus
            masuk ke "Pusat Pribadi" untuk menyelesaikan otentikasi nama asli
            dalam otentikasi identitas. Anda harus memberikan nama asli, nomor
            ID, atau nomor paspor Anda ke platform selama proses autentikasi
            nama asli.
          </p>
          <p>
            Sertifikasi lanjutan: Ketika volume transaksi Anda di platform ini
            melebihi batas tertentu, platform akan meminta Anda menjalani
            sertifikasi lanjutan untuk menjaga keamanan aset Anda. Selain
            informasi autentikasi nama asli selama proses autentikasi, Anda
            setuju bahwa platform akan melakukan autentikasi lanjutan
            "pengenalan wajah" pada Anda, dan pengenalan wajah akan melibatkan
            pengumpulan gambar wajah Anda yang relevan oleh platform.
          </p>
          <p>
            <strong
              ><u
                >Tampilan informasi akun: Jika Anda sudah memiliki akun di
                platform ini, kami dapat menampilkan informasi pribadi Anda yang
                disebutkan di atas dalam layanan (autentikasi nama asli hanya
                menunjukkan hasil apakah autentikasi diteruskan), dan akun Anda
                yang terkait dengan platform Operasi yang dilakukan pada produk
                dan layanan, termasuk tampilan terpusat dari data pribadi Anda
                dan pesanan transaksi melalui akun platform Anda. Kami akan
                menghargai pilihan pengaturan akun platform Anda.
              </u></strong
            >
          </p>
          <p>
            <strong>(2)</strong>&nbsp;<strong
              >Layanan manajemen keamanan dan layanan push informasi
              layanan</strong
            >
          </p>
          <p>
            Saat Anda menggunakan layanan yang disediakan oleh platform ini atau
            mengunjungi platform ini, untuk memastikan keamanan transaksi Anda
            dan pengoperasian layanan yang aman dan stabil, platform ini perlu
            secara otomatis menerima dan merekam informasi browser web Anda,
            informasi perangkat Anda gunakan untuk masuk ke akun Anda ( IDFA,
            sistem operasi, model perangkat, pabrikan perangkat, versi sistem,
            Android ID, IMEI&nbsp; dan alamat&nbsp;MAC&nbsp;, dll.), alamat IP
            dan catatan pada halaman web yang Anda minta untuk dikunjungi,
            lokasi, lingkungan jaringan, informasi sidik jari perangkat,
            informasi log layanan, informasi perangkat lunak, informasi lokasi,
            dll.
          </p>
          <p>
            <strong
              ><u
                >Perhatikan bahwa informasi perangkat saja adalah informasi yang
                tidak dapat mengidentifikasi orang tertentu.
              </u></strong
            >
            Jika kami menggabungkan informasi non-pribadi tersebut dengan
            informasi lain untuk mengidentifikasi orang tertentu, atau
            menggunakannya dalam kombinasi dengan informasi pribadi, selama
            penggunaan gabungan, informasi non-pribadi tersebut akan dianggap
            sebagai informasi pribadi informasi, kami akan menganonimkan dan
            menghilangkan identitas informasi tersebut kecuali kami mendapatkan
            otorisasi Anda atau ditentukan lain oleh undang-undang dan
            peraturan.
          </p>
          <p>
            <strong
              ><u
                >Untuk memberi Anda tampilan informasi dan layanan push yang
                lebih nyaman dan lebih sesuai dengan kebutuhan pribadi Anda,
                kami dapat mengekstrak preferensi Anda berdasarkan informasi
                perangkat Anda dan informasi penjelajahan dan penggunaan Anda di
                platform ini atau klien di fitur mendatang, dan menghasilkan
                potret kerumunan tidak langsung berdasarkan tag fitur untuk
                ditampilkan, mendorong informasi, dan kemungkinan iklan
                komersial. Jika Anda tidak setuju untuk menerima informasi push
                tersebut atau menarik persetujuan Anda untuk mengumpulkan
                informasi pribadi, Anda dapat menghubungi kami sesuai dengan
                informasi kontak yang diberikan dalam kebijakan ini.
              </u></strong
            >
          </p>
          <p>
            Kami bekerja keras untuk melindungi pengalaman menjelajah Anda. Jika
            Anda tidak ingin menerima iklan komersial yang kami kirimkan kepada
            Anda, Anda dapat berhenti berlangganan atau menutupnya dengan
            membalas SMS prompt atau cara lain yang kami sediakan.
          </p>
          <p>
            Selain itu, kami juga akan menggunakan informasi Anda di atas untuk
            terus menyempurnakan dan mengoptimalkan fungsi di atas.
          </p>
          <p>
            <strong
              >(3) Penyimpanan catatan transaksi dan layanan pertanyaan</strong
            >
          </p>
          <p>
            Data relevan yang kami kumpulkan tentang transaksi Anda di platform
            ini, termasuk namun tidak terbatas pada catatan transaksi. Jika Anda
            perlu menanyakan tentang catatan transaksi pesanan Anda, Anda dapat
            menanyakan tentang detail transaksi pribadi Anda di "Manajemen
            Pesanan" platform, termasuk: nama panggilan rekanan transaksi, waktu
            pemesanan, nomor pesanan, informasi transaksi, jenis dan kuantitas
            aset digital, dll.
          </p>
          <p>
            <strong>(4) Layanan pelanggan dan penyelesaian sengketa</strong>
          </p>
          <p>
            Saat Anda menghubungi kami atau mengajukan permohonan untuk
            menangani perselisihan selama dan setelah penjualan,
            <strong
              ><u
                >kami meminta Anda memberikan informasi pribadi yang diperlukan
                untuk memverifikasi keanggotaan Anda guna melindungi akun dan
                keamanan sistem Anda.
              </u></strong
            >
          </p>
          <p>
            <strong
              ><u
                >Untuk memfasilitasi kontak dengan Anda, membantu Anda
                memecahkan masalah sesegera mungkin, atau mencatat solusi dan
                hasil dari masalah terkait, kami dapat menyimpan catatan
                komunikasi Anda dan konten terkait dengan kami (termasuk
                informasi akun, pesanan informasi, pesanan Anda Informasi lain
                yang diberikan untuk membuktikan fakta yang relevan, atau
                informasi kontak yang Anda tinggalkan), jika Anda bertanya,
                mengeluh atau memberikan saran untuk pesanan tertentu, kami akan
                menggunakan informasi akun dan informasi pesanan Anda.
              </u></strong
            >
          </p>
          <p>
            Untuk menyediakan layanan dan meningkatkan kebutuhan kualitas
            layanan yang wajar, kami juga dapat menggunakan informasi lain
            tentang Anda, termasuk informasi relevan yang Anda berikan saat
            menghubungi layanan pelanggan, dan informasi tanggapan kuesioner
            yang Anda kirimkan kepada kami saat berpartisipasi dalam survei
            kuesioner.
          </p>
          <p><strong>(5) Lainnya</strong></p>
          <p>
            <strong><u>1</u></strong
            ><strong
              ><u
                >, jika informasi yang Anda berikan berisi informasi pribadi
                pengguna lain, sebelum memberikan informasi pribadi tersebut ke
                platform ini, Anda perlu memastikan bahwa Anda telah Mendapatkan
                otorisasi hukum. Jika melibatkan informasi pribadi anak-anak,
                Anda harus mendapatkan persetujuan dari wali anak terkait
                sebelum memposting. Dalam keadaan yang disebutkan di atas, wali
                berhak menghubungi kami melalui saluran di Pasal 9 kebijakan ini
                untuk meminta koreksi atau penghapusan konten yang melibatkan
                informasi pribadi anak-anak.
              </u></strong
            >
          </p>
          <p>
            <strong><u>2</u></strong
            ><strong
              ><u
                >, jika kami menggunakan informasi untuk tujuan lain yang tidak
                ditentukan dalam kebijakan ini, atau menggunakan informasi yang
                dikumpulkan untuk tujuan tertentu untuk tujuan lain, atau kami
                secara aktif mendapatkan informasi pribadi Anda dari pihak
                ketiga, kami akan mendapatkan persetujuan Anda terlebih dahulu.
              </u></strong
            >
          </p>
          <p>
            Jika kami memperoleh informasi Anda secara tidak langsung dari pihak
            ketiga, kami akan secara eksplisit meminta pihak ketiga untuk
            mengumpulkan informasi pribadi setelah mendapatkan persetujuan Anda
            sesuai dengan undang-undang sebelum pengumpulan, dan memberi tahu
            Anda tentang konten informasi yang dibagikan, yang melibatkan
            informasi sensitif Sebelum itu diberikan kepada kami untuk
            digunakan, itu perlu dikonfirmasi dengan jelas oleh Anda, dan pihak
            ketiga diharuskan membuat komitmen terhadap legalitas dan kepatuhan
            sumber informasi pribadi. Jika pihak ketiga melanggar hukum, kami
            akan dengan jelas mewajibkan pihak lain untuk memikul tanggung jawab
            hukum yang sesuai; pada saat yang sama, kami akan memperkuat
            keamanan informasi pribadi (termasuk pelaporan informasi sensitif,
            penyimpanan informasi sensitif terenkripsi, kontrol akses, dll.).
            Kami akan menggunakan tidak kurang dari sarana dan tindakan
            perlindungan yang sama seperti yang kami gunakan untuk informasi
            pribadi pengguna kami untuk melindungi informasi pribadi yang
            diperoleh secara tidak langsung.
          </p>
          <p>
            <strong>3</strong
            ><strong>, Pengecualian untuk Persetujuan Resmi</strong>
          </p>
          <p>
            Anda sepenuhnya memahami dan setuju bahwa kami tidak memerlukan
            otorisasi dan persetujuan Anda untuk mengumpulkan dan menggunakan
            informasi pribadi Anda dalam keadaan berikut, dan kami mungkin tidak
            menanggapi permintaan Anda untuk koreksi/modifikasi, penghapusan,
            pembatalan, penarikan persetujuan, atau permintaan informasi :
          </p>
          <p>(1) Terkait keamanan nasional dan keamanan pertahanan negara;</p>
          <p>
            (2) Terkait dengan keselamatan publik, kesehatan publik, dan
            kepentingan publik utama;
          </p>
          <p>
            (3) Terkait dengan penegakan hukum yudisial atau administratif
            seperti investigasi kriminal, penuntutan, persidangan, dan
            pelaksanaan putusan;
          </p>
          <p>
            (4) Untuk melindungi kehidupan pribadi Anda atau lainnya, properti
            dan hak dan kepentingan hukum utama lainnya, tetapi sulit untuk
            mendapatkan persetujuan dari orang tersebut;
          </p>
          <p>(5) Informasi pribadi yang Anda ungkapkan kepada publik;</p>
          <p>
            (6) Informasi pribadi dikumpulkan dari informasi yang diungkapkan
            secara hukum, seperti laporan berita hukum, pengungkapan informasi
            pemerintah, dan saluran lainnya.
          </p>
          <p>
            (7) Diperlukan untuk menandatangani dan melaksanakan perjanjian yang
            relevan atau dokumen tertulis lainnya dengan Anda;
          </p>
          <p>
            (8) Diperlukan untuk mempertahankan operasi yang aman dan stabil
            dari produk dan/atau layanan yang disediakan, seperti menemukan dan
            menangani kegagalan produk dan/atau layanan;
          </p>
          <p>(9) Diperlukan untuk pelaporan berita yang sah;</p>
          <p>
            (10) Lembaga penelitian akademik perlu melakukan penelitian
            statistik atau akademik berdasarkan kepentingan publik, dan ketika
            memberikan hasil penelitian atau deskripsi akademik kepada dunia
            luar, menghilangkan identifikasi informasi pribadi yang terkandung
            dalam hasil;
          </p>
          <p>
            (11) Keadaan lain yang diatur oleh peraturan perundang-undangan.
          </p>
          <p>
            Perlu diketahui bahwa, sesuai dengan undang-undang yang berlaku,
            jika kami
            <strong
              ><u
                >memproses informasi pribadi dengan melakukan tindakan teknis
                dan tindakan lain yang diperlukan sehingga penerima data tidak
                dapat mengidentifikasi ulang individu tertentu dan tidak dapat
                memulihkan</u
              ></strong
            >, Atau kami dapat melakukan penelitian yang tidak teridentifikasi,
            analisis statistik, dan prediksi pada informasi yang dikumpulkan
            untuk meningkatkan konten dan tata letak platform ini, memberikan
            dukungan produk atau layanan untuk keputusan bisnis, dan
            meningkatkan produk dan layanan kami (termasuk menggunakan data
            anonim untuk pembelajaran mesin atau pelatihan algoritme model),
            <strong
              ><u
                >Penggunaan data yang diproses tersebut tidak perlu memberi tahu
                Anda dan mendapatkan persetujuan Anda</u
              ></strong
            >.
          </p>
          <p>
            4. Jika kami berhenti mengoperasikan produk atau layanan platform
            ini, kami akan berhenti mengumpulkan informasi pribadi Anda secara
            tepat waktu, memberi tahu Anda tentang pemberitahuan penghentian
            operasi dalam bentuk pengiriman atau pengumuman satu per satu, dan
            membuat tinjauan komprehensif atas informasi relevan yang kami
            miliki. Informasi pribadi yang terkait dengan penangguhan bisnis
            akan dihapus atau dianonimkan.
          </p>
          <p>
            <strong
              >2. Bagaimana kami menggunakan{{
                this.basicConfig.CompanyNameEnglish
              }}</strong
            >
          </p>
          <p>
            <strong
              ><u
                >Jika browser atau ekstensi browser Anda memungkinkan, Anda
                dapat mengubah{{
                  this.basicConfig.CompanyNameEnglish
                }}penerimaan atau penolakan dari kami{{
                  this.basicConfig.CompanyNameEnglish
                }}。</u
              ></strong
            >. Namun, jika Anda melakukannya, hal itu dapat memengaruhi akses
            aman Anda ke platform kami dalam beberapa kasus, dan Anda mungkin
            perlu mengubah pengaturan pengguna setiap kali mengunjungi platform
            kami. Anda memahami dan menyetujui bahwa:
          </p>
          <p>
            1. Saat Anda mengunjungi platform kami, kami lulus{{
              this.basicConfig.CompanyNameEnglish
            }}Gunakan Google Stats untuk mencatat kinerja kami dan memeriksa
            keefektifan periklanan online.{{
              this.basicConfig.CompanyNameEnglish
            }}adalah sejumlah kecil data yang dikirim ke browser Anda dan
            disimpan di hard drive komputer Anda. Hanya ketika Anda menggunakan
            komputer Anda untuk mengakses platform kami,{{
              this.basicConfig.CompanyNameEnglish
            }}sebelum dapat dikirim ke hard drive komputer Anda.
          </p>
          <p>
            2、{{ this.basicConfig.CompanyNameEnglish }}Ini sering digunakan
            untuk merekam kebiasaan dan preferensi pengunjung saat menjelajahi
            berbagai item di platform kami.{{
              this.basicConfig.CompanyNameEnglish
            }}Informasi yang dikumpulkan adalah statistik kolektif anonim dan
            tidak mengandung data pribadi.
          </p>
          <p>
            3、{{ this.basicConfig.CompanyNameEnglish }}Itu tidak dapat
            digunakan untuk mendapatkan data di hard disk Anda, alamat email
            Anda, dan data pribadi Anda, yang dapat membuat platform atau sistem
            penyedia layanan ini mengenali browser Anda dan menangkap serta
            mengingat informasi. Sebagian besar browser secara default
            menerima{{ this.basicConfig.CompanyNameEnglish }}. Anda dapat
            memilih untuk mengonfigurasi browser Anda untuk tidak menerima{{
              this.basicConfig.CompanyNameEnglish
            }},atau jika{{ this.basicConfig.CompanyNameEnglish }}Anda akan
            diberitahu segera setelah diinstal. Namun, jika disetel ke
            nonaktif{{ this.basicConfig.CompanyNameEnglish }}, Anda mungkin
            tidak dapat mengaktifkan atau menggunakan fitur tertentu dari
            platform kami.
          </p>
          <p>
            <strong
              >3. Cara kami membagikan, mentransfer, dan mengungkapkan informasi
              Anda secara publik</strong
            >
          </p>
          <p><strong>(1) Berbagi</strong></p>
          <p>
            Kami tidak akan membagikan informasi pribadi Anda dengan perusahaan,
            organisasi, dan individu selain penyedia layanan platform ini,
            kecuali dalam kasus berikut:
          </p>
          <p>
            1. Berbagi berdasarkan undang-undang: Kami dapat membagikan
            informasi pribadi Anda secara eksternal sesuai dengan undang-undang
            dan peraturan, litigasi, kebutuhan penyelesaian sengketa, atau
            sesuai dengan persyaratan hukum otoritas administratif dan yudisial.
          </p>
          <p>
            2. Berbagi dengan persetujuan eksplisit: Setelah mendapatkan
            persetujuan eksplisit Anda, kami akan membagikan informasi pribadi
            Anda dengan pihak lain.
          </p>
          <p>
            3. Berbagi ketika Anda secara aktif memilih: Kami akan membagikan
            informasi yang diperlukan terkait transaksi dalam informasi pesanan
            Anda dengan mitra transaksi aset digital sesuai pilihan Anda,
            sehingga dapat mewujudkan kebutuhan transaksi dan layanan purna jual
            Anda.
          </p>
          <p>
            4. Berbagi dengan mitra resmi: afiliasi kami, pihak ketiga tepercaya
            yang membantu kami mengoperasikan platform kami, menjalankan bisnis,
            atau memberikan layanan kepada Anda, selama pihak-pihak ini setuju
            untuk merahasiakan informasi ini; ketika kami yakin bahwa
            pengungkapan Informasi tersebut sesuai untuk mematuhi undang-undang,
            peraturan, aturan dan peraturan atau perintah dari pengadilan atau
            otoritas kompeten lainnya, untuk menerapkan strategi platform kami,
            untuk mengoperasikan platform dengan benar, untuk menyediakan
            layanan bagi pihak terkait, atau untuk melindungi hak, properti,
            atau keselamatan kami atau orang lain .
          </p>
          <p><strong>(2) Transfer</strong></p>
          <p>
            Kami tidak akan mentransfer informasi pribadi Anda ke perusahaan,
            organisasi, atau individu mana pun, kecuali dalam kasus berikut:
          </p>
          <p>
            1. Transfer dengan persetujuan eksplisit: Setelah memperoleh
            persetujuan eksplisit Anda, kami akan mentransfer informasi pribadi
            Anda ke pihak lain;
          </p>
          <p>
            2. Jika terjadi merger, akuisisi, atau likuidasi kebangkrutan pada
            platform ini, atau situasi lain yang melibatkan merger, akuisisi,
            atau likuidasi kebangkrutan, jika transfer informasi pribadi
            terlibat, kami akan meminta perusahaan atau organisasi baru yang
            menyimpan informasi pribadi Anda untuk terus tunduk pada hal ini
            Jika tidak, kami akan meminta perusahaan, organisasi, dan individu
            untuk meminta otorisasi lagi dari Anda.
          </p>
          <p><strong>(3) Pengungkapan publik</strong></p>
          <p>
            Kami hanya akan mengungkapkan informasi pribadi Anda secara publik
            dalam keadaan berikut:
          </p>
          <p>
            1. Dengan persetujuan tegas Anda atau berdasarkan pilihan aktif
            Anda, kami dapat mengungkapkan informasi pribadi Anda secara publik;
          </p>
          <p>
            2.
            <strong
              ><u
                >Jika kami menetapkan bahwa Anda telah melanggar undang-undang
                dan peraturan atau secara serius melanggar perjanjian dan aturan
                yang relevan dari platform ini, atau untuk melindungi keamanan
                pribadi dan properti pengguna platform ini atau publik dari
                pelanggaran , kami dapat, sesuai dengan undang-undang dan
                peraturan atau Pengungkapan informasi pribadi Anda</u
              ></strong
            >
            dengan persetujuan Anda, termasuk pelanggaran yang relevan dan
            tindakan yang diambil platform ini terhadap Anda.
          </p>
          <p>
            <strong
              >(4) Pengecualian untuk otorisasi dan persetujuan sebelumnya saat
              membagikan, mentransfer, dan mengungkapkan informasi pribadi
              secara publik</strong
            >
          </p>
          <p>
            Dalam situasi berikut, berbagi, mentransfer, dan mengungkapkan
            informasi pribadi Anda secara publik tidak memerlukan otorisasi dan
            persetujuan Anda sebelumnya:
          </p>
          <p>
            1. Terkait dengan keamanan nasional dan keamanan pertahanan negara;
          </p>
          <p>
            2. Terkait dengan keamanan publik, kesehatan publik, dan kepentingan
            publik utama;
          </p>
          <p>
            3. Terkait dengan penegakan hukum yudisial atau administratif
            seperti investigasi kriminal, penuntutan, persidangan, dan
            pelaksanaan putusan;
          </p>
          <p>
            4. Untuk melindungi kehidupan pribadi Anda atau lainnya, properti
            dan hak serta kepentingan utama lainnya yang sah, tetapi sulit untuk
            mendapatkan persetujuan dari orang tersebut;
          </p>
          <p>
            5. Informasi pribadi Anda diungkapkan kepada publik oleh Anda
            sendiri;
          </p>
          <p>
            6. Mengumpulkan informasi pribadi dari informasi yang diungkapkan
            secara hukum, seperti laporan berita hukum, pengungkapan informasi
            pemerintah, dan saluran lainnya.
          </p>
          <p>
            Perlu diketahui bahwa, sesuai dengan undang-undang yang berlaku,
            berbagi data yang telah diproses tersebut dilarang jika kami
            <strong
              ><u
                >mengambil tindakan teknis dan tindakan lain yang diperlukan
                untuk memproses informasi pribadi sehingga penerima data tidak
                dapat mengidentifikasi ulang data tertentu individu dan tidak
                dapat memulihkan , transfer, dan pengungkapan publik tidak perlu
                memberi tahu Anda dan mendapatkan persetujuan Anda</u
              ></strong
            >.
          </p>
          <p><strong>4. Bagaimana kami melindungi informasi Anda</strong></p>
          <p>
            1. Kami menerapkan tindakan fisik, elektronik, manajemen, dan teknis
            yang sesuai untuk melindungi dan menjamin keamanan data pribadi
            Anda. Kami berusaha sebaik mungkin untuk memastikan bahwa setiap
            informasi pribadi yang dikumpulkan melalui platform kami bebas dari
            campur tangan pihak ketiga mana pun yang tidak terkait dengan kami.
            Langkah-langkah keamanan yang kami ambil termasuk tetapi tidak
            terbatas pada:
          </p>
          <p>
            (1) Tindakan fisik: catatan yang berisi data pribadi Anda akan
            disimpan di tempat terkunci.
          </p>
          <p>
            (2) Tindakan elektronik: Data komputer yang berisi data pribadi Anda
            akan disimpan di sistem komputer dan media penyimpanan yang tunduk
            pada pembatasan login yang ketat.
          </p>
          <p>
            (3) Tindakan manajemen: Kami telah menyiapkan departemen terkait
            secara internal untuk melindungi keamanan informasi pengguna, dan
            menetapkan sistem kontrol internal yang relevan, dan menerapkan
            prinsip otorisasi yang ketat untuk staf yang mungkin memiliki akses
            ke informasi Anda. Hanya staf yang berwenang yang dapat mengakses Ke
            Anda data pribadi,&nbsp;staf ini diharuskan untuk mematuhi kode
            internal kerahasiaan data pribadi kami. Selain itu, kami terus
            memperkuat publisitas undang-undang dan peraturan yang relevan,
            standar privasi dan keselamatan, serta kesadaran keselamatan untuk
            pelatihan staf, dan secara teratur mengorganisir personel internal
            yang relevan untuk melakukan pelatihan tanggap darurat dan latihan
            darurat, sehingga mereka dapat menguasai tanggung jawab pekerjaan
            dan keadaan darurat. strategi respon dan prosedur
          </p>
          <p>
            (4) Tindakan teknis: seperti Secure Socket Layer dapat digunakan
            Enkripsi adalah teknologi enkripsi untuk mengirimkan data pribadi
            Anda.
          </p>
          <p>
            (5) Tindakan keamanan: Untuk melindungi keamanan informasi Anda,
            kami berkomitmen untuk menggunakan berbagai teknologi keamanan umum
            terkini dan sistem manajemen pendukung untuk meminimalkan informasi
            Anda agar tidak bocor, rusak, disalahgunakan, akses tidak sah, dan
            pengungkapan tidak sah serta risiko perubahan . Misalnya: transmisi
            terenkripsi melalui perangkat lunak lapisan keamanan jaringan (SSL),
            penyimpanan informasi terenkripsi, dan akses yang sangat terbatas ke
            pusat data. Saat mentransmisikan dan menyimpan informasi pribadi
            yang sensitif (termasuk informasi biometrik pribadi), kami akan
            mengadopsi langkah-langkah keamanan seperti enkripsi, kontrol izin,
            de-identifikasi, dan desensitisasi.
          </p>
          <p>
            (6) Tindakan lain: meninjau prosedur pengumpulan, penyimpanan, dan
            pemrosesan data pribadi kami secara rutin; dan membatasi akses
            karyawan dan pemasok kami ke data Anda sesuai dengan prinsip yang
            diperlukan, dan karyawan serta pemasok kami harus mematuhi kewajiban
            kerahasiaan kontrak yang ketat. .
          </p>
          <p>
            2. Jika Anda mengetahui adanya celah keamanan di platform
            kami,&nbsp;harap segera hubungi kami&nbsp;agar kami dapat mengambil
            tindakan yang tepat sesegera mungkin.
          </p>
          <p>
            3. Terlepas dari penerapan langkah-langkah teknis dan keamanan di
            atas,&nbsp;kami tidak dapat menjamin keamanan mutlak transmisi data
            di Internet,&nbsp;sehingga kami tidak dapat sepenuhnya menjamin
            bahwa data pribadi yang Anda berikan kepada kami melalui platform
            kami sama sekali aman kali. Kami akan memperbarui dan menerbitkan
            risiko keamanan, laporan penilaian dampak keamanan informasi
            pribadi, dan konten relevan lainnya dari waktu ke waktu, yang dapat
            Anda peroleh melalui pengumuman di platform ini.
          </p>
          <p><strong>5. Bagaimana Anda mengelola informasi Anda</strong></p>
          <p>
            Anda dapat mengakses dan mengelola informasi Anda dengan cara
            berikut:
          </p>
          <p>
            <strong
              >(1) Permintaan, koreksi, dan penghapusan informasi Anda</strong
            >
          </p>
          <p>
            Anda memiliki hak untuk mendapatkan salinan data pribadi Anda
            berdasarkan permintaan dan untuk memastikan apakah informasi yang
            kami miliki tentang Anda akurat dan terkini. Jika ada data pribadi
            Anda yang tidak akurat, Anda dapat meminta agar informasi Anda
            diperbarui. Anda juga dapat meminta penghapusan data pribadi Anda,
            meskipun kami dapat menolak permintaan penghapusan Anda dalam
            keadaan tertentu, misalnya jika diwajibkan oleh undang-undang atau
            untuk tujuan hukum lainnya. Untuk permintaan akses data, koreksi
            atau penghapusan, Anda dapat menghubungi kami melalui informasi
            kontak di Pasal 8. &nbsp;
          </p>
          <p>
            Dalam menanggapi permintaan akses, koreksi, atau penghapusan data,
            kami akan memverifikasi identitas pihak yang meminta untuk
            memastikan bahwa dia berwenang secara hukum untuk membuat permintaan
            tersebut. Meskipun tujuan kami adalah untuk menanggapi permintaan
            ini secara gratis, kami berhak membebankan biaya yang wajar kepada
            Anda jika permintaan Anda berulang atau memberatkan. &nbsp;
          </p>
          <p>
            <strong>(2) Ubah cakupan otorisasi dan persetujuan Anda</strong>
          </p>
          <p>
            Setiap fungsi bisnis memerlukan beberapa informasi pribadi dasar
            untuk diselesaikan (lihat "Bagian 1" dari Kebijakan Privasi ini).
            Selain itu, Anda dapat menghubungi kami untuk memberikan atau
            menarik otorisasi dan persetujuan Anda melalui informasi kontak di
            Pasal 8.
          </p>
          <p>
            Ketika Anda menarik persetujuan Anda, kami tidak akan lagi memproses
            informasi pribadi terkait.
            <strong
              ><u
                >Namun, keputusan Anda untuk menarik persetujuan Anda tidak akan
                memengaruhi pemrosesan informasi pribadi sebelumnya berdasarkan
                otorisasi Anda.
              </u></strong
            >
          </p>
          <p><strong>(3) Batalkan akun Anda</strong></p>
          <p>
            Anda dapat menghubungi kami melalui informasi kontak di Pasal 8
            untuk mengajukan pembatalan akun Anda; setelah Anda secara sukarela
            membatalkan akun Anda, kami akan berhenti menyediakan produk atau
            layanan kepada Anda, menghapus informasi pribadi Anda sesuai dengan
            persyaratan undang-undang yang berlaku, atau membuat itu
            Anonimisasi.
          </p>
          <p><strong>(4) Tanggapi permintaan Anda di atas</strong></p>
          <p>
            Untuk tujuan keamanan, Anda mungkin diminta untuk memberikan
            permintaan tertulis atau membuktikan identitas Anda. Kami mungkin
            meminta Anda untuk memverifikasi identitas Anda sebelum memproses
            permintaan Anda.
          </p>
          <p>
            Kami akan membalas dalam waktu 15 hari. Jika Anda tidak puas, Anda
            juga dapat mengajukan keluhan melalui informasi kontak yang kami
            berikan.
          </p>
          <p>
            Untuk permintaan Anda yang wajar, pada prinsipnya kami tidak
            membebankan biaya, tetapi untuk permintaan berulang yang melebihi
            batas wajar, kami akan membebankan biaya tertentu yang sesuai. Untuk
            informasi yang tidak terkait langsung dengan identitas Anda, yang
            mengulang informasi tanpa alasan, atau yang memerlukan sarana teknis
            yang berlebihan (misalnya, memerlukan pengembangan sistem baru atau
            perubahan mendasar dalam praktik yang ada), menimbulkan risiko bagi
            pihak yang sah. hak dan kepentingan orang lain, atau tidak praktis ,
            kami dapat menolaknya.
          </p>
          <p>
            Dalam situasi berikut, kami tidak dapat menanggapi permintaan Anda
            sesuai dengan persyaratan hukum dan peraturan:
          </p>
          <p>
            1. Terkait dengan keamanan nasional dan keamanan pertahanan negara;
          </p>
          <p>
            2. Terkait dengan keamanan publik, kesehatan publik, dan kepentingan
            publik utama;
          </p>
          <p>
            3. Terkait dengan investigasi kriminal, penuntutan, persidangan, dan
            pelaksanaan putusan;
          </p>
          <p>
            4. Ada cukup bukti bahwa subjek informasi pribadi memiliki kebencian
            subjektif atau penyalahgunaan hak;
          </p>
          <p>
            5. Menanggapi permintaan Anda akan menyebabkan kerusakan serius pada
            hak dan kepentingan sah Anda atau individu atau organisasi lain;
          </p>
          <p>6. Yang melibatkan rahasia komersial.</p>
          <p>
            <strong
              >6. Bagaimana kami menangani informasi anak di bawah umur</strong
            >
          </p>
          <p>
            Platform ini terbuka untuk anak-anak. Jika kami menemukan atau
            mencurigai bahwa pengguna berusia di bawah 14 tahun, kami akan
            meminta pengguna untuk menutup akunnya dan tidak akan mengizinkan
            pengguna untuk terus menggunakan produk atau layanan platform. Kami
            akan menghapus informasi yang relevan sesegera mungkin. Jika Anda
            mengetahui seseorang yang berusia di bawah 14 tahun menggunakan
            layanan kami, beri tahu kami agar kami dapat mengambil tindakan
            untuk mencegah mereka mengakses produk atau layanan kami.
          </p>
          <p>
            Bagi anak di bawah umur yang telah mencapai usia 14 tahun tetapi
            belum mencapai usia 18 tahun, kami mengharapkan orang tua atau wali
            untuk membimbing anak di bawah umur tersebut untuk menggunakan
            layanan kami. Kami akan melindungi kerahasiaan dan keamanan
            informasi anak di bawah umur sesuai dengan hukum dan peraturan yang
            relevan.
          </p>
          <p>
            Jika Anda masih di bawah umur, disarankan agar orang tua atau wali
            Anda membaca kebijakan ini, dan menggunakan layanan kami atau
            memberikan informasi Anda kepada kami dengan persetujuan orang tua
            atau wali Anda. Dalam hal mengumpulkan informasi Anda dengan
            persetujuan orang tua atau wali Anda, kami hanya akan menggunakan
            atau mengungkapkan informasi ini secara publik jika diizinkan oleh
            undang-undang, dengan persetujuan eksplisit dari orang tua atau wali
            Anda, atau sebagaimana diperlukan untuk melindungi hak dan
            kepentingan Anda. Jika wali Anda tidak setuju dengan Anda untuk
            menggunakan layanan kami atau memberikan informasi kepada kami
            sesuai dengan kebijakan ini, harap segera berhenti menggunakan
            layanan kami dan beri tahu kami tepat waktu sehingga kami dapat
            mengambil tindakan yang sesuai.
          </p>
          <p>
            Jika Anda adalah orang tua atau wali dari anak di bawah umur, harap
            hubungi kami melalui informasi kontak yang dipublikasikan dalam
            kebijakan ini jika Anda memiliki pertanyaan tentang pemrosesan
            informasi anak di bawah umur di bawah perwalian Anda.
          </p>
          <p><strong>7. Cara memperbarui Kebijakan Privasi ini</strong></p>
          <p>Kebijakan Privasi kami dapat berubah.</p>
          <p>
            Kami tidak akan membatasi hak Anda berdasarkan Kebijakan Privasi ini
            tanpa persetujuan tertulis dari Anda. Kami akan memposting setiap
            perubahan pada Kebijakan Privasi di saluran resmi kami. Jika Anda
            tidak setuju dengan konten yang direvisi, Anda harus segera berhenti
            mengakses platform ini. Ketika versi Kebijakan Privasi yang
            diperbarui dirilis, akses Anda yang berkelanjutan ke platform ini
            akan menunjukkan dan menunjukkan bahwa Anda menyetujui konten yang
            diperbarui dan setuju untuk mematuhi Kebijakan Privasi yang
            diperbarui.
          </p>
          <p>
            Untuk perubahan besar, kami juga akan memberikan pemberitahuan yang
            lebih menonjol (termasuk kami akan memberi tahu Anda melalui
            publisitas platform ini dan bahkan memberi Anda petunjuk pop-up).
          </p>
          <p>
            Perubahan materi yang dirujuk dalam kebijakan ini termasuk tetapi
            tidak terbatas pada:
          </p>
          <p>
            1. Perubahan besar telah terjadi pada model layanan kami. Seperti
            tujuan pemrosesan informasi pribadi, jenis informasi pribadi yang
            diproses, cara penggunaan informasi pribadi, dll.;
          </p>
          <p>
            2. Kami telah mengalami perubahan besar dalam aspek-aspek seperti
            hak kontrol. Seperti perubahan pengendali informasi yang disebabkan
            oleh merger dan akuisisi, dll;
          </p>
          <p>
            3. Objek utama pembagian, transfer, atau pengungkapan publik
            informasi pribadi telah berubah;
          </p>
          <p>
            4. Ada perubahan besar dalam hak Anda untuk berpartisipasi dalam
            pemrosesan informasi pribadi dan cara menggunakannya;
          </p>
          <p>
            5. Perubahan pada departemen yang bertanggung jawab, metode kontak,
            dan saluran pengaduan yang bertanggung jawab untuk menangani
            keamanan informasi pribadi;
          </p>
          <p>
            6. Laporan penilaian dampak keamanan informasi pribadi menunjukkan
            adanya risiko tinggi. &nbsp;
          </p>
          <p><strong>VIII. Cara menghubungi kami</strong></p>
          <p>
            1. Jika Anda memiliki persyaratan dan komentar, Anda dapat mengirim
            email, yang merupakan satu-satunya email resmi dan valid yang kami
            gunakan untuk berkomunikasi dengan Anda, jadi kami tidak akan
            bertanggung jawab atas tindakan atau kelalaian apa pun yang Anda
            tidak gunakan informasi kontak yang valid.
          </p>
          <p>
            2. Kami hanya mempublikasikan pengumuman dan informasi atau
            memposting pengumuman di platform ini melalui informasi kontak yang
            efektif di platform ini, jadi kami tidak bertanggung jawab atas
            segala kerugian yang disebabkan oleh kepercayaan Anda terhadap
            informasi yang tidak diperoleh melalui cara-cara di atas.
          </p>
          <p>
            3. Jika Anda memiliki pertanyaan tentang kebijakan privasi
            kami,&nbsp;jangan ragu untuk menghubungi kami.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CurrencyMobileNewIndex',

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection'])
  },
  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.main {
  padding-top: 20px;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;
  background-color: #f8f8f8;
  align-items: center;
  justify-content: center;
  display: flex;
  box-sizing: border-box;

  .box {
    width: 1120px;
    min-height: 747px;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;

    .top {
      width: 100%;
      height: 75px;
      padding-top: 17px;
      box-sizing: border-box;
      border-bottom: 1px solid #eaecef;
      text-align: center;

      .text1 {
        margin-bottom: 8px;
        color: #1e1e1e;
        font-size: 18px;
      }

      .text2 {
        color: #989898;
        font-size: 12px;
      }
    }

    .content {
      width: 100%;
      padding: 20px 30px;
      box-sizing: border-box;
      letter-spacing: 1px;
      line-height: 16.8px;
      word-wrap: break-word;
      text-align: left;
      color: #000;

      p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}
</style>
